import React from 'react'
import { SzAlert } from 'react-theme-components'

interface Props {
  type: string
  alertMessaeg: string
  className?: string
  children?: React.ReactNode
}
function Alert({ type, alertMessaeg, className, children }: Props) {
  switch (type) {
    case 'success':
      return (
        <div>
          <SzAlert
            variant="success"
            className={`wasteRegister__warning ${className}`}
          >
            {alertMessaeg}
          </SzAlert>
        </div>
      )
    case 'warning':
      return (
        <div>
          <SzAlert
            variant="warning"
            className={`wasteRegister__warning ${className}`}
          >
            {alertMessaeg}
          </SzAlert>
        </div>
      )
    case 'alert':
      return (
        <div className="alert__bottomGap">
          <SzAlert variant="danger" className={`alert__danger ${className}`}>
            {children ?? alertMessaeg}
          </SzAlert>
        </div>
      )
    case 'info':
      return (
        <div className="alert__bottomGap">
          <SzAlert variant="info" className={`alert__danger ${className}`}>
            {children ?? alertMessaeg}
          </SzAlert>
        </div>
      )
    default:
      return <></>
  }
}

Alert.defaultProps = {
  className: '',
  children: null,
}

export default Alert
