import styled from 'styled-components'

const StyledTags = styled.div`
  color: #030f40;
  font-family: 'DINPro';
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
  padding: 5px;
  width: fit-content;
`

export default StyledTags
