import React from 'react'

interface Props {
  htmlFor: string
  mandatory?: boolean
  children?: React.ReactNode
  className?: string
}

const FormLabel: React.FC<Props> = ({
  htmlFor,
  children,
  mandatory,
  className,
}: Props) => (
  <label className={className} htmlFor={htmlFor}>
    <div>{children}</div>
    <div>{mandatory && <span className="mandatory_indicator">*</span>}</div>
  </label>
)

FormLabel.defaultProps = {
  children: null,
  mandatory: false,
  className: 'form__label',
}

export default FormLabel
