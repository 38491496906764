/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from 'styled-components'
import { StyledBandeauControl } from './BandeauControle'

const StyledBandeauWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 4.6rem;
  width: 100%;
  max-width: 1440px;
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: 'DINPro-bold';
  background-color: transparent;
  font-size: 1.4rem;
  line-height: 2.3rem;
  &:hover,
  &:hover ${StyledBandeauControl}, &:hover ${StyledBandeauControl} path {
    cursor: pointer;
    color: ${(props) =>
      props.resetHovered
        ? props.isImpersonnation
          ? '#030f40'
          : 'white'
        : '#77b7fc'};
    fill: ${(props) =>
      props.resetHovered
        ? props.isImpersonnation
          ? '#030f40'
          : 'white'
        : '#77b7fc'};
  }
`
interface Props {
  children: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  className?: string
  resetHovered: boolean
  mode: string
  isImpersonnation: boolean
}
const BandeauWrapper = ({
  children,
  onClick,
  className,
  resetHovered,
  mode,
  isImpersonnation,
}: Props) => {
  return (
    <StyledBandeauWrapper
      onClick={onClick}
      className={className}
      resetHovered={resetHovered}
      isImpersonnation={isImpersonnation}
      mode={mode}
    >
      {children}
    </StyledBandeauWrapper>
  )
}

BandeauWrapper.defaultProps = {
  className: '',
}

export default BandeauWrapper
