export const ADMIN_ROLES = [
  'ROLE_INT_SUPER_ADMIN',
  'ROLE_CLI_ADMIN',
  'ROLE_INT_ADMIN_CLIENT',
  'ROLE_INT_ADMIN_INTERNAL',
]

export const ROLE_SYMBOLE = {
  abbreviation: 'A',
  fullCharacters: 'Administrateur',
}

export const SUPPORT_ROLES = ['ROLE_INT_SUPPORT_N1']

enum ROLES {
  ROLE_INT_BILLING = 1,
  ROLE_INT_ACTIVITIES = 2,
  ROLE_INT_ADMIN_CLIENT = 3,
  ROLE_INT_ADMIN_INTERNAL = 4,
  ROLE_INT_SUPER_ADMIN = 5,
  ROLE_CLI_ACTIVITIES = 6,
  ROLE_CLI_BILLING = 7,
  ROLE_CLI_ADMIN = 8,
  ROLE_INT_SUPPORT_N1 = 9,
}

export default ROLES
