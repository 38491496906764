import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

/**
 * We use react-table lib because the native Suez Design System
 * Component (SDS) does not provide the functionalities we want
 * The react-table lib is cool, but with TYPESCRIPT, we have
 * a lot of problem, so we use JS files
 */
function TableColumnFilter({
  // eslint-disable-next-line react/prop-types
  column: { setFilter },
}) {
  const { t } = useTranslation()

  const [value, setValue] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => setFilter(value), 1000)
    return () => clearTimeout(timer)
  }, [value])

  return (
    <>
      <input
        className="table__filter-input custom-input"
        placeholder={t('table.filterPlaceholder')}
        onChange={(e) => {
          setValue(e.target.value || undefined)
        }}
      />
    </>
  )
}

export default TableColumnFilter
