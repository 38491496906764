import React from 'react'
import { useTranslation } from 'react-i18next'
import deniedAccess from '../assets/deniedAccess.png'
import ScreenContainerSimpleLayoutConnexion from '../layouts/ScreenContainerSimpleLayoutConnexion'

const IEisNotAllowed: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ScreenContainerSimpleLayoutConnexion isLogoClickable={false}>
      <div className="errorbody">
        <img
          src={deniedAccess}
          alt="man holding empty box"
          className="errorbody_image"
        />
        <div className="errorbody_text-container errorbody_text-container--width-auto">
          <div className="content">
            <h1 style={{ fontSize: '2.8rem' }}>{t('ieNotAllowed.title')}</h1>
            <div className="infos text-left">
              <p>{t('ieNotAllowed.text_p1')}</p>
              <p>{t('ieNotAllowed.text_p2')}</p>
              <ul className="mt-4 mb-5 text-left">
                <li>Google Chrome</li>
                <li>Firefox</li>
                <li>Microsoft Edge</li>
                <li>Safari</li>
              </ul>
              <p>{t('ieNotAllowed.text_p3')}</p>
            </div>
          </div>
        </div>
      </div>
    </ScreenContainerSimpleLayoutConnexion>
  )
}

export default IEisNotAllowed
