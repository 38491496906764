enum MAINTENANCE_TYPE {
  ALL = 'all',
  DEMANDS = 'demands',
  DEMANDS_DETAILS = 'demands_details',
  GED = 'ged',
}

export interface MaintenanceEntity {
  type: string
  label: string
  value: boolean
  message: string | null
  withForm: boolean
}

export default MAINTENANCE_TYPE

export type MaintenanceData = {
  type: string
  label: string
  value: boolean
  message: string | null
  withForm: boolean
}
