import moment from 'moment'
import 'moment/locale/fr'

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
const INPUT_DATE_FORMAT_API_WDH = 'YYYY-MM-DD'
const INPUT_DATE_FORMAT_GDD = 'HH:mm'

export function addDays(date: Date, nbDay: number): Date {
  date.setDate(date.getDate() + nbDay)
  return date
}

export function get1stDayOfCurrentYear(): Date {
  return moment().dayOfYear(1).toDate()
}

export function to1stDayOfMonth(date: Date): Date {
  return moment(date).startOf('month').toDate()
}

export function toLastDayOfMonth(date: Date): Date {
  return moment(date).endOf('month').toDate()
}

export function formatDate(date: Date, datePattern?: string): string {
  const pattern = !datePattern ? DEFAULT_DATE_FORMAT : datePattern

  return moment(date).format(pattern)
}

export function formatToInputDateWDH(
  date: Date,
  inputFormat: string | null = null,
  outputFormat = INPUT_DATE_FORMAT_API_WDH
): string {
  if (inputFormat !== null && inputFormat !== '')
    return moment(date, inputFormat).format(outputFormat)
  return moment(date).format(outputFormat)
}

export function formatFrenchDate(date: Date): string {
  return `${date.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}${' à '}${formatDate(date, INPUT_DATE_FORMAT_GDD)}`
}

export function parseDate(date: string): Date {
  return moment(date).toDate()
}

export function getYear(date: Date): number {
  return moment(date).year()
}

export function getCurrentYear(): number {
  return moment().year()
}

export function getLastYear(): number {
  return moment().subtract(1, 'y').year()
}

export function getMonth(date: Date): string {
  return moment(date).format('MMMM')
}

export function getCurrentMonth(): string {
  return moment().format('MMMM')
}

export function getCurrentMonthFormatMM(): string {
  return moment().format('MM')
}

export function getFormattedDate(date: Date): string {
  return moment(date).format('D MMMM YYYY')
}

export function getMonthBeforeCurrentMonth() {
  return moment().subtract(1, 'M').format('MM')
}

export function getNextMonth() {
  return moment().add(1, 'M').format('MM')
}

export function getMonthBeforeCurrentMonthByFormat() {
  return moment().subtract(1, 'M').format('MMMM')
}

export function getMonthBeforeLastMonth() {
  return moment().subtract(2, 'M').format('MM')
}

export function getYearOfMonthBeforeLastMonth() {
  return moment().subtract(2, 'M').format('YYYY')
}

export function getYearOfMonthBeforeCurrentMonth() {
  return moment().subtract(1, 'M').format('YYYY')
}

export function getLastDayOftri(date: Date): Date {
  return moment(date).subtract(1, 'M').endOf('month').toDate()
}
export function getFirstDayOftri(date: Date): Date {
  return moment(date).subtract(3, 'M').startOf('month').toDate()
}
export function subMonths(date: Date, count: number): Date {
  return moment(date).subtract(count, 'M').toDate()
}
export function getTrim(date: Date): Date[] {
  const interval: Date[] = []
  const index = moment(date).month()
  let firstDate
  let lastDate

  if (index >= 0 && index <= 2) {
    firstDate = new Date(getYear(date), 0)
    lastDate = new Date(getYear(date), 3, 0)
  } else if (index >= 3 && index <= 5) {
    firstDate = new Date(getYear(date), 3)
    lastDate = new Date(getYear(date), 6, 0)
  } else if (index >= 6 && index <= 8) {
    firstDate = new Date(getYear(date), 6)
    lastDate = new Date(getYear(date), 9, 0)
  } else {
    firstDate = new Date(getYear(date), 9)
    lastDate = new Date(getYear(date), 12, 0)
  }

  interval.push(firstDate)
  interval.push(lastDate)

  return interval
}

export function inXXdays(dateAsString: string): number {
  const msDiff = new Date(dateAsString).getTime() - new Date().getTime()

  const daysTillExpiration = Math.ceil(msDiff / (1000 * 60 * 60 * 24))

  return daysTillExpiration
}

export function getFirstMonthAndYear(date: string) {
  return moment(date).format('YYYY-MM')
}

export function getLibelleMonthByNumberMonthFR(month: number) {
  switch (month) {
    case 1:
      return 'janvier'
    case 2:
      return 'février'
    case 3:
      return 'mars'
    case 4:
      return 'avril'
    case 5:
      return 'mai'
    case 6:
      return 'juin'
    case 7:
      return 'juillet'
    case 8:
      return 'août'
    case 9:
      return 'septembre'
    case 10:
      return 'octobre'
    case 11:
      return 'novembre'
    case 12:
      return 'décembre'
    default:
      return '?'
  }
}

// Date() doesn't convert string in (dd/mm/yyyy) format
export function convertStringToFrenchDate(date: string): Date {
  const dateParts = date.split('/')
  const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
  return dateObject
}

export function getMonthsSelector() {
  return [
    { label: 'Janvier', value: '01' },
    { label: 'Février', value: '02' },
    { label: 'Mars', value: '03' },
    { label: 'Avril', value: '04' },
    { label: 'Mai', value: '05' },
    { label: 'Juin', value: '06' },
    { label: 'Juillet', value: '07' },
    { label: 'Août', value: '08' },
    { label: 'Septembre', value: '09' },
    { label: 'Octobre', value: '10' },
    { label: 'Novembre', value: '11' },
    { label: 'Décembre', value: '12' },
  ]
}

// Get last x months with format (MMM YYYY): ex -> nov. 2023
export function getLastXMonths(numberOfMonths: number, format: string) {
  return Array.from({ length: numberOfMonths }, (_, index) => {
    const newDate = moment().clone().subtract(index, 'months')
    return newDate.format(format)
  }).reverse()
}

// convert yyyy-mm to Month yyyy - ex : 2024-02 => Février 2024
export const displayMonthYearInFrFormat = (date: string) => {
  const formattedDate = new Date(`${date}-01`).toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
  })
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
}

export function subtractDay(dateString: string, nbrDays: number) {
  // Parse the date string (assuming "DD/MM/YYYY" format)
  const [day, month, year] = dateString.split('/').map(Number)
  // Create a Date object
  const date = new Date(year, month - 1, day)
  // Subtract nbr days
  date.setDate(date.getDate() - nbrDays)
  // Format the new date back to "DD/MM/YYYY"
  const newMonth = (date.getMonth() + 1).toString().padStart(2, '0') // month is 0-based
  const newDay = date.getDate().toString().padStart(2, '0')
  const newYear = date.getFullYear()

  return `${newDay}/${newMonth}/${newYear}`
}
