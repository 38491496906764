export interface Hydra {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'hydra:member': any
  'hydra:totalItems': number
  'hydra:view': {
    '@id': string
    'hydra:last': string
    'hydra:next': string
  }
}

export function isLastPage(data: Hydra) {
  const currentPage = data['hydra:view']['@id']
  const lastPage = data['hydra:view']['hydra:last']
  return !lastPage || currentPage === lastPage
}

export function getNextPage(data: Hydra) {
  return data['hydra:view']['hydra:next']
}

export function getMemberData(data: Hydra) {
  return data['hydra:member']
}

export function getTotalItems(data: Hydra): number {
  return data['hydra:totalItems']
}
