/* eslint-disable class-methods-use-this */
/* eslint-disable class-methods-use-this */
import DocumentTableAPI from '../utils/DocumentTableAPI'
import DocumentTableEntity from '../utils/DocumentTableEntity'

export const handleMimeType = (mimeType: string): string => {
  switch (mimeType) {
    case 'application/pdf':
      return 'pdf'
    case 'application/x-pdf':
      return 'pdf'
    case 'image/jpeg':
      return 'jpeg'
    case 'image/jpg':
      return 'jpg'
    case 'application/msword':
      return 'doc'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx'
    case 'application/vnd.ms-powerpoint':
      return 'ppt'
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx'
    case 'application/vnd.ms-excel':
      return 'xls'
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx'
    default:
      return ''
  }
}

class DocumentTableNormalizer {
  normalize(tableDocument: DocumentTableAPI[]): DocumentTableEntity[] {
    return tableDocument.map((item) => {
      return {
        id: `${item.companyId}${item.document.id}`,
        companyId: item.companyId,
        raisonSociale: item.raisonSociale ? item.raisonSociale : '',
        rueAdresse: item.rueAdresse ? item.rueAdresse : '',
        codePostalAdresse: item.codePostalAdresse ? item.codePostalAdresse : '',
        villeAdresse: item.villeAdresse ? item.villeAdresse : '',
        documentId: item.document.id,
        filename: item.document.filename,
        docTypeLabel: item.document.docType.label,
        docFamilyLabel: item.document.docType.docFamily.label,
        name: item.document.name,
        mimeType: handleMimeType(item.document.mimeType),
        createdAt: item.document.createdAt
          ? new Date(item.document.createdAt)
          : undefined,
      }
    })
  }
}

export default new DocumentTableNormalizer()
