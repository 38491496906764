import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SvgIcon from '../../Icons/SvgIcon'

export const StyledBandeauReset = styled.button`
  margin-bottom: 1rem;
  @media screen and (max-width: 996px) {
    align-self: flex-end;
    order: 1;
    margin-top: -3rem;
  }
`
interface Props {
  resetFilter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  filterResetClass?: string
  handleResetHover?: (event: React.MouseEvent) => void
  handleResetLeave?: (event: React.MouseEvent) => void
}
const BandeauReset = ({
  resetFilter,
  filterResetClass,
  handleResetHover,
  handleResetLeave,
}: Props) => {
  const { t } = useTranslation()
  return (
    <StyledBandeauReset
      onClick={resetFilter}
      className={filterResetClass}
      onMouseEnter={handleResetHover}
      onMouseLeave={handleResetLeave}
    >
      <span>{t('perimeterfilter.link.reset')}</span>
      <SvgIcon name="iconRight" className={`${filterResetClass}-icon`} />
    </StyledBandeauReset>
  )
}

BandeauReset.defaultProps = {
  filterResetClass: '',
  handleResetHover: () => undefined,
  handleResetLeave: () => undefined,
}
export default BandeauReset
