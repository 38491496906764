/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint @typescript-eslint/no-var-requires: "off" */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-loop-func */
import axios, { CancelTokenSource } from 'axios'

import moment from 'moment'
import JSZip from 'jszip'
import { getMemberData } from './hydra-services'
import ROUTES from '../utils/ROUTES'
import {
  annexContentForZip,
  contentForZip,
  getFilterForId,
} from '../utils/BillEntity'
import {
  BillsAndCreditsEntity,
  BillsAndCreditsByServiceEntity,
} from '../utils/BillSynthesisEntity'
import {
  extractMaterialCleanDataFromRaw,
  extractMaterialTreatedCleanDataFromRaw,
  extractServicelCleanDataFromRaw,
  prepareTableBillsCredits,
  prepareTableSimpleData,
  prepareTableWithArray,
} from '../utils/Synthese-utils'
import { getMyPerimeterOrganistaions } from './organization-services'
import { OrganizationType, InstitutionType } from '../store/state'
import {
  extractLibelleFamillies,
  extractLibelleServices,
} from './billing-synthesis-services'
import OrganizationEntity from '../utils/OrganizationEntity'
import InstitutionEntity from '../utils/InstitutionEntity'
import DocumentTableEntity from '../utils/DocumentTableEntity'
import { isCancel } from './billing-services'
import DocumentTableNormalizer from '../normalizer/DocumentTableNormalizer'
import {
  DocumentFamilyEntity,
  DocumentTypeEntity,
} from '../utils/DocumentEntity'
import { incrementKpiDownloadDoc } from './kpi-service'
import WasteRegisterEntity from '../utils/WasteRegisterEntity'
import EnumDocTypeVisibility from '../utils/enums/MyDocuments/DocTypes'

export interface zipInfo {
  data: Blob
  docName: string
  dirName: string
  status: string
  message: string
}

export interface annexZipInfo extends zipInfo {
  id: string
}

export interface DocumentUploadJson {
  docType?: string
  filename?: string
  companies?: any
  organisations?: any
  file?: any
  expireAt?: Date | null
}

interface DocumentCompaniesId {
  companyId: string
}

const BRM_ENABLED = !!parseInt(process?.env?.REACT_APP_BRM_ENABLED || '1', 10)
const XlsxTemplate = require('xlsx-template')

let cancelToken: CancelTokenSource
const labelsToApiFields: { [key: string]: string } = {
  Nom: 'document.filename',
  Catégorie: 'document.docType.docFamily.label',
  Type: 'document.docType.label',
  "Date d'ajout": 'document.createdAt',
  'Éts concerné': 'company',
}

export async function downloadBlobFile(
  fileName: string,
  fileUrl: string,
  docType?: string
): Promise<number> {
  let status = 400
  await axios
    .get(`${process.env.REACT_APP_BASE_API_WDH_URL}${fileUrl}`, {
      responseType: 'blob',
    })
    .then((response: any) => {
      if (response?.status === 200) {
        if (response?.data) {
          const urlBlob = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = urlBlob
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        }
      }
      status = response?.status
    })
    .catch((err: any) => {
      status = err.response ? err.response.status : 404
      console.log(`error doc`, err)
    })
  incrementKpiDownloadDoc(docType ?? 'FACTURE', status.toString())
  return status
}

export async function downloadMultipleBlobFile(
  allFiles: Array<contentForZip | annexContentForZip>
): Promise<Array<zipInfo | annexZipInfo>> {
  const zipInfos: Array<zipInfo | annexZipInfo> = []
  let index = 0
  let status = 400

  while (index < allFiles.length) {
    const tmpIndex = index
    const { docType } = allFiles[index]
    status = 400

    await axios
      .get(
        `${process.env.REACT_APP_BASE_API_WDH_URL}${allFiles[index].docUrl}`,
        {
          responseType: 'blob',
          validateStatus: (codeStatus: number) => {
            return codeStatus >= 200 && codeStatus < 500
          },
        }
      )
      .then((response: any) => {
        if (response?.status === 200) {
          if (response?.data) {
            if ('id' in allFiles[index]) {
              const fileData = allFiles[index] as annexContentForZip
              zipInfos.push({
                id: fileData.id,
                data: response.data,
                docName: allFiles[tmpIndex].docName,
                dirName: allFiles[tmpIndex].dirName,
                status: response.status,
                message: '',
              })
            } else {
              zipInfos.push({
                data: response.data,
                docName: allFiles[tmpIndex].docName,
                dirName: allFiles[tmpIndex].dirName,
                status: response.status,
                message: '',
              })
            }
          }
        } else {
          let errorMessage = ''
          switch (response?.status) {
            case 404:
              errorMessage = "Le document n'est actuellement pas disponible."
              break
            case 204:
              errorMessage = "Le document n'est actuellement pas disponible."
              break
            default:
              errorMessage = 'Une erreur est survenue'
              break
          }
          if ('id' in allFiles[index]) {
            const fileData = allFiles[index] as annexContentForZip
            zipInfos.push({
              id: fileData.id,
              data: response.data,
              docName: allFiles[tmpIndex].docName,
              dirName: allFiles[tmpIndex].dirName,
              status: response.status,
              message: errorMessage,
            })
          } else {
            zipInfos.push({
              data: response.data,
              docName: allFiles[tmpIndex].docName,
              dirName: allFiles[tmpIndex].dirName,
              status: response.status,
              message: errorMessage,
            })
          }
        }
        status = response?.status
      })
      .catch((err: any) => {
        status = err.response ? err.response.status : 404
        let errorMessage = ''
        switch (err.response.status) {
          case 404:
            errorMessage = "Le document n'est actuellement pas disponible."
            break
          case 204:
            errorMessage = "Le document n'est actuellement pas disponible."
            break
          default:
            errorMessage = 'Une erreur est survenue'
            break
        }
        if ('id' in allFiles[index]) {
          const fileData = allFiles[index] as annexContentForZip
          zipInfos.push({
            id: fileData.id,
            data: err.response,
            docName: allFiles[tmpIndex].docName,
            dirName: allFiles[tmpIndex].dirName,
            status: err.response.status ?? err.message.slice(-3),
            message: errorMessage,
          })
        } else {
          zipInfos.push({
            data: err.response,
            docName: allFiles[tmpIndex].docName,
            dirName: allFiles[tmpIndex].dirName,
            status: err.response.status ?? err.message.slice(-3),
            message: errorMessage,
          })
        }
      })
    incrementKpiDownloadDoc(docType, status.toString())
    index += 1
  }

  return zipInfos
}

export function GenerateExcel(
  data: WasteRegisterEntity[],
  startDate: Date,
  endDate: Date
) {
  fetch('/resources/registre_dechets_v2.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)
      const values = {
        extractDate: moment(new Date()).format('DD/MM/YYYY'),
        StartDate: moment(startDate).format('DD/MM/YYYY'),
        EndDate: moment(endDate).format('DD/MM/YYYY'),
        registre: data,
      }
      template.substitute(1, values)
      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Registre_dechets_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}

async function prepareIntroductionPagedata(
  Organisation: OrganizationType,
  Etablissements: InstitutionType
): Promise<any[]> {
  // depends on the case
  // case all the perimetre
  if (Etablissements.items.length > 0) {
    return Etablissements.items.map((etab) => {
      return { name: `${etab.name}${etab.address}` }
    })
  }
  if (Organisation.items.length > 0) {
    return Organisation.items.map((orga) => {
      return { name: orga.name }
    })
  }
  const res = await getMyPerimeterOrganistaions()
  return res.entities.map((item) => {
    return { name: item.name }
  })
}

export async function GenerateExcelBillingSynthese(
  selectedYear: string,
  billsData: BillsAndCreditsEntity[],
  creditsdData: BillsAndCreditsEntity[],
  redeemedData: BillsAndCreditsEntity[],
  servicesData: BillsAndCreditsByServiceEntity[],
  ServicesCredits: BillsAndCreditsByServiceEntity[],
  Organisation: OrganizationType,
  Etablissements: InstitutionType
) {
  // preparing data

  const Bills = extractMaterialCleanDataFromRaw(billsData)
  const credits = extractMaterialCleanDataFromRaw(creditsdData)
  const Redeemed = extractMaterialCleanDataFromRaw(redeemedData)
  const Services = extractServicelCleanDataFromRaw(servicesData)
  const servicesCredits = extractServicelCleanDataFromRaw(ServicesCredits)
  const onlyCreditsFamilies = extractLibelleFamillies(creditsdData).filter(
    (item) => {
      return !extractLibelleFamillies(billsData).includes(item)
    }
  )
  const preparedBillsCredits = prepareTableBillsCredits(
    Bills,
    credits,
    onlyCreditsFamilies
  )
  const onlyCreditsServices = extractLibelleServices(ServicesCredits).filter(
    (item) => {
      return !extractLibelleServices(servicesData).includes(item)
    }
  )
  const preparedReedemed = prepareTableSimpleData(Redeemed)
  const preparedServices = prepareTableBillsCredits(
    Services,
    servicesCredits,
    onlyCreditsServices
  )
  const preparedIntroduction = await prepareIntroductionPagedata(
    Organisation,
    Etablissements
  )

  fetch('/resources/SyntheseFacturationRaw.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)

      const values = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedIntroduction,
      }
      template.substitute(1, values)

      // Bills & Credits by material
      const Materialvalues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedBillsCredits,
      }
      template.substitute(2, Materialvalues)
      // redeemed by material
      const RedeemedValues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedReedemed,
      }
      template.substitute(3, RedeemedValues)
      // Bills & Credits by services
      const Servicesvalues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedServices,
      }
      template.substitute(4, Servicesvalues)

      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Synthèse_Facturation_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}

export async function GenerateExcelBillingSyntheseNoBRM(
  selectedYear: string,
  billsData: BillsAndCreditsEntity[],
  creditsdData: BillsAndCreditsEntity[],
  servicesData: BillsAndCreditsByServiceEntity[],
  ServicesCredits: BillsAndCreditsByServiceEntity[],
  Organisation: OrganizationType,
  Etablissements: InstitutionType
) {
  // preparing data

  const Bills = extractMaterialCleanDataFromRaw(billsData)
  const credits = extractMaterialCleanDataFromRaw(creditsdData)
  const Services = extractServicelCleanDataFromRaw(servicesData)
  const servicesCredits = extractServicelCleanDataFromRaw(ServicesCredits)
  const onlyCreditsFamilies = extractLibelleFamillies(creditsdData).filter(
    (item) => {
      return !extractLibelleFamillies(billsData).includes(item)
    }
  )
  const preparedBillsCredits = prepareTableBillsCredits(
    Bills,
    credits,
    onlyCreditsFamilies
  )
  const onlyCreditsServices = extractLibelleServices(ServicesCredits).filter(
    (item) => {
      return !extractLibelleServices(servicesData).includes(item)
    }
  )
  const preparedServices = prepareTableBillsCredits(
    Services,
    servicesCredits,
    onlyCreditsServices
  )

  const preparedIntroduction = await prepareIntroductionPagedata(
    Organisation,
    Etablissements
  )

  fetch('/resources/SyntheseFacturationNoBRM.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)

      const introductionValues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        data: preparedIntroduction,
      }
      template.substitute(1, introductionValues)

      // Bills & Credits by material
      const Materialvalues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedBillsCredits,
      }
      template.substitute(2, Materialvalues)
      // Bills & Credits by services
      const Servicesvalues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedServices,
      }
      template.substitute(3, Servicesvalues)

      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Synthèse_Facturation_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}

export async function GenerateExcelActivitySynthese(
  selectedYear: string,
  operatedData: any[],
  treatedData: any[],
  SortRate: any[],
  passNumber: any[],
  Organisation: OrganizationType,
  Etablissements: InstitutionType
) {
  // preparing data
  const operated = extractMaterialCleanDataFromRaw(operatedData)
  const treated = extractMaterialTreatedCleanDataFromRaw(treatedData)
  const preparedOperated = prepareTableSimpleData(operated)
  const preparedTreated = prepareTableSimpleData(treated)
  const preparedSortRate = prepareTableWithArray(SortRate)
  const preparedPassNumber = prepareTableWithArray(passNumber)

  const preparedIntroduction = await prepareIntroductionPagedata(
    Organisation,
    Etablissements
  )

  fetch('/resources/SyntheseActivitesRaw.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)

      const introductionValues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        data: preparedIntroduction,
      }
      template.substitute(1, introductionValues)

      // Operated materials
      const Operatedvalues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedOperated,
      }
      template.substitute(2, Operatedvalues)
      // treated materials
      const TreatedValues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedTreated,
      }
      template.substitute(3, TreatedValues)

      // Bills & Credits by services
      const Sortvalues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedSortRate,
      }

      template.substitute(4, Sortvalues)

      const Passvalues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        year: selectedYear,
        data: preparedPassNumber,
      }
      template.substitute(5, Passvalues)

      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Synthèse_Activités_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}
export async function GenerateExcelEvolutionFactureTable(
  StartDate: Date,
  EndDate: Date,
  operatedData: any[]
) {
  const tmplt = BRM_ENABLED
    ? '/resources/detailFacturationRaw.xlsx'
    : '/resources/detailFacturationRawNoBRM.xlsx'
  fetch(tmplt).then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)
      //  facturation data
      const expotedValues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        StartDate: moment(StartDate).format('DD/MM/YYYY'),
        EndDate: moment(EndDate).format('DD/MM/YYYY'),
        evolutionFactures: operatedData,
      }
      template.substitute(1, expotedValues)

      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Détail_Facturation_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}

export async function GenerateExcelEvolutionActivityTable(
  StartDate: Date,
  EndDate: Date,
  operatedData: any[]
) {
  fetch('/resources/detailActivitesRaw.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)
      //  facturation data
      const expotedValues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        StartDate: moment(StartDate).format('DD/MM/YYYY'),
        EndDate: moment(EndDate).format('DD/MM/YYYY'),
        evolutionActivites: operatedData,
      }
      template.substitute(1, expotedValues)

      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Détail_Activités_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}
async function exportExcelFile(
  template: any,
  exportedValues: any,
  exportName: string
) {
  const day = moment(new Date()).format('YYYY_MM_DD')
  template.substitute(1, exportedValues)

  const result = template.generate({ type: 'arraybuffer' })
  const filename = `${exportName}_${day}.xlsx`
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([result]))
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

export async function GenerateExcelEvolutionActivityMonthly(
  startDate: Date,
  endDate: Date,
  operatedData: any
) {
  fetch('/resources/DetailActivitesMensuelRaw.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const day = moment(new Date()).format('YYYY_MM_DD')
      const exportName = 'Détail_Activités'
      if (Object.entries(operatedData).length < 2) {
        const template = new XlsxTemplate(buffer)
        const exportedValues = {
          exportDate: moment(new Date()).format('DD/MM/YYYY'),
          startDate: moment(startDate).format('DD/MM/YYYY'),
          endDate: moment(endDate).format('DD/MM/YYYY'),
          year: Object.keys(operatedData)[0],
          detailsActivites: Object.values(operatedData)[0],
        }
        exportExcelFile(template, exportedValues, exportName)
      } else {
        const zip = new JSZip()
        Object.keys(operatedData).forEach((year) => {
          const template = new XlsxTemplate(buffer)
          const expotedValues = {
            exportDate: moment(new Date()).format('DD/MM/YYYY'),
            startDate: moment(startDate).format('DD/MM/YYYY'),
            endDate: moment(endDate).format('DD/MM/YYYY'),
            year,
            detailsActivites: operatedData[year],
          }
          template.substitute(1, expotedValues)
          const result = template.generate({ type: 'arraybuffer' })
          const filename = `${exportName}_${year}.xlsx`
          zip.file(filename, new Blob([result]))
        })
        const zipName = `${exportName}_${day}.zip`
        zip.generateAsync({ type: 'blob' }).then(function (content: any) {
          saveAs(content, zipName)
        })
      }
    })
  })
}

export async function GenerateExcelEvolutionBillingMonthly(
  startDate: Date,
  endDate: Date,
  operatedData: any
) {
  fetch('/resources/detailFacturationMensuelRaw.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const day = moment(new Date()).format('YYYY_MM_DD')
      const exportName = 'Détail_Facturation'
      if (Object.entries(operatedData).length < 2) {
        const template = new XlsxTemplate(buffer)

        const exportedValues = {
          exportDate: moment(new Date()).format('DD/MM/YYYY'),
          startDate: moment(startDate).format('DD/MM/YYYY'),
          endDate: moment(endDate).format('DD/MM/YYYY'),
          year: Object.keys(operatedData)[0],
          evolutionFactures: Object.values(operatedData)[0],
        }
        exportExcelFile(template, exportedValues, exportName)
      } else {
        const zip = new JSZip()
        Object.keys(operatedData).forEach((year) => {
          const template = new XlsxTemplate(buffer)
          const expotedValues = {
            exportDate: moment(new Date()).format('DD/MM/YYYY'),
            startDate: moment(startDate).format('DD/MM/YYYY'),
            endDate: moment(endDate).format('DD/MM/YYYY'),
            year,
            evolutionFactures: operatedData[year],
          }
          template.substitute(1, expotedValues)
          const result = template.generate({ type: 'arraybuffer' })
          const filename = `${exportName}_${year}.xlsx`
          zip.file(filename, new Blob([result]))
        })
        const zipName = `${exportName}_${day}.zip`
        zip.generateAsync({ type: 'blob' }).then(function (content: any) {
          saveAs(content, zipName)
        })
      }
    })
  })
}

export async function getDocumentTable(
  perPage: number,
  page: number,
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  filtredOrganisation: string[] = [],
  sortTable: any,
  filters: any[],
  history: any
): Promise<{
  results: DocumentTableEntity[]
  pageCount: number
  totalItems: number
}> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/documents`

  let listOfOrganization: DocumentTableEntity[] = []
  const params = new URLSearchParams()
  params.append(`page`, String(page))
  params.append(`perPage`, String(perPage))
  if (filtredOrganisation.length > 0) {
    filtredOrganisation.forEach((elem) => {
      params.append(`idOrganisation[]`, elem)
    })
  } else {
    organisation.forEach((org) => {
      params.append(`idOrganisation[]`, org.id)
    })
  }
  institution.forEach((insti) => {
    params.append(`companyId[]`, insti.id)
  })
  if (sortTable) {
    Object.keys(sortTable).forEach((key: string) => {
      params.append(`order[${labelsToApiFields[key]}]`, sortTable[key])
    })
  }

  const nomFilter = getFilterForId(filters, 'name')
  if (nomFilter) {
    params.append('document.filename', nomFilter)
  }

  const categoryFilter = getFilterForId(filters, 'docFamilyLabel')
  if (categoryFilter) {
    params.append('document.docType.docFamily.label', categoryFilter)
  }

  const typeFilter = getFilterForId(filters, 'docTypeLabel')
  if (typeFilter) {
    params.append('document.docType.label', typeFilter)
  }
  if (getFilterForId(filters, 'createdAt')) {
    const [startDate, endDate] = getFilterForId(filters, 'createdAt')
    params.append(
      'document.createdAt[after]',
      moment(startDate).format('YYYY-MM-DD')
    )
    params.append(
      'document.createdAt[before]',
      moment(endDate).format('YYYY-MM-DD')
    )
  }
  const companyFilter = getFilterForId(filters, 'company')
  if (companyFilter) {
    params.append('searchCompany', companyFilter)
  }

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })
    if (resp?.data) {
      // Result Normalization
      listOfOrganization = DocumentTableNormalizer.normalize(
        getMemberData(resp.data)
      )
      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      return {
        results: [...listOfOrganization],
        pageCount: Math.ceil(totalItems / perPage),
        totalItems,
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve({ pageCount: 0, results: err, totalItems: 0 })
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return Promise.resolve({ pageCount: 0, results: [], totalItems: 0 })
}
export async function downloadDocument(
  fileId: string,
  fileName: string
): Promise<number> {
  let status = 400
  await axios
    .get(`${process.env.REACT_APP_BASE_API_URL}/documents/${fileId}/download`, {
      responseType: 'blob',
    })
    .then((response: any) => {
      if (response?.status === 200) {
        if (response?.data) {
          const urlBlob = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = urlBlob
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        }
      }
      status = response?.status
    })
    .catch((err: any) => {
      status = err.response ? err.response.status : 404
      console.log(`error doc`, err)
    })
  incrementKpiDownloadDoc('AUTRES', status.toString())
  return status
}
// function to remove link between  document an d company
export async function removeDocumentLinkcompany(
  companyId: string,
  documentId: string,
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/asso_documents_companies/companyId=${companyId};document=${documentId}`
  let status = 400
  try {
    const resp = await axios({
      method: 'DELETE',
      url,
    })

    status = resp?.status
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function getFamilies(): Promise<DocumentFamilyEntity[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/doc_families`

  try {
    const resp = await axios.get(url)
    const result = getMemberData(resp.data)

    if (result) {
      return Promise.all(result)
    }
  } catch (err) {
    throw new Error(
      `Erreur lors de l'appel de l'api getFamilies avec l'url: ${url}`
    )
  }
  return []
}

export async function getDocTypes(
  filter: EnumDocTypeVisibility[] = []
): Promise<DocumentTypeEntity[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/doc_types`
  const params = new URLSearchParams()

  filter.forEach((visibility) => {
    params.append(`visibleIn[]`, visibility)
  })

  try {
    const resp = await axios.get(url, { params })
    const result = getMemberData(resp.data)

    if (result) {
      return Promise.all(result)
    }
  } catch (err) {
    throw new Error(
      `Erreur lors de l'appel de l'api getDocTypes avec l'url: ${url}`
    )
  }
  return []
}

export async function getBase64File(
  file: File
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve) => {
    let baseURL
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => {
      baseURL = reader.result
      resolve(baseURL)
    }
  })
}

export async function documentUploadJSON(
  file: string,
  fileName: string,
  companyIds: string[] = [],
  docType: string,
  expireAt: Date | null,
  organisationsIds: number[] = []
): Promise<any> {
  const companiesEntity = companyIds.map((company) => ({ companyId: company }))
  const organisationsEntity = organisationsIds.map((organisation) => ({
    organisationId: organisation.toString(),
  }))

  console.log(companiesEntity, organisationsEntity)

  const doc: DocumentUploadJson = {
    docType,
    filename: fileName,
    file,
    expireAt,
  }

  if (companyIds.length) {
    doc.companies = companiesEntity
  }
  if (organisationsIds.length) {
    doc.organisations = organisationsEntity
  }

  const url = `${process.env.REACT_APP_BASE_API_URL}/documents`

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: doc,
    })

    return resp
  } catch (err) {
    throw new Error(
      `Erreur lors de l'appel de l'api DocumentUpload avec l'url: ${url}`
    )
  }
}

export async function getDocumentById(docId: string): Promise<any> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/documents/${docId}`

  try {
    const resp = await axios({
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json;',
      },
    })

    if (resp?.data) {
      return Promise.resolve(resp.data)
    }
  } catch (err) {
    throw new Error(
      `Erreur lors de l'appel de l'api DocumentUpload avec l'url: ${url}`
    )
  }

  return Promise.resolve([])
}

export async function patchDocument(
  doc: DocumentUploadJson,
  id: string
): Promise<any> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/documents/${id}`

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: doc,
    })

    return resp
  } catch (err) {
    throw new Error(
      `Erreur lors de l'appel de l'api DocumentUpload avec l'url: ${url}`
    )
  }
}

export async function getAttestationValorisation(
  numDebiteur = '',
  anneeReference = ''
): Promise<any> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/attestation_valorisation`
  const params = `?numDebiteur=${numDebiteur}&anneeRef=${anneeReference}`

  try {
    const resp = await axios({
      method: 'GET',
      url: url + params,
    })
    if (resp?.data) {
      return Promise.resolve(resp.data)
    }
  } catch (err) {
    throw new Error(
      `error when getting attestation valorisation with url: ${url}`
    )
  }

  return Promise.resolve([])
}

export async function downloadAttestationValorisation(
  idDoc = '',
  docName = ''
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/download_attestation_valorisation`
  const params = `?idDoc=${idDoc}`

  let status = 400

  try {
    await axios({
      method: 'GET',
      url: url + params,
      responseType: 'blob',
    })
      .then((response: any) => {
        if (response?.status === 200) {
          if (response?.data) {
            const urlBlob = window.URL.createObjectURL(
              new Blob([response.data])
            )
            const link = document.createElement('a')
            link.href = urlBlob
            link.setAttribute('download', docName)
            document.body.appendChild(link)
            link.click()
          }
        }
        status = response?.status
      })
      .catch((err: any) => {
        console.log(`Upload`, err)
        status = err.response ? err.response.status : 404
      })
    incrementKpiDownloadDoc('ATTESTATION_VALO', status.toString())
  } catch (err) {
    throw new Error(
      `error when downloading attestation valorisation with url: ${url}`
    )
  }

  return status
}
