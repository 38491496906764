import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ScreenContainerSimpleLayoutConnexion from '../../layouts/ScreenContainerSimpleLayoutConnexion'
import ErrorBody from './ErrorBody'
import MAINTENANCE_TYPE from '../../utils/maintenance-utils'
import ROUTES from '../../utils/ROUTES'

interface Props {
  title: string
  description: string | JSX.Element
  type: string
  chatbot?: boolean
  textButton?: string
  externalLink?: string
  connected: boolean
}

const ErrorInfo: React.FC<Props> = ({
  title,
  description,
  type,
  chatbot,
  textButton,
  externalLink,
  connected,
}: Props) => {
  const isLogoClickable = type !== 'maintenanceError'

  const history = useHistory()
  // if is not maintenance: redirect to the welcome page
  useEffect(() => {
    const maintenanceMode = localStorage.getItem(
      `maintenanceMode${MAINTENANCE_TYPE.ALL}`
    )
    if (maintenanceMode === 'false' && type === 'maintenanceError') {
      history.push(ROUTES.CheckEmail)
    }
  }, [])

  return (
    <ScreenContainerSimpleLayoutConnexion
      isLogoClickable={isLogoClickable}
      isLogged={connected}
    >
      <ErrorBody
        title={title}
        description={description}
        type={type}
        chatbot={chatbot}
        textButton={textButton}
        externalLink={externalLink}
      />
    </ScreenContainerSimpleLayoutConnexion>
  )
}

ErrorInfo.defaultProps = {
  chatbot: true,
  textButton: undefined,
  externalLink: undefined,
}

export default ErrorInfo
