import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button as ButtonBootstrap } from 'react-bootstrap'
import MenuItem from '../../components/Menus/MenuItem'
import logo from '../../assets/logo.png'
import ROUTES from '../../utils/ROUTES'
import SignUpSubMenu from '../../components/Menus/SignUpSubMenu'
import SvgIcon from '../../components/Icons/SvgIcon'

const CenterStepsHeaderLayout: React.FC = () => {
  const { pathname } = useLocation()
  const [sideBarOpen, setSideBarOpen] = useState(false)

  const menuClassname = sideBarOpen ? 'menu menu--opened' : 'menu menu--closed'

  useEffect(() => {
    setSideBarOpen(false)
  }, [pathname])

  return (
    <div className="screen__fixed screen-sign-up">
      <div className="menu__burger">
        <ButtonBootstrap
          variant="transparent"
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <SvgIcon name="menu" className="menu-icon" />
        </ButtonBootstrap>
        <MenuItem
          title={<img alt="logo Suez" src={logo} className="menu__logo" />}
          link={ROUTES.CheckEmail}
        />
      </div>
      <div className={menuClassname}>
        <MenuItem
          title={<img alt="logo Suez" src={logo} className="menu__logo" />}
          link={ROUTES.CheckEmail}
        />
        <SignUpSubMenu />
      </div>
    </div>
  )
}

export default CenterStepsHeaderLayout
