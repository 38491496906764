import styled from 'styled-components'

interface Props {
  showList?: boolean
}
const StyledSmartListe = styled.div<Props>`
  padding: 1.5rem;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  display: ${({ showList }) => (showList ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 0.4rem 0.4rem 1rem 0.4rem rgb(0 0 0 / 44%);
  z-index: 1000;
  max-height: 536px;
  overflow: auto;
`

export default StyledSmartListe
