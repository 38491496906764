import moment from 'moment'
import {
  KPIDemandsAPI,
  KpiProperties,
} from './interfaces/SuperAdministration/KPI'

const formatKPIDemandesForExport = (
  monthExportLabels: string[],
  data: KPIDemandsAPI[]
) => {
  const typeRejeuLabels = ['1ère tentative', 'Rejeu 1', 'Rejeu 2', 'Rejeu 3']
  const result: KpiProperties[] = []

  // Remplissage des données
  data.forEach((entry) => {
    const {
      categoryLabel,
      motifLabel,
      anneeMois,
      typeRejeu,
      succes,
      echec,
    } = entry

    // Trouver l'index du mois dans monthExportLabels
    const monthIndex = monthExportLabels.indexOf(
      moment(anneeMois, 'YYYY_MM').format('MMMM-YYYY')
    )

    if (monthIndex !== -1) {
      // Chercher si l'entrée existe déjà
      const existingEntry = result.find(
        (item) =>
          item.category === categoryLabel &&
          item.motif === motifLabel &&
          item.typeRejeu === typeRejeuLabels[typeRejeu]
      )

      // Si l'entrée existe, mettre à jour les données
      if (existingEntry) {
        existingEntry.data[monthIndex] = {
          succes,
          echec,
        }
      } else {
        // Sinon, créer une nouvelle entrée
        const newEntry: KpiProperties = {
          category: categoryLabel,
          motif: motifLabel,
          typeRejeu: typeRejeuLabels[typeRejeu],
          data: Array(14).fill({ succes: 0, echec: 0 }), // Initialiser avec des zéros
        }

        // Remplir les données pour le mois spécifique
        newEntry.data[monthIndex] = {
          succes,
          echec,
        }

        result.push(newEntry)
      }
    }
  })

  // Trier le tableau par 'category', puis par 'motif', puis par 'typeRejeu'
  result.sort((a, b) => {
    const categoryComparison = a.category.localeCompare(b.category)
    if (categoryComparison !== 0) return categoryComparison

    const motifComparison = a.motif.localeCompare(b.motif)
    if (motifComparison !== 0) return motifComparison

    return (
      typeRejeuLabels.indexOf(a.typeRejeu) -
      typeRejeuLabels.indexOf(b.typeRejeu)
    )
  })

  return result
}

export default formatKPIDemandesForExport
