/* eslint-disable no-case-declarations */
import {
  UserType,
  OrganizationType,
  InstitutionType,
  AllRolesType,
  UiType,
  UserInCreationType,
  CreateModifyUserType,
  SignUpUserType,
  OrganizationValidationType,
  InstitutionValidationType,
  ImpersonnationType,
  ApiCallBlockType,
  InstitutionPerimeterType,
  ServiceType,
} from './state'
import { APP_ACTIONS, ActionTypes } from './actions'
import PERIMETER_MODE from '../utils/PERIMETER_MODE'

// user
export const userReducer = (state: UserType, action: APP_ACTIONS) => {
  switch (action.type) {
    case ActionTypes.SET_USER_TYPE:
      return {
        ...state,
        isInternal: action.payload.isInternal,
        email: action.payload.email,
        byPassRecaptch: action.payload.byPassRecaptch,
      }
    case ActionTypes.SET_TOKEN_INFO:
      return {
        ...state,
        email: action.payload.username,
        userId: action.payload.userId,
        isInternal: action.payload.isInternal,
        organizations: action.payload.organizations,
        firstname: action.payload.firstname,
        roleNames: action.payload.roleNames,
        accessDemands: action.payload.accessDemands,
        accessDocs: action.payload.accessDocs,
        idContactWdh: action.payload.idContactWdh,
        isSuperAdminTech: action.payload.isSuperAdminTech,
      }
    case ActionTypes.SET_FIRSTNAME:
      return { ...state, firstname: action.payload.firstname }
    default:
      return state
  }
}

// UserInCreation
export const UserInCreationReducer = (
  state: UserInCreationType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_CREATE_USERID:
      return { ...state, userId: action.payload.userId }
    default:
      return state
  }
}

// createModifyUser
export const createModifyUserReducer = (
  state: CreateModifyUserType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_IS_CREATION:
      return { ...state, isCreation: action.payload.isCreation }
    case ActionTypes.SET_STEP2_STATE:
      return { ...state, step2Disabled: action.payload.step2Disabled }
    case ActionTypes.SET_STEP3_STATE:
      return { ...state, step3Disabled: action.payload.step3Disabled }
    case ActionTypes.SET_USERID_CREATEMODIFY:
      return { ...state, userId: action.payload.userId }
    case ActionTypes.SET_ACTUAL_STEP:
      return { ...state, actualStep: action.payload.actualStep }
    case ActionTypes.SET_USER_ENTITY:
      return { ...state, userEntity: action.payload.userEntity }
    default:
      return state
  }
}
// user sign up
export const signUpUserReducer = (
  state: SignUpUserType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_INSC_STEP2_STATE:
      return { ...state, step2Disabled: action.payload.step2Disabled }
    case ActionTypes.SET_INSC_STEP3_STATE:
      return { ...state, step3Disabled: action.payload.step3Disabled }
    case ActionTypes.SET_INSC_ACTUAL_STEP:
      return { ...state, actualStep: action.payload.actualStep }
    default:
      return state
  }
}

// impersonnation
export const impersonnationReducer = (
  state: ImpersonnationType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_IS_IMPERSONNATION:
      return { ...state, isImpersonnation: action.payload.isImpersonnation }
    case ActionTypes.SET_USERNAME_IMPERSONNATION:
      return {
        ...state,
        nameImpersonnated: action.payload.nameImpersonnated,
        accessDemands: action.payload.accessDemands,
        emailImpersonated: action.payload.emailImpersonated,
      }
    default:
      return state
  }
}

// apiCallBLock
export const apiCallBlockReducer = (
  state: ApiCallBlockType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_API_CALL_BLOCK:
      return { ...state, APICallBlocked: action.payload.APICallBlocked }
    default:
      return state
  }
}

export const serviceReducer = (state: ServiceType, action: APP_ACTIONS) => {
  switch (action.type) {
    case ActionTypes.SET_SERVICE_LIST:
      return { ...state, items: [...action.payload.serviceList] }
    default:
      return state
  }
}

// organization$
export const organizationReducer = (
  state: OrganizationType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_ORGANIZATION_LIST:
      return { ...state, items: [...action.payload.organizationList] }
    case ActionTypes.RESET_PERIMETER_FILTER:
      return { ...state, items: [] }
    default:
      return state
  }
}

export const organizationValidationReducer = (
  state: OrganizationValidationType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_ORGANIZATION_VALIDATED:
      return {
        ...state,
        organizationValidated: action.payload.organizationValidated,
      }
    default:
      return state
  }
}

// institution
export const institutionReducer = (
  state: InstitutionType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_INSTITUTION_LIST:
      return { ...state, items: [...action.payload.institutionList] }
    case ActionTypes.RESET_PERIMETER_FILTER:
      return { ...state, items: [] }
    case ActionTypes.SET_INSTITUTION_VALIDATED:
      return {
        ...state,
        institutionValidated: action.payload.institutionValidated,
      }
    default:
      return state
  }
}
export const institutionPerimeterReducer = (
  state: InstitutionPerimeterType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_INSTITUTION_PERIMETER:
      return { ...state, allItems: [...action.payload.institutionPerimeter] }
    default:
      return state
  }
}

export const institutionValidationReducer = (
  state: InstitutionValidationType,
  action: APP_ACTIONS
) => {
  switch (action.type) {
    case ActionTypes.SET_INSTITUTION_VALIDATED:
      return {
        ...state,
        institutionValidated: action.payload.institutionValidated,
      }
    default:
      return state
  }
}

// allRoles
export const allRolesReducer = (state: AllRolesType, action: APP_ACTIONS) => {
  switch (action.type) {
    case ActionTypes.SET_ALL_ROLES:
      return { ...state, items: [...action.payload.allRoles] }
    default:
      return state
  }
}

// ui
export const uiReducer = (state: UiType, action: APP_ACTIONS) => {
  switch (action.type) {
    case ActionTypes.SET_BACKGROUND_COLOR:
      return { ...state, backgroundColor: action.payload.backgroundColor }
    case ActionTypes.SWITCH_PERIMETER_FILTER_MODE:
      const { perimeterFilterMode } = state
      const newMode =
        perimeterFilterMode === PERIMETER_MODE.DECREASED_MODE
          ? PERIMETER_MODE.INCREASED_MODE
          : PERIMETER_MODE.DECREASED_MODE
      return { ...state, perimeterFilterMode: newMode }
    case ActionTypes.SWITCH_VIEW_MODE:
      window.localStorage.setItem('viewMode', action.payload.viewMode)
      return { ...state, viewMode: action.payload.viewMode }
    default:
      return state
  }
}
