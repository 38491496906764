/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CollapseDto from './CollapseDto'
import { frontCreatedOrganization } from './OrganizationEntity'
import BreadcrumbNodeDto from './BreadcrumbNodeDto'
import { findNode } from './collapse-utils'
import {
  createOrganization,
  getFormatedEtablissementsAsChildren,
  getFormatedOrganisationToCreate,
  putEtablissementsEnfant,
  removeInstitutionLinkChild,
  updateOrganizationName,
} from '../services/organization-services'
import { filterArray } from './array-utils'

export enum GROUPING_TYPE {
  BY_INSTITUTION = 'BY_INSTITUTION',
  BY_ORGANIZATION = 'BY_ORGANIZATION',
}

export enum WDH_GROUPING_TYPE {
  WHD_BY_INSTITUTION = 'Regroupement Entreprises',
  WHD_BY_ORGANIZATION = 'Regroupement Organisations',
}

export enum GROUPING_FAMILY {
  INTERNAL = 'INTERNAL',
  CLIENT = 'CLIENT',
}

export enum WHD_GROUPING_FAMILY {
  WHD_INTERNAL = 'Regroupement Interne',
  WHD_CLIENT = 'Regroupement Client',
  WHD_GCN = 'GCN',
  WHD_PORTFEUILLE = 'Portefeuille Commercial',
}

export enum ORGANIZATION_STATUT {
  STATUT_ACTIF = 'Actif',
  STATUT_ENCREATION = 'EnCreation',
  STATUT_INACTIF = 'Inactif',
  STATUT_SUPPRIME = 'Supprime',
}
export enum ACTION_TYPE {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}
export interface currentTreeOrganization {
  id: string
  level: number
  parentId: string
}

export interface importAssociateIds {
  id: string
  newId?: number
}
export function reBuildbreadCrumb(
  id: string,
  orgaList: frontCreatedOrganization[]
): BreadcrumbNodeDto[] {
  const result: BreadcrumbNodeDto[] = []
  const initialElem = orgaList.filter((elem) => {
    return elem.id === id
  })
  const parent = initialElem.map(({ parentId }) => {
    return parentId
  })
  orgaList.forEach((orga) => {
    if (orga.id === parent[0]) {
      result.push({ id: orga.id, label: orga.title })
      const recResult = reBuildbreadCrumb(orga.id, orgaList)
      if (recResult)
        recResult.forEach((member) => {
          result.push(member)
        })
    }
  })
  return result
}

export function createImportedSubOrga(
  organisationCollapseSto: CollapseDto | undefined,
  idParent: string,
  // currentOrganization: CollapseDto | undefined,
  currentLevel: number,
  currentGroupingFamily: GROUPING_FAMILY | undefined
) {
  const table: frontCreatedOrganization[] = []
  if (organisationCollapseSto) {
    const { id } = organisationCollapseSto
    const { type } = organisationCollapseSto
    const title = organisationCollapseSto.title
      ? organisationCollapseSto.title
      : ''
    const level = organisationCollapseSto.level
      ? organisationCollapseSto.level + currentLevel
      : 0
    let children: string[] = []
    let typeRegroupement: GROUPING_TYPE = GROUPING_TYPE.BY_ORGANIZATION
    if (organisationCollapseSto.children) {
      organisationCollapseSto.children.forEach(async (element) => {
        if (element.type === 'table') {
          children = element.dataIds ? element.dataIds : []
          typeRegroupement = GROUPING_TYPE.BY_INSTITUTION
        }
        table.push({
          id,
          parentId: idParent,
          type,
          title,
          level,
          children: children.filter((idEntreprise) => idEntreprise !== '-1'),
          typeOrganisation: typeRegroupement,
          familleOrganisation: currentGroupingFamily,
        })

        if (element.type === 'collapse') {
          const nodeSecond: { ref: CollapseDto | undefined } = {
            ref: undefined,
          }
          findNode(organisationCollapseSto, element.id, nodeSecond)
          const recResult = createImportedSubOrga(
            nodeSecond.ref,
            id,
            currentLevel,
            currentGroupingFamily
          )
          if (recResult)
            recResult.forEach((elem: frontCreatedOrganization) => {
              table.push(elem)
            })
        }
      })
    }
  }
  // fonction au moment du return pour enlever les doublons
  return table.filter((v, i, a) => a.findIndex((xt) => xt.id === v.id) === i)
}

export function extractOrganizationsFromCollapseDto(
  organisationCollapseSto: CollapseDto | undefined,
  idParent: string
) {
  const table: frontCreatedOrganization[] = []
  if (organisationCollapseSto) {
    const { id } = organisationCollapseSto
    const { type } = organisationCollapseSto
    const { typeOrganisation } = organisationCollapseSto
    const title = organisationCollapseSto.title
      ? organisationCollapseSto.title
      : ''
    const level = organisationCollapseSto.level
      ? organisationCollapseSto.level
      : 0

    let children: string[] = []
    if (organisationCollapseSto.children) {
      organisationCollapseSto.children.forEach(async (element) => {
        if (element.type === 'table') {
          children = element.dataIds ? element.dataIds : []
          if (children.length === 1 && children[0] === '-1')
            children = filterArray(children, ['-1'])
        }
        table.push({
          id,
          parentId: idParent,
          type,
          title,
          level,
          children,
          typeOrganisation,
          institutionAlreadyLoaded: false,
        })

        if (element.type === 'collapse') {
          const nodeSecond: { ref: CollapseDto | undefined } = {
            ref: undefined,
          }
          findNode(organisationCollapseSto, element.id, nodeSecond)
          const recResult = extractOrganizationsFromCollapseDto(
            nodeSecond.ref,
            id
          )
          if (recResult) {
            recResult.forEach((elem: frontCreatedOrganization) => {
              if (elem.children.length > 0 && elem.children.includes('-1')) {
                children = []
              } else {
                children = elem.children
              }
              table.push({
                id: elem.id,
                parentId: elem.parentId,
                type: elem.type,
                title: elem.title,
                level: elem.level,
                children,
                typeOrganisation: elem.typeOrganisation,
                institutionAlreadyLoaded: false,
              })
            })
          }
        }
      })
    }
    if (typeOrganisation === GROUPING_TYPE.BY_INSTITUTION) {
      table.push({
        id,
        parentId: idParent,
        type,
        title,
        level,
        children,
        typeOrganisation,
        institutionAlreadyLoaded: false,
      })
    }
  }
  // fonction au moment du return pour enlever les doublons
  return table.filter((v, i, a) => a.findIndex((xt) => xt.id === v.id) === i)
}
// function used when deleting organization
export function getAllCurrentTreeOrganizations(
  organizationList: frontCreatedOrganization[],
  idOrganizationToDelete: string
): currentTreeOrganization[] {
  const organizationsToDelete: currentTreeOrganization[] = []
  organizationList.forEach((orga) => {
    if (orga.parentId === idOrganizationToDelete) {
      organizationsToDelete.push({
        id: orga.id,
        level: orga.level,
        parentId: orga.parentId,
      })
      const recResult = getAllCurrentTreeOrganizations(
        organizationList,
        orga.id
      )
      if (recResult)
        recResult.forEach((member: currentTreeOrganization) => {
          organizationsToDelete.push(member)
        })
    }
  })
  return organizationsToDelete
}
// check the presence of an organization in the current Tree
export function isInCurrentOrganizationTree(
  idOrganization: string,
  currentTree: currentTreeOrganization[]
) {
  return currentTree.some(function (item) {
    return item.id === idOrganization
  })
}

// OOL test

export async function updateOrganizationListInstitutions(
  listofOrganisation: frontCreatedOrganization[],
  currentSubOrga: frontCreatedOrganization[],
  subInstitutionIds: string[],
  currentSubOrgaWithInstitutionsName: string | undefined,
  history: any
): Promise<frontCreatedOrganization[]> {
  const oldCurrentSubOrga = listofOrganisation.filter(
    (orga: { id: string }) => orga.id === currentSubOrga[0].id
  )[0]
  const institutionToDelete = oldCurrentSubOrga.children.filter(
    (value: string) => !subInstitutionIds.includes(value)
  )
  const institutionToAdd = subInstitutionIds.filter(
    (value: string) => !oldCurrentSubOrga.children.includes(value)
  )
  const intermediate = listofOrganisation.filter((element) => {
    return element.id !== oldCurrentSubOrga.id
  })
  let name = oldCurrentSubOrga.title
  if (currentSubOrgaWithInstitutionsName) {
    // call API to Update Name
    await updateOrganizationName(
      oldCurrentSubOrga.id,
      currentSubOrgaWithInstitutionsName,
      history
    )
    name = currentSubOrgaWithInstitutionsName
  }
  // setIsReady(false)
  const result = intermediate.concat([
    {
      id: oldCurrentSubOrga.id,
      parentId: oldCurrentSubOrga.parentId,
      type: oldCurrentSubOrga.type,
      title: name,
      level: oldCurrentSubOrga.level,
      children: subInstitutionIds,
      typeOrganisation: oldCurrentSubOrga.typeOrganisation,
      familleOrganisation: oldCurrentSubOrga.familleOrganisation,
    },
  ])

  if (institutionToDelete.length > 0) {
    // Call API to Delete institutions
    institutionToDelete.forEach(async (idInstitution: string) => {
      await removeInstitutionLinkChild(
        oldCurrentSubOrga.id,
        idInstitution,
        history
      )
    })
  }

  if (institutionToAdd.length > 0) {
    // Call API to add institutions
    await putEtablissementsEnfant(
      getFormatedEtablissementsAsChildren(institutionToAdd),
      oldCurrentSubOrga.id,
      history
    )
  }
  return result
}

/**
 * Checks if we can activate the organisation in creation or modification
 */
export function canBeActivated(
  listOfOrganization: frontCreatedOrganization[]
): boolean {
  let result = true
  listOfOrganization.forEach((noeud) => {
    if (noeud.typeOrganisation === GROUPING_TYPE.BY_ORGANIZATION) {
      const recResult =
        listOfOrganization.filter((orga) => {
          return orga.parentId === noeud.id
        }).length > 0
      result = result && recResult
    } else if (!noeud.institutionAlreadyLoaded) {
      result = result && !noeud.institutionAlreadyLoaded
    } else {
      const resultRec = noeud.children.length > 0
      result = result && resultRec
    }
  })
  return result
}

export function canTerminate(
  isLoading: boolean,
  orgalist: frontCreatedOrganization[]
): boolean {
  if (isLoading) return false
  return canBeActivated(orgalist)
}

// post the imported organization
export async function duplicateImportedOrganizations(
  organizationSource: frontCreatedOrganization[],
  idParent: string,
  history: any
): Promise<frontCreatedOrganization[]> {
  const result: frontCreatedOrganization[] = []
  const roots = organizationSource.filter((org) => org.parentId === idParent)
  const childrens = organizationSource.filter(
    (org) => org.parentId !== idParent
  )
  const listIds: importAssociateIds[] = []
  for (const root of roots) {
    const technicalId = await createOrganization(
      getFormatedOrganisationToCreate(root, parseInt(idParent, 10)),
      history
    )
    if (root.typeOrganisation === GROUPING_TYPE.BY_INSTITUTION) {
      // link institutions
      await putEtablissementsEnfant(
        getFormatedEtablissementsAsChildren(root.children),
        technicalId.toString(),
        history
      )
    }

    listIds.push({ id: root.id, newId: technicalId })
    result.push({
      id: technicalId.toString(),
      parentId: idParent,
      type: root.type,
      title: root.title,
      level: root.level,
      children: root.children,
      typeOrganisation: root.typeOrganisation,
      familleOrganisation: root.familleOrganisation,
    })
  }
  for (const child of childrens) {
    const fatherId = listIds
      .filter((el) => el.id === child.parentId)
      .map((em) => {
        return em.newId
      })[0]
    const chilTechId = await createOrganization(
      getFormatedOrganisationToCreate(child, fatherId),
      history
    )
    if (child.typeOrganisation === GROUPING_TYPE.BY_INSTITUTION) {
      // link institutions
      await putEtablissementsEnfant(
        getFormatedEtablissementsAsChildren(child.children),
        chilTechId.toString(),
        history
      )
    }
    listIds.push({ id: child.id, newId: chilTechId })
    result.push({
      id: chilTechId.toString(),
      parentId: fatherId?.toString(),
      type: child.type,
      title: child.title,
      level: child.level,
      children: child.children,
      typeOrganisation: child.typeOrganisation,
      familleOrganisation: child.familleOrganisation,
    })
  }
  return result
}
