/* eslint-disable class-methods-use-this */
import { BillsAndCreditsWDH } from '../utils/BillingSynthesisWDH'
import { BillsAndCreditsEntity } from '../utils/BillSynthesisEntity'

class BillingSynthesisNormalizer {
  normalize(billAndCreditList: BillsAndCreditsWDH[]): BillsAndCreditsEntity[] {
    return billAndCreditList.map((item) => {
      return {
        indicateurCode: item.indicateurCode,
        indicateurLibelle: item.indicateurLibelle,
        annee: item.annee,
        mois: item.mois,
        unite: item.unite,
        amount: Number(item.numerateur),
        denominateur: Number(item.denominateur),
        fkfamilledechet: item.fkfamilledechet ? item.fkfamilledechet : '',
        familleDechetCode: item.familleDechetCode ? item.familleDechetCode : '',
        familleDechetLibelle: item.familleDechetLibelle
          ? item.familleDechetLibelle
          : '',
      }
    })
  }
}

export default new BillingSynthesisNormalizer()
