import React from 'react'
import SvgIcon from '../Icons/SvgIcon'

interface Props {
  children: React.ReactNode
  className?: string
  id?: string
  icon?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  dataTest?: string
}

const Button: React.FC<Props> = ({
  children,
  className,
  id,
  disabled,
  onClick,
  icon,
  dataTest,
}: Props) => {
  return (
    <button
      type="button"
      id={id}
      className={`button ${className} ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
    >
      {children}
      {icon && <SvgIcon name={icon} className="ml-5 mr-0" />}
    </button>
  )
}

Button.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => undefined,
  id: '',
  icon: '',
  dataTest: '',
}

export default Button
