/* eslint-disable camelcase */
import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import Button from './Button'
import {
  DecodedToken,
  dispatchUserInformations,
} from '../../services/user-services'
import { AppContext } from '../../store/context'
import SvgIcon from '../Icons/SvgIcon'
import { ActionTypes } from '../../store/actions'
import handleNavigation from '../../utils/RedirectionUtils'
import Color from '../../utils/COLORS'
import Loading from '../Loaders/Loading'
import { searchOrganizationByIds } from '../../services/organization-services'
import { getEntreprisesByIds } from '../../services/institution-services'
import ROUTES from '../../utils/ROUTES'

interface Props {
  className: string
  externalLink?: string
  textButton?: string
  showIcon?: boolean
}

const ButtonExitImpersonnation: React.FC<Props> = ({
  className,
  externalLink,
  textButton,
  showIcon,
}: Props) => {
  const { state, dispatch, updateRoleName } = useContext(AppContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const handleExitImpersonnation = async () => {
    if (externalLink) {
      // En cas de redirection depuis l'ancienne url de portail
      window.location.href = externalLink
      return
    }
    setIsLoading(true)
    dispatch({
      type: ActionTypes.SET_API_CALL_BLOCK,
      payload: {
        APICallBlocked: true,
      },
    })
    const accessToken = localStorage.getItem('access_token')
    // utilisateur non connecté
    if (!accessToken) {
      history.push(ROUTES.CheckEmail)
      return
    }

    sessionStorage.removeItem('impersonation_token')
    sessionStorage.removeItem('refresh_token')
    sessionStorage.removeItem('impersonate_mode')

    // Connecter en tant que admin
    axios.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    }

    // Remettre les données d'admin
    const decodedToken: DecodedToken = jwt_decode(accessToken)
    const userData = {
      username: decodedToken.username,
      userId: decodedToken.user_id,
      isInternal: Boolean(decodedToken.isInternal),
      organizations: decodedToken.organizations.map((org) => org.id),
      roleNames: decodedToken.roles.map((e) => e.name),
      firstname: decodedToken.first_name,
      accessDemands: Boolean(decodedToken.access_demands),
      accessDocs: Boolean(decodedToken.access_docs),
    }

    dispatchUserInformations(
      { ...userData, backgroundColor: Color.GRAY_0 },
      dispatch,
      state
    )
    updateRoleName(userData.roleNames)

    try {
      const cookies = new Cookies()
      // Handle perimeter save in cookies
      const institutionsList = cookies.get('chosenInstitution')
      const organizationsList = cookies.get('chosenOrganization')
      if (organizationsList) {
        const { entities } = await searchOrganizationByIds(
          organizationsList,
          history
        )
        const filterdEntities = entities.filter((entity) => {
          return organizationsList.includes(entity.id)
        })
        if (filterdEntities)
          dispatch({
            type: ActionTypes.SET_ORGANIZATION_LIST,
            payload: {
              organizationList: filterdEntities,
            },
          })
      } else {
        await dispatch({
          type: ActionTypes.SET_ORGANIZATION_LIST,
          payload: {
            organizationList: [],
          },
        })
      }

      if (institutionsList) {
        const { entities } = await getEntreprisesByIds(
          institutionsList,
          history,
          true
        )

        if (entities)
          dispatch({
            type: ActionTypes.SET_INSTITUTION_LIST,
            payload: {
              institutionList: entities,
            },
          })
      } else {
        await dispatch({
          type: ActionTypes.SET_INSTITUTION_LIST,
          payload: {
            institutionList: [],
          },
        })
      }
    } catch (e) {
      console.debug('error', e.message)
    }
    // Désactiver l'impersonnation
    dispatch({
      type: ActionTypes.SET_IS_IMPERSONNATION,
      payload: {
        isImpersonnation: false,
      },
    })
    dispatch({
      type: ActionTypes.SET_USERNAME_IMPERSONNATION,
      payload: {
        nameImpersonnated: '',
        accessDemands: false,
        emailImpersonated: '',
      },
    })

    // Redirection vers la page d'accueil
    handleNavigation(userData.roleNames, history)

    dispatch({
      type: ActionTypes.SET_API_CALL_BLOCK,
      payload: {
        APICallBlocked: false,
      },
    })

    setIsLoading(false)
  }

  return (
    <Button className={className} onClick={handleExitImpersonnation}>
      <Loading isLoading={isLoading} inButton>
        {showIcon && (
          <SvgIcon name="stepBefore" className="menu__exitButton-icon" />
        )}
        {textButton}
      </Loading>
    </Button>
  )
}

ButtonExitImpersonnation.defaultProps = {
  textButton: '',
  externalLink: undefined,
  showIcon: false,
}

export default ButtonExitImpersonnation
