import React from 'react'
import { useTranslation } from 'react-i18next'

const CreateDemandExpirationBody: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div>{t('session.modal.demandBody1')}</div>
      <div>{t('session.modal.demandBody2')}</div>
      <div>{t('session.modal.demandBody3')}</div>
    </>
  )
}

export default CreateDemandExpirationBody
