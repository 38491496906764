import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'fr',
    fallbackLng: 'en',
    whitelist: ['en', 'fr'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  })

export default i18n
