/* eslint-disable class-methods-use-this */
import BillEntity from '../utils/BillEntity'
import BillWDH from '../utils/BillWDH'
import {
  to1stDayOfMonth,
  formatDate,
  toLastDayOfMonth,
  parseDate,
  getYear,
  getMonth,
  getTrim,
} from '../utils/date-utils'

class BillNormalizer {
  normalize(billList: BillWDH[]): BillEntity[] {
    return billList.map((bill) => {
      return this.normalizeSingle(bill)
    })
  }

  normalizeSingle(bill: BillWDH): BillEntity {
    const {
      idFacture,
      numFacture,
      dateFacturation,
      montantTotalHT,
      type,
      fketablissemententrepriseclient,
      fkstatutfacture,
    } = bill

    const dateObj = parseDate(dateFacturation)

    const principalAddress = fketablissemententrepriseclient.adresses.find(
      (address) => address.typeAdresse === 'Principale'
    )

    let address = ''
    if (principalAddress) {
      const { rueAdresse, villeAdresse, codePostalAdresse } = principalAddress
      address += `${rueAdresse || ''} ${codePostalAdresse || ''} ${
        villeAdresse || ''
      }`
    }

    const { cycleFacturation } = fketablissemententrepriseclient
    // default "mensuel"
    let interval = ''
    switch (cycleFacturation) {
      case 'TRIM': {
        const trim = getTrim(dateObj)
        interval = `du ${formatDate(trim[0])} au ${formatDate(trim[1])}`
        break
      }
      default:
        interval = `du ${formatDate(to1stDayOfMonth(dateObj))} au ${formatDate(
          toLastDayOfMonth(dateObj)
        )}`
    }

    let status = ''
    if (fkstatutfacture?.statut === 'Partiel') {
      status = 'closed'
    } else if (fkstatutfacture?.statut === 'Complet') {
      status = 'paid'
    } else {
      status = 'notPaid'
    }

    return {
      id: idFacture,
      number: `N°${numFacture}`,
      status,
      price: montantTotalHT,
      date: dateObj,
      formattedDate: formatDate(dateObj),
      formattedDateAsYearMonth: `${getMonth(dateObj)} ${getYear(dateObj)}`,
      type,
      linkDL: '?', // TODO to dynamize
      name: fketablissemententrepriseclient.raisonSociale,
      address,
      // TODO translation
      interval,
    }
  }
}

export default new BillNormalizer()
