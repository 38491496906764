/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelTokenSource } from 'axios'
import ActivitySynthesisNormalizer from '../normalizer/ActivitySynthesisNormalizer'
import { getFirstMonthAndYear } from '../utils/date-utils'
import InstitutionEntity from '../utils/InstitutionEntity'
import OrganizationEntity from '../utils/OrganizationEntity'
import { getMemberData } from './hydra-services'

export enum SYN_ACTIVITY_TYPE {
  TONNAGE_OPERATED = 'kpi-act-synt-ton',
  TONNAGE_TREATED = 'kpi-act-synt-tra',
  PASSAGES = 'kpi-act-synt-pas',
  TRI = 'kpi-act-synt-tri',
}

let cancelToken: CancelTokenSource

function getUrlKPIByType(
  type: string,
  year: number,
  institution: InstitutionEntity[],
  organisation: OrganizationEntity[]
): string {
  const periodeDebut = getFirstMonthAndYear(year.toString())
  const periodeFin = `${year}-12`

  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/indicateurs/${type}?regroupement[]=mois&periodeDebut=${periodeDebut}&periodeFin=${periodeFin}`

  if (type === SYN_ACTIVITY_TYPE.TONNAGE_OPERATED) {
    url = url.concat('&regroupement[]=fkfamilledechet')
  } else if (type === SYN_ACTIVITY_TYPE.TONNAGE_TREATED) {
    url = url.concat('&regroupement[]=fkfamilletraitement')
  }

  institution.forEach((insti) => {
    url = url.concat(`&fkentreprisefacturee[]=${insti.id}`)
  })

  organisation.forEach((orga) => {
    url = url.concat(`&idOrganisation[]=${orga.id}`)
  })

  return url
}

export async function getAllActivitySynthesis(
  year: number,
  institution: InstitutionEntity[],
  organisation: OrganizationEntity[]
): Promise<any> {
  let tonnageOperated
  let tonnageTreated
  let tri
  let passages
  let isCancled = false
  const requestArray: any[] = []
  const urlTonnageOperated = getUrlKPIByType(
    SYN_ACTIVITY_TYPE.TONNAGE_OPERATED,
    year,
    institution,
    organisation
  )
  const urlTonnageTreated = getUrlKPIByType(
    SYN_ACTIVITY_TYPE.TONNAGE_TREATED,
    year,
    institution,
    organisation
  )
  const urlTri = getUrlKPIByType(
    SYN_ACTIVITY_TYPE.TRI,
    year,
    institution,
    organisation
  )
  const urlPassages = getUrlKPIByType(
    SYN_ACTIVITY_TYPE.PASSAGES,
    year,
    institution,
    organisation
  )

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  requestArray.push(
    axios.get(urlTonnageOperated, { cancelToken: cancelToken.token })
  )
  requestArray.push(
    axios.get(urlTonnageTreated, { cancelToken: cancelToken.token })
  )
  requestArray.push(axios.get(urlTri, { cancelToken: cancelToken.token }))
  requestArray.push(axios.get(urlPassages, { cancelToken: cancelToken.token }))

  const promisesResolved = requestArray.map((request) =>
    request.catch((err: any) => {
      if (axios.isCancel(err)) isCancled = true // Pour traiter le loader
      return { err }
    })
  )
  await axios
    .all(promisesResolved)
    .then(
      axios.spread((...responses: any[]) => {
        tonnageOperated = ActivitySynthesisNormalizer.normalize(
          responses[0].err ? [] : getMemberData(responses[0].data)
        )
        tonnageTreated = ActivitySynthesisNormalizer.normalize(
          responses[1].err ? [] : getMemberData(responses[1].data)
        )
        tri = ActivitySynthesisNormalizer.normalize(
          responses[2].err ? [] : getMemberData(responses[2].data)
        )
        passages = ActivitySynthesisNormalizer.normalize(
          responses[3].err ? [] : getMemberData(responses[3].data)
        )
      })
    )
    .catch((err: any) => {
      console.error(err)
    })

  return {
    tonnageOperated,
    tonnageTreated,
    tri,
    passages,
    isCancled,
  }
}
