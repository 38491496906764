import React from 'react'

interface Props {
  children: React.ReactNode
}
const AuthenticatedScreenInnerContainer = ({ children }: Props) => {
  return (
    <div className="authenticated-screen-main-content__inner">{children}</div>
  )
}

export default AuthenticatedScreenInnerContainer
