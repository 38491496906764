/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-expressions */

import JSZip from 'jszip'
import axios, { CancelTokenSource, Cancel } from 'axios'
import moment from 'moment'
import { saveAs } from 'file-saver'

import { BillDetailEntity } from '../utils/BillDetailWDH'
import BillingDetailNormalizer from '../normalizer/BillingDetailNormalizer'
import BillNormalizer from '../normalizer/BillNormalizer'
import BillingDownloadErrorNormalizer from '../normalizer/BillingDownloadErrorNormalizer'
import BillingAnnexeDownloadErrorNormalizer, {
  DossierAnnexe,
} from '../normalizer/BillingAnnexeDownloadErrorNormalizer'
import BillEntity, {
  annexContentForZip,
  AnnexDto,
  contentForZip,
  FolderDto,
  FolderStateType,
  getFilterForId,
} from '../utils/BillEntity'
import BILL_TYPE from '../utils/enums/BILL_TYPE'
import { getMemberData } from './hydra-services'
import InstitutionEntity from '../utils/InstitutionEntity'
import OrganizationEntity from '../utils/OrganizationEntity'
import {
  annexZipInfo,
  downloadMultipleBlobFile,
  zipInfo,
} from './documents-services'
import ROUTES from '../utils/ROUTES'
import { ANNEXE_TYPE } from '../normalizer/BillingAnnexeDetailNormalizer'

let cancelToken: CancelTokenSource

export function isCancel(value: any | Cancel) {
  return !!(value && value.__CANCEL__)
}

export interface BillDownloadStatus {
  [key: string]: string | number
  id: string
  fileName: string
  message: string
  status: number
}

export async function getBillsAndCredits({
  searchText,
  page,
  type,
  perPage,
  sortTable,
  filters = [],
  organization = [],
  institution = [],
  history,
}: any): Promise<{ pageCount: number; results: BillEntity[] }> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/factures`
  const [startDate, endDate] = getFilterForId(filters, 'periode')

  const params = new URLSearchParams({
    'dateFacturation[after]': moment(startDate).format('YYYY-MM-DD'),
    'dateFacturation[before]': moment(endDate).format('YYYY-MM-DD'),
    page,
    perPage,
    type,
  })

  if (organization.length) {
    organization.forEach((org: OrganizationEntity) => {
      params.append('idOrganisation[]', org.id)
    })
  }

  if (institution.length) {
    institution.forEach((org: InstitutionEntity) => {
      params.append('fketablissemententrepriseclient[]', org.id)
    })
  }

  const statusFilter = getFilterForId(filters, 'statut')
  if (statusFilter && statusFilter.value !== 'all') {
    params.append('fkstatutfacture.statut', statusFilter.apiValue)
  }

  const numFactureFilter = getFilterForId(filters, 'numFacture')
  if (numFactureFilter) {
    params.append('numFacture', numFactureFilter)
  }

  const adresseFilter = getFilterForId(filters, 'adresse')
  if (adresseFilter) {
    params.append('searchAdresseFacturation', adresseFilter)
  }

  const etablissementFilter = getFilterForId(filters, 'etablissement')
  if (etablissementFilter) {
    params.append(
      'fketablissemententrepriseclient.raisonSociale',
      etablissementFilter
    )
  }

  const montantFilter = getFilterForId(filters, 'montantTotalHT')
  if (montantFilter) {
    params.append('montantTotalHT[gte]', montantFilter)
  }

  if (searchText) {
    params.append('searchText', searchText)
  }

  // Table de correspondance champs du tableau - champs api
  const labelsToApiFields: { [key: string]: string } = {
    'N° de facture': 'numFacture',
    "N° d'avoir": 'numFacture',
    'N° BRM': 'numFacture',
    Période: 'dateFacturation',
    Date: 'dateFacturation',
    "Date d'émission": 'dateFacturation',
    Établissements: 'fketablissemententrepriseclient.raisonSociale',
    'Étbs remboursés': 'fketablissemententrepriseclient.raisonSociale',
    'Étbs facturés': 'fketablissemententrepriseclient.raisonSociale',
    'Adresse de facturation': 'rueAdresse',
    'Montant (HT)': 'montantTotalHT',
    'Statut de réception': 'fkstatutfacture.statut',
  }

  if (sortTable) {
    Object.keys(sortTable).forEach((key: string) => {
      params.append(`sort[${labelsToApiFields[key]}]`, sortTable[key])
    })
  }

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })

    const data = getMemberData(resp.data)

    if (data) {
      const result = BillNormalizer.normalize(data)

      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      return {
        pageCount: Math.ceil(totalItems / perPage),
        results: [...result],
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }

    console.error(err)
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur lors de l'obtention des factures`)
  }

  return Promise.resolve({ pageCount: 0, results: [] })
}

export function getCollapsedClassname(collapsed: boolean): string {
  return collapsed
    ? 'billing-annex__collapsed billing-annex__collapsed--active'
    : 'billing-annex__collapsed'
}

export async function getBillDetail(
  idFacture: number,
  history: any
): Promise<BillDetailEntity> {
  // service result initialization
  const result: BillDetailEntity = {
    id: '',
    date: '',
    period: {
      begin: '',
      end: '',
    },
    amount: 0,
    institution: {
      name: '',
      address: '',
    },
    emissionDate: '',
    debtorNb: '',
    billingSuezEntity: {
      name: '',
      address: '',
    },
    annexes: [],
    statut: '',
  }
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/factures/${idFacture}?format=Interventions`
  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    if (resp?.data) {
      // normalizing the api call result
      return BillingDetailNormalizer.normalize(resp.data)
    }
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur lors de l'obtention du détail des factures :${err}`)
  }
  return result
}

async function getLastBillOrCredit(
  type: string,
  history: any
): Promise<BillEntity> {
  try {
    const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/factures?order[dateFacturation]=DESC&type=${type}`
    const resp = await axios.get(url)
    const data = getMemberData(resp.data)

    if (data?.length > 0) {
      return BillNormalizer.normalizeSingle(data[0])
    }
  } catch (e) {
    history.push(ROUTES.TechnicalError)
    console.log(e)
  }

  return Promise.reject()
}

export async function getLastBill(history: any): Promise<BillEntity> {
  return getLastBillOrCredit(BILL_TYPE.BILL, history)
}

export async function getLastCredit(history: any): Promise<BillEntity> {
  return getLastBillOrCredit(BILL_TYPE.CREDIT, history)
}

export function fillContentForZipAnnexes(
  bon: AnnexDto,
  iDdirName: string,
  id: string
): annexContentForZip {
  const docName = `${bon.title}.pdf`
  const dirName = `Dossier_N°${iDdirName}`
  let docUrl
  let docType
  if (bon.type === ANNEXE_TYPE.INTERVENTION) {
    docUrl = `/factures/${id}/interventions/${bon.id}/document-data`
    docType = 'BI'
  } else {
    docUrl = `/factures/${id}/pesees/${bon.id}/document-data`
    docType = 'BP'
  }
  return {
    id: bon.id,
    docUrl,
    docName,
    dirName,
    docType,
  }
}

export async function downloadZipAnnexesAsync(
  folderChecked: FolderStateType,
  folders: FolderDto[],
  history: any,
  billId: string
): Promise<any> {
  let zipContainer: annexContentForZip[] = []
  const zip = new JSZip()
  let errormessage = ''
  let totalFiles = 0
  const listFileWithError: any = []
  const listFileWithoutError: any = []

  for (const [key, value] of Object.entries(folderChecked)) {
    if (value) {
      const presta = folders.filter((fold: FolderDto) => fold.id === key)
      if (presta.length === 1) {
        presta[0].annexes.map((bon: AnnexDto) => {
          return zipContainer.push(
            fillContentForZipAnnexes(bon, presta[0].number, billId)
          )
        })
      }
    }
  }

  try {
    let newFolder
    // Warning message --> Only 20 BI or BP for one zip
    if (zipContainer.length > 40) {
      zipContainer = zipContainer.slice(0, 40)
      errormessage = 'Seules vos 40 premières sélections ont été téléchargées.'
    }
    const finalZip = await downloadMultipleBlobFile(zipContainer)
    totalFiles = finalZip.length
    finalZip?.forEach((item) => {
      const { message, docName } = item
      const idFacture = docName.split('.')[0]
      if (item.status.toString() !== '200') {
        listFileWithError.push({
          id: (item as annexZipInfo).id,
          fileName: idFacture.replaceAll('_', ' '),
          message,
          status: item.status,
        })
      } else {
        listFileWithoutError.push({
          data: item.data,
          docName: item.docName,
          dirName: item.dirName,
          status: item.status,
          message: '',
        })
      }
    })
    // Fill the zip
    if (listFileWithoutError.length > 0) {
      listFileWithoutError.map((zipinfo: zipInfo) => {
        newFolder = zip.folder(zipinfo.dirName)
        return newFolder?.file(zipinfo.docName, zipinfo.data)
      })
      // Download Zip
      zip.generateAsync({ type: 'blob' }).then(function (content: any) {
        saveAs(content, `Annexes_Facture_${billId}.zip`)
      })
    }
  } catch (err) {
    history.push(`${ROUTES.TechnicalError}`)
  }

  const selectedDossierAnnexes: DossierAnnexe = folders.reduce(
    (selection, dossier) => {
      return folderChecked[dossier.id]
        ? {
            ...selection,
            [dossier.number]: dossier.annexes.map((annexe) => annexe.id),
          }
        : { ...selection }
    },
    {}
  )

  const categorizedErrors = BillingAnnexeDownloadErrorNormalizer.normalize(
    listFileWithError,
    selectedDossierAnnexes
  )

  const listFileSuccess = listFileWithoutError.map((f: any) => {
    return {
      id: f.docName.slice(0, -4).replaceAll('_', ' ') as string,
      fileName: f.docName,
      message: 'download OK',
      status: 200,
    }
  })

  return {
    totalFiles,
    listFileWithError,
    categorizedErrors,
    listFileSuccess,
    errormessage,
  }
}

export function fillContentForZipBills(
  billId: string,
  billNumber: string,
  type: string
): contentForZip {
  let docType = 'FACTURE'
  let docName = `Facture_${billNumber}.pdf`
  if (type === 'credit') {
    docName = `Avoir_${billNumber}.pdf`
    docType = 'AVOIR'
  } else if (type === 'brm') {
    docName = `BRM_${billNumber}.pdf`
    docType = 'BRM'
  }

  const dirName = ``
  const docUrl = `/factures/${billId}/document-data`

  return {
    docUrl,
    docName,
    dirName,
    docType,
  }
}

export async function downloadZipBillsAsync(
  selectedBills: any,
  bills: BillEntity[],
  type: string
): Promise<any> {
  let zipContainer: contentForZip[] = []
  const zip = new JSZip()
  let errormessage = ''

  for (const [key, value] of Object.entries(selectedBills)) {
    if (value) {
      const bill = bills.filter((b) => b.id.toString() === key)
      zipContainer.push(fillContentForZipBills(key, bill[0].number, type))
    }
  }

  // Warning message --> Only 40 for one zip
  if (zipContainer.length > 40) {
    zipContainer = zipContainer.slice(0, 40)
    errormessage = 'Seules vos 40 premières sélections ont été téléchargées.'
  }
  const finalZip = await downloadMultipleBlobFile(zipContainer)
  const totalFiles = finalZip.length
  const listFileWithError: any = []
  const listFileWithoutError: any = []
  finalZip?.forEach((item) => {
    const { message, docName } = item
    const idFacture = docName.split('.')[0]
    if (item.status.toString() !== '200') {
      listFileWithError.push({
        id: idFacture.replaceAll('_', ' '),
        fileName: idFacture.replaceAll('_', ' '),
        message,
        status: item.status,
      })
    } else {
      listFileWithoutError.push({
        data: item.data,
        docName: item.docName,
        dirName: item.dirName,
        status: item.status,
        message: '',
      })
    }
  })
  // Fill the zip
  if (listFileWithoutError.length > 0) {
    listFileWithoutError.map((zipinfo: zipInfo) => {
      return zip.file(zipinfo.docName, zipinfo.data)
    })
    // Download Zip
    let zipName = `Factures_Suez.zip`
    if (type === 'credit') {
      zipName = `Avoirs_Suez.zip`
    } else if (type === 'brm') {
      zipName = `BRM_Suez.zip`
    }

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(content, zipName)
    })
  }

  const categorizedErrors = BillingDownloadErrorNormalizer.normalize(
    listFileWithError
  )

  const listFileSuccess = listFileWithoutError.map((f: any) => {
    return {
      id: f.docName.slice(0, -4).replaceAll('_', ' ') as string,
      fileName: f.docName,
      message: 'download OK',
      status: 200,
    }
  })

  return {
    totalFiles,
    listFileWithError,
    categorizedErrors,
    listFileSuccess,
    errormessage,
  }
}
