import styled from 'styled-components'

interface Props {
  withOverflow?: boolean
}
const StyledTagsWrapper = styled.div<Props>`
  display: flex;
  column-gap: 5px;
  flex-wrap: wrap;
  ${({ withOverflow }) =>
    withOverflow
      ? { 'overflow-y': 'scroll', 'max-height': '150px' }
      : { overflow: 'none' }}
`

export default StyledTagsWrapper
