import React, { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
  fullWidth?: boolean
  className?: string
  childClassName?: string
}
const SelectedContainer: FC<Props> = ({
  children,
  fullWidth,
  className,
  childClassName,
}: Props) => {
  // Gestion du style pour les bouttons - + plier et déplier
  const containerStyle = className || 'selected-container'
  return (
    <div className={`${containerStyle} ${fullWidth ? 'full' : ''}`}>
      <div className={childClassName}>{children}</div>
    </div>
  )
}
SelectedContainer.defaultProps = {
  fullWidth: false,
  className: '',
  childClassName: '',
}
export default SelectedContainer
