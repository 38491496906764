/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import ServiceEntity, { SubscribeEntity } from '../utils/ServiceEntity'
import ROUTES from '../utils/ROUTES'
import { InstitutionPerimeterType } from '../store/state'

export async function getUserServicesSubscribed(): Promise<ServiceEntity[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/user-services`
  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      url,
    })

    const services = resp?.data.subscribed_services

    return Promise.resolve(services)
  } catch (err) {
    throw new Error(err)
  }
}

export async function getAllServicesBySubscription(
  userId: string
): Promise<ServiceEntity[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/all-services-by-subscription`

  const params = new URLSearchParams()
  params.append(`userId`, userId)

  try {
    const resp = await axios({
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
      },
      params,
    })

    const services = resp?.data.services_by_subscription
    return services
  } catch (err) {
    throw new Error(err)
  }
}

export async function subscribe(
  payload: SubscribeEntity,
  history: any
): Promise<boolean> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/subscribe`

  try {
    let response = false
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        ...payload,
      },
    })
    response = resp?.data
    return response
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    return false
  }
}

export function hasAccessToManageServices(
  isInternal: boolean,
  institutionPerimeter: InstitutionPerimeterType
): boolean {
  const ACCESS_SERVICES = Boolean(
    parseInt(process?.env?.REACT_APP_ACCESS_SERVICES || '1', 10)
  )

  return !!(
    !isInternal &&
    ACCESS_SERVICES &&
    institutionPerimeter.allItems &&
    institutionPerimeter.allItems.length > 1
  )
}
