/* eslint-disable class-methods-use-this */
import { WDH_GROUPING_TYPE } from '../utils/CreateOrganization-utils'
import OrganizationEntity, {
  OrganizationTableEntity,
  OrganizationWDHFull,
  OrganizationDocumentEntity,
} from '../utils/OrganizationEntity'
import {
  extractOrganizationEnfantId,
  extractOrganizationEnfantWithIdAndState,
} from '../utils/OrganizationTable-utils'
import OrganizationWDH from '../utils/OrganizationWDH'

class OrganizationNormalizer {
  normalize(organizationList: OrganizationWDH[]): OrganizationEntity[] {
    return organizationList.map(
      ({
        idOrganisation,
        nomOrganisation,
        nbEtablissements,
        organisationsEnfant,
      }) => ({
        id: String(idOrganisation),
        name: nomOrganisation,
        nbInstitutions: nbEtablissements,
        nbOrganizationEnfant: organisationsEnfant.length,
        application: `${process.env.REACT_APP_ORGANIZATION_APPLICATION}`,
        type: `${process.env.REACT_APP_ORGANIZATION_TYPE}`,
      })
    )
  }

  normalizeForTable(
    organizationList: OrganizationWDHFull[]
  ): OrganizationTableEntity[] {
    return organizationList.map((item) => {
      return {
        id: String(item.idOrganisation),
        idFonctionnelOrganisation: item.idFonctionnelOrganisation,
        typeOrganisation: item.typeOrganisation,
        nbEtablissements: String(item.nbEtablissements),
        nbOrganisations: String(item.organisationsEnfant.length),
        name: item.nomOrganisation,
        familleOrganisation: item.familleOrganisation,
        dateModification: item.dateModification,
        dateCreation: item.dateCreation,
        contenu:
          item.typeOrganisation === WDH_GROUPING_TYPE.WHD_BY_ORGANIZATION
            ? String(item.organisationsEnfant.length)
            : String(item.nbEtablissements),
        statut: item.statut,
        organisationsEnfant:
          item.organisationsEnfant.length > 0
            ? extractOrganizationEnfantWithIdAndState(item.organisationsEnfant)
            : [],
      }
    })
  }

  normalizeForDocument(
    organizationList: OrganizationWDHFull[]
  ): OrganizationDocumentEntity[] {
    return organizationList.map((item) => {
      return {
        id: String(item.idOrganisation),
        idFonctionnelOrganisation: item.idFonctionnelOrganisation,
        nbOrganisations: item.organisationsEnfant.length,
        name: item.nomOrganisation,
        organisationsEnfant:
          item.organisationsEnfant.length > 0
            ? extractOrganizationEnfantId(item.organisationsEnfant)
            : [],
      }
    })
  }
}

export default new OrganizationNormalizer()
