/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import debounce from 'lodash.debounce'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import SearchOrganizationAdapter from '../../adapter/SearchOrganizationAdapter'
import {
  searchOrganizationByNameAndIds,
  organizationHandlerSearchValidated,
  SearchResult,
  searchOrganizationByIds,
} from '../../services/organization-services'
import { isSuperAdmin } from '../../services/roles-services'
import { ActionTypes } from '../../store/actions'
import { AppContext } from '../../store/context'
import OrganizationEntity from '../../utils/OrganizationEntity'
import InstitutionEntity from '../../utils/InstitutionEntity'
import SmartSelect from './SmartSelect'
import SvgIcon from '../Icons/SvgIcon'
import useElementSizeListener from '../../utils/useElementSizeListener'
import { isCancel } from '../../services/billing-services'
import { searchInstitutionByOrganizationIds } from '../../services/institution-services'
import { clearSameIds, equalsArray } from '../../utils/array-utils'
import SearchResultItem from '../../utils/SearchResultItem'
import SelectedItems from '../molecules/SelectedItems'

interface Props {
  disableGlobalState?: boolean
  searchWithSetInstitutions?: boolean
  autoSearchInstitution?: boolean
  forceReset?: boolean
  setForceReset?: React.Dispatch<React.SetStateAction<boolean>> | undefined
  onInstitutionsLoadChanged?: (isLoading: boolean) => void
  onOrganizationsSetted?: () => void
  onSearchOrganizationsValidate?: (organizations: OrganizationEntity[]) => void
  onSearchInstitutionsValidate?: (institutions: InstitutionEntity[]) => void
  onDisabledSearchInstitution?: (value: boolean) => void
  onlyClientOrganization?: boolean
  selectedOrganizations?: OrganizationEntity[]
  expandedMode?: boolean
  isBandeau?: boolean
}

const SmartSelectOrganization: FC<Props> = ({
  disableGlobalState,
  searchWithSetInstitutions,
  autoSearchInstitution,
  forceReset,
  setForceReset,
  onInstitutionsLoadChanged,
  onOrganizationsSetted,
  onSearchOrganizationsValidate,
  onSearchInstitutionsValidate,
  onDisabledSearchInstitution,
  onlyClientOrganization,
  selectedOrganizations,
  expandedMode,
  isBandeau,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const cookies = new Cookies()

  const {
    state: { user, organization, institution, impersonnation },
    dispatch,
  } = useContext(AppContext)

  const selectRef = useRef(null)
  const { width: elementSize } = useElementSizeListener(selectRef)

  const [entities, setEntities] = React.useState<OrganizationEntity[]>([])
  const [totalItems, setTotalItems] = React.useState(0)
  const [
    resetSearchValueAfterValidate,
    setResetSearchValueAfterValidate,
  ] = React.useState(false)
  const [entitiesSearchedResult, setEntitiesSearchedResult] = React.useState<
    OrganizationEntity[]
  >([])
  const [selectedItems, setSelectedItems] = React.useState<SearchResultItem[]>(
    []
  )
  const [searchValue, setSearchValue] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const searchOrganizationItems = SearchOrganizationAdapter(
    entitiesSearchedResult,
    t('perimeterfilter.label.institutionInResult'),
    t('perimeterfilter.label.organizationSearchCount')
  )

  const selectedOrganizationsItem = selectedOrganizations
    ? SearchOrganizationAdapter(
        selectedOrganizations,
        t('perimeterfilter.label.institutionInResult'),
        t('perimeterfilter.label.organizationSearchCount')
      )
    : []

  const callApiAsync = async (organizationIds: string[]) => {
    const maxTotalLimit = parseInt(
      `${process.env.REACT_APP_FILTER_MAX_ORGANIZATION}`,
      10
    )

    setIsLoading(true)
    if (onDisabledSearchInstitution) onDisabledSearchInstitution(true)

    const {
      entities: resultEntities,
      totalItems: resultTotalItems,
    } = await searchOrganizationByNameAndIds(
      searchValue,
      organizationIds,
      history,
      maxTotalLimit,
      disableGlobalState,
      onlyClientOrganization,
      expandedMode
    )

    setEntitiesSearchedResult(resultEntities)
    setTotalItems(resultTotalItems)
    setIsLoading(false)
    const lastentities = entities.concat(resultEntities)
    setEntities([...lastentities])
    if (onDisabledSearchInstitution) onDisabledSearchInstitution(false)
  }

  const [idsOrgaTable, setIdsOrgaTable] = useState<string[]>([])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const HandleUpdateEntities = (toDeleteId: string) => {
    setIdsOrgaTable([...idsOrgaTable, toDeleteId])
  }

  useEffect(() => {
    async function callEtsAPI() {
      if (!disableGlobalState) {
        const check = organization.items.filter((e: OrganizationEntity) =>
          idsOrgaTable.includes(e.id)
        )
        if (check.length > 0) {
          const newOrga = organization.items.filter(
            (e: OrganizationEntity) => !idsOrgaTable.includes(e.id)
          )
          const orgaIds: string[] = []
          newOrga.forEach((e: OrganizationEntity) => {
            orgaIds.push(e.id)
          })

          const organizationsList = JSON.stringify(
            newOrga.map((itm: OrganizationEntity) => itm.id)
          )

          if (organizationsList === '[]') {
            dispatch({
              type: ActionTypes.SET_INSTITUTION_LIST,
              payload: {
                institutionList: [],
              },
            })
            if (!impersonnation.isImpersonnation) {
              cookies.remove('chosenInstitution', { path: '/' })
            }
          }

          // put orgas in cookies
          if (!impersonnation.isImpersonnation) {
            if (organizationsList !== '[]') {
              cookies.set('chosenOrganization', organizationsList, {
                path: '/',
                secure: true,
              })
            } else {
              cookies.remove('chosenOrganization', { path: '/' })
            }
          }

          dispatch({
            type: ActionTypes.SET_ORGANIZATION_LIST,
            payload: {
              organizationList: newOrga,
            },
          })

          const newResult: OrganizationEntity[] = []
          entitiesSearchedResult.map((orga: OrganizationEntity) => {
            if (orgaIds.includes(orga.id)) {
              newResult.push(orga)
            }
          })
          setEntitiesSearchedResult(newResult)
          setTotalItems(newResult.length)

          if (searchWithSetInstitutions) {
            if (onSearchInstitutionsValidate) {
              try {
                if (orgaIds.length > 0) {
                  const result = await searchInstitutionByOrganizationIds(
                    orgaIds,
                    history
                  )

                  if (institution.items.length > 0) {
                    const tmp = JSON.stringify(result.entities)
                    const newInstitutionState: InstitutionEntity[] = []
                    institution.items.map((ins: InstitutionEntity) => {
                      if (tmp.includes(ins.id)) {
                        newInstitutionState.push(ins)
                      }
                    })

                    dispatch({
                      type: ActionTypes.SET_INSTITUTION_LIST,
                      payload: {
                        institutionList: newInstitutionState,
                      },
                    })

                    const institutionsList = JSON.stringify(
                      newInstitutionState.map(
                        (itm: InstitutionEntity) => itm.id
                      )
                    )
                    // put institution in cookies
                    if (!impersonnation.isImpersonnation) {
                      if (institutionsList !== '[]') {
                        cookies.set('chosenInstitution', institutionsList, {
                          path: '/',
                          secure: true,
                        })
                      } else {
                        cookies.remove('chosenInstitution', { path: '/' })
                      }
                    }
                  }

                  if (result.entities) {
                    onSearchInstitutionsValidate(result.entities)
                  }
                } else {
                  onSearchInstitutionsValidate([])
                }
              } catch (err) {
                console.error('error getting institution')
              }
            }
          }
        }
      }
      setIdsOrgaTable([])
    }

    if (idsOrgaTable.length > 0 && organization.items.length > 0) {
      setIsLoading(true)
      if (onDisabledSearchInstitution) onDisabledSearchInstitution(true)
      callEtsAPI()
      setIsLoading(false)
      if (onDisabledSearchInstitution) onDisabledSearchInstitution(false)
    }
  }, [idsOrgaTable])

  const handleRemoveOrgaDebounced = debounce(HandleUpdateEntities, 400)

  const handleOrganizationChanged = (value: string) => {
    setSearchValue(value)
  }

  const handleOrganizationChangedDebounced = debounce(
    handleOrganizationChanged,
    1000
  )

  const handleOrganizationSearchValidated = async (
    selectedItemIds: string[]
  ) => {
    if (!disableGlobalState) {
      const tmpOrgIds: string[] = []
      organization.items.forEach((orga: OrganizationEntity) => {
        tmpOrgIds.push(orga.id)
      })

      if (equalsArray(selectedItemIds, tmpOrgIds)) {
        if (searchValue.length) {
          if (setForceReset) {
            setForceReset(!forceReset)
          }
          setResetSearchValueAfterValidate(true)
        }
        return
      }
    }

    setResetSearchValueAfterValidate(true)
    if (onDisabledSearchInstitution) onDisabledSearchInstitution(true)
    if (selectedItemIds.length === 0) {
      if (disableGlobalState) {
        if (onSearchOrganizationsValidate) onSearchOrganizationsValidate([])
      } else {
        dispatch({
          type: ActionTypes.SET_ORGANIZATION_LIST,
          payload: {
            organizationList: [],
          },
        })

        if (!impersonnation.isImpersonnation) {
          cookies.remove('chosenOrganization', { path: '/' })
        }
      }
      return
    }

    dispatch({
      type: ActionTypes.SET_ORGANIZATION_VALIDATED,
      payload: {
        organizationValidated: true,
      },
    })

    if (onOrganizationsSetted) {
      onOrganizationsSetted()
    }

    if (onInstitutionsLoadChanged) {
      onInstitutionsLoadChanged(true)
    }

    if (!autoSearchInstitution) {
      // hack to not break exising code
      dispatch({
        type: ActionTypes.SET_ORGANIZATION_LIST,
        payload: {
          organizationList: selectedItemIds.map((id) => ({
            id,
            name: 'empty',
            nbInstitutions: 0,
            nbOrganizationEnfant: 0,
            application: '',
            type: '',
          })),
        },
      })

      return
    }

    if (selectedItemIds.length > 0) {
      // synchronize selected items
      const synIds = selectedItemIds
      if (!impersonnation.isImpersonnation && !disableGlobalState) {
        const orgaList = cookies.get('chosenOrganization')
        orgaList?.forEach((orga: any) => {
          if (!synIds.includes(orga)) synIds.push(orga)
        })
      }
      // recover not existing organisations
      const orgaIdsToAdd: string[] = []
      synIds.forEach((selected) => {
        if (
          !entities.find((orga: OrganizationEntity) => orga.id === selected)
        ) {
          orgaIdsToAdd.push(selected)
        }
      })

      const orgaToAdd: SearchResult =
        orgaIdsToAdd.length > 0
          ? await searchOrganizationByIds(orgaIdsToAdd, history, expandedMode)
          : { entities: [], totalItems: 0 }

      const listOrga: OrganizationEntity[] = entities.concat(orgaToAdd.entities)

      const filteredOrganizations = listOrga.filter((item) =>
        synIds.find((id) => id === item.id)
      )
      const clearFilteredOrga = clearSameIds(filteredOrganizations)
      setEntitiesSearchedResult(clearFilteredOrga)
      setTotalItems(clearFilteredOrga.length)

      if (!disableGlobalState) {
        dispatch({
          type: ActionTypes.SET_ORGANIZATION_LIST,
          payload: {
            organizationList: clearFilteredOrga,
          },
        })

        // put organization in cookies
        const organizationsList = JSON.stringify(
          clearFilteredOrga.map((itm: OrganizationEntity) => itm.id)
        )
        if (!impersonnation.isImpersonnation) {
          if (organizationsList !== '[]') {
            cookies.set('chosenOrganization', organizationsList, {
              path: '/',
              secure: true,
            })
          } else {
            cookies.remove('chosenOrganization', { path: '/' })
          }
        }
      }

      const { linkedInstitutions } = await organizationHandlerSearchValidated(
        synIds,
        history,
        expandedMode
      )
      if (isCancel(linkedInstitutions)) {
        return
      }

      if (onInstitutionsLoadChanged) {
        onInstitutionsLoadChanged(false)
      }

      if (onSearchOrganizationsValidate)
        onSearchOrganizationsValidate(clearFilteredOrga)
      if (disableGlobalState) {
        if (searchWithSetInstitutions) {
          if (onSearchInstitutionsValidate)
            onSearchInstitutionsValidate(linkedInstitutions)
        }
      } else {
        if (searchWithSetInstitutions) {
          if (onSearchInstitutionsValidate)
            onSearchInstitutionsValidate(linkedInstitutions)
        }

        if (institution.items.length > 0) {
          const tmp = JSON.stringify(linkedInstitutions)
          const newInstitutionState: InstitutionEntity[] = []
          institution.items.map((ins: InstitutionEntity) => {
            if (tmp.includes(ins.id)) {
              newInstitutionState.push(ins)
            }
          })

          dispatch({
            type: ActionTypes.SET_INSTITUTION_LIST,
            payload: {
              institutionList: newInstitutionState,
            },
          })

          const institutionsList = JSON.stringify(
            newInstitutionState.map((itm: InstitutionEntity) => itm.id)
          )
          // put institution in cookies
          if (!impersonnation.isImpersonnation) {
            if (institutionsList !== '[]') {
              cookies.set('chosenInstitution', institutionsList, {
                path: '/',
                secure: true,
              })
            } else {
              cookies.remove('chosenInstitution', { path: '/' })
            }
          }
        }
      }
    } else {
      if (onSearchOrganizationsValidate) onSearchOrganizationsValidate([])
      if (searchWithSetInstitutions) {
        if (onSearchInstitutionsValidate) onSearchInstitutionsValidate([])
      }
    }
    if (onDisabledSearchInstitution) onDisabledSearchInstitution(false)
  }
  const handleItemsSelected = (items: any) => {
    // 👇️ take parameter passed from Child component
    setSelectedItems([...selectedItems, items])
  }
  const handleItemsUnselected = (item: any) => {
    setSelectedItems(selectedItems.filter((e) => e.id !== item))
  }
  const onItemUnselected = (toDeleteId: string) => {
    const newSelectedItems = selectedItems?.filter(
      (item) => toDeleteId !== item.id
    )
    const organizationsList = JSON.stringify(
      newSelectedItems.map((itm: SearchResultItem) => itm.id)
    )
    cookies.set('chosenOrganization', organizationsList, {
      path: '/',
      secure: true,
    })
    handleRemoveOrgaDebounced(toDeleteId)
    setSelectedItems([...newSelectedItems])
  }
  const selectedPlaceholder =
    selectedOrganizationsItem.length > 1
      ? `${selectedItems.length} ${t(
          'perimeterfilter.select.organizations.multi'
        )}`
      : `${selectedItems.length} ${t(
          'perimeterfilter.select.organizations.single'
        )}`
  useEffect(() => {
    setResetSearchValueAfterValidate(false)
    if (
      user.roleNames.length &&
      ((isSuperAdmin(user.roleNames) && searchValue.length) ||
        !isSuperAdmin(user.roleNames))
    ) {
      callApiAsync([])
    }
  }, [organization, searchValue, user])

  useEffect(() => {
    setSearchValue('')
    setEntities([])
    setTotalItems(0)
    setEntitiesSearchedResult([])
    if (onSearchInstitutionsValidate) onSearchInstitutionsValidate([])
  }, [forceReset])

  useEffect(() => {
    if (selectedOrganizations && selectedOrganizations.length > 0) {
      setEntitiesSearchedResult(selectedOrganizations)
      setTotalItems(selectedOrganizations.length)
    }
  }, [selectedOrganizations])

  useEffect(() => {
    setSelectedItems(selectedOrganizationsItem)
  }, [organization])

  return (
    <div className="perimeter-filter__select" ref={selectRef}>
      <SmartSelect
        isOrga
        parentWidth={elementSize}
        placeholder={
          <>
            <SvgIcon
              name="hierarchy"
              className="perimeter-filter__select-icon"
            />
            <span>{t('perimeterfilter.placeholder.select')}</span>
          </>
        }
        selectedPlaceholderSingle={t(
          'perimeterfilter.select.organizations.single'
        )}
        selectedPlaceholderMulti={t(
          'perimeterfilter.select.organizations.multi'
        )}
        countLabel={
          entitiesSearchedResult.length <= 1
            ? t('perimeterfilter.search.result.single')
            : t('perimeterfilter.search.result.multi')
        }
        searchResults={searchOrganizationItems}
        count={totalItems}
        isLoading={isLoading}
        resetSearchValue={resetSearchValueAfterValidate}
        forceReset={forceReset}
        onSearchChanged={handleOrganizationChangedDebounced}
        onSearchValidated={handleOrganizationSearchValidated}
        onHandleUnSelectedItems={handleRemoveOrgaDebounced}
        selectedItemsProp={selectedOrganizationsItem}
        isBandeau={isBandeau}
        dataTest="smartOrganisation"
        handleItemsSelected={handleItemsSelected}
        handleItemsUnselected={handleItemsUnselected}
        itemsSelected={selectedItems}
      />
      {isBandeau &&
        selectedOrganizations &&
        selectedOrganizations.length > 0 && (
          <SelectedItems
            placeholder={selectedPlaceholder}
            items={selectedItems}
            entityAbbreviation={t(
              'perimeterfilter.search.institutionAbbreviation'
            )}
            onUnselect={onItemUnselected}
          />
        )}
    </div>
  )
}
SmartSelectOrganization.defaultProps = {
  disableGlobalState: false,
  searchWithSetInstitutions: true,
  autoSearchInstitution: true,
  forceReset: false,
  setForceReset: undefined,
  onlyClientOrganization: false,
  expandedMode: false,
  onSearchOrganizationsValidate: () => undefined,
  onSearchInstitutionsValidate: () => undefined,
  onInstitutionsLoadChanged: () => undefined,
  onOrganizationsSetted: () => undefined,
  onDisabledSearchInstitution: () => undefined,
  selectedOrganizations: undefined,
  isBandeau: false,
}

export default SmartSelectOrganization
