/* eslint-disable radix */
import { useEffect, useState, RefObject, useCallback } from 'react'

export default function useElementSizeListener(
  elementRef: RefObject<HTMLDivElement>,
  forceReload?: boolean
): {
  height: number
  width: number
} {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  })

  const calculateSize = useCallback(() => {
    const element = elementRef?.current

    if (element) {
      setDimensions({
        height: element.offsetHeight || 0,
        width: element.offsetWidth || 0,
      })
    }
  }, [elementRef, forceReload])

  useEffect(() => {
    calculateSize()

    window.addEventListener('resize', calculateSize)

    return () => {
      window.removeEventListener('resize', calculateSize)
    }
  }, [])

  return dimensions
}
