/* eslint-disable class-methods-use-this */
import formatAdresse from '../utils/AddressUtils'
import { FoldersCodeERP, Interventions } from '../utils/BillDetailWDH'
import { FolderDto, AnnexDto } from '../utils/BillEntity'
import { formatDate } from '../utils/date-utils'

export enum ANNEXE_TYPE {
  INTERVENTION = 'intervention',
  PESEES = 'pesees',
}

class BillingAnnexeDetailNormalizer {
  normalize(interventions: Interventions[]): FolderDto[] {
    const result: FolderDto[] = []
    if (interventions !== undefined) {
      let ToFolder: Interventions[] = [...interventions]
      let foldersCodeERP: FoldersCodeERP[] = []
      // extract folders codes ERP and indexes
      interventions.forEach((intervention, i) => {
        intervention.fkintervention.prestations.forEach((p) => {
          foldersCodeERP.push({
            index: i,
            codeDossierERP:
              p.fkservicesouscrit?.fkdossierservice?.codeDossierERP,
          })
        })
      })
      // get unique codes
      foldersCodeERP = foldersCodeERP.filter(
        (v, i, a) =>
          a.findIndex((xt) => xt.codeDossierERP === v.codeDossierERP) === i &&
          v.codeDossierERP !== undefined
      )

      // remove duplicates interventions
      ToFolder = ToFolder.filter(
        (v, i) => foldersCodeERP.find((f) => f.index === i) !== undefined
      )

      ToFolder.forEach((folder: Interventions) => {
        let annexe: AnnexDto[] = []
        interventions.forEach((intervention: Interventions) => {
          // recuperation des annexes
          // recuperattion des intervention
          if (
            intervention.fkintervention?.prestations.length &&
            intervention.fkintervention !== undefined &&
            intervention.fkintervention?.prestations[0].fkservicesouscrit
              ?.fkdossierservice?.codeDossierERP ===
              folder.fkintervention?.prestations[0].fkservicesouscrit
                ?.fkdossierservice?.codeDossierERP
          ) {
            const interventionRaw = intervention.fkintervention
            if (interventionRaw) {
              // adding the intervention
              annexe.push({
                id: interventionRaw.idIntervention.toString(),
                title: `Bon_d'intervention_N°${interventionRaw.numBonIntervention}`,
                date: formatDate(
                  interventionRaw.dateBonIntervention,
                  'DD/MM/YYYY'
                ),
                type: ANNEXE_TYPE.INTERVENTION,
              })
              // adding the pesees
              interventionRaw.pesees.forEach((pesee) => {
                annexe.push({
                  id: pesee.idPesee.toString(),
                  title: `Bon_de_pesée_N°${pesee.numBonPesee}`,
                  date: formatDate(pesee.dateBonPesee, 'DD/MM/YYYY'),
                  type: ANNEXE_TYPE.PESEES,
                })
              })
            }
          }
        })
        annexe = annexe.filter(
          (v, i, a) => a.findIndex((xt) => xt.id === v.id) === i
        )
        const item: FolderDto = {
          id: '',
          number: '',
          institutionName: '',
          description: '',
          address: '',
          annexes: annexe,
        }
        if (
          folder.fkintervention !== undefined &&
          folder.fkintervention?.prestations.length
        ) {
          const prestation = folder.fkintervention.prestations[0]
          item.institutionName = prestation.fkadressesitepreste.nomAdresse
          item.id = folder.fkintervention.idIntervention.toString()
          item.number =
            prestation.fkservicesouscrit?.fkdossierservice?.codeDossierERP
          item.address = formatAdresse(prestation.fkadressesitepreste)
          item.description =
            prestation.fkservicesouscrit?.fkdossierservice?.descriptionDossier
          // adding converted ligne to the result
          if (item.number) result.push(item)
        }
      })
    }
    return result
  }
}

export default new BillingAnnexeDetailNormalizer()
