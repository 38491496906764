/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode, useRef, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useMsal } from '@azure/msal-react'
import MenuSubItem from '../../utils/MenuSubItem'
import SvgIcon from '../Icons/SvgIcon'
import { AppContext } from '../../store/context'
import { ActionTypes } from '../../store/actions'
import Color from '../../utils/COLORS'
import { dispatchUserInformations } from '../../services/user-services'
import ROUTES from '../../utils/ROUTES'
import { logout } from '../../services/connexion-services'

interface Props {
  title: ReactNode
  subItems?: Array<MenuSubItem>
  itemId?: string
  isOpen?: boolean
  link?: string
  className?: string
  iconName?: string
  onClick?: (id: string) => void
  disabled?: boolean
}

const MenuItem: React.FC<Props> = ({
  title,
  subItems,
  itemId,
  isOpen,
  link,
  className,
  iconName,
  onClick,
  disabled,
}: Props) => {
  const {
    state: {
      impersonnation: { isImpersonnation },
    },
  } = React.useContext(AppContext)
  const switchArrow = () => {
    if (onClick && itemId) {
      onClick(itemId)
    }
  }

  const ref = useRef<HTMLDivElement>(null)
  const [isOpenInternal, setIsOpenInternal] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const { current } = ref
      if (current !== null) {
        if (!current.contains(event.target as Node)) {
          setIsOpenInternal(false)
        } else {
          setIsOpenInternal(true)
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
  const getArrow = (
    isImpersonnationMode: boolean,
    isUserMenu: boolean,
    isOpenArrow: boolean | undefined,
    isOpenInternalArrow: boolean
  ) => {
    if (isImpersonnationMode && isUserMenu) {
      return null
    }
    if (isOpenArrow && isOpenInternalArrow) {
      return (
        <>
          <SvgIcon name="arrowUp" className="menu__svg" />
        </>
      )
    }
    return <SvgIcon name="arrowDown" className="menu__svg" />
  }
  return (
    <div className={`menu__item ${className}`} onClick={switchArrow} ref={ref}>
      {(subItems && subItems.length > 0) ||
      (isImpersonnation && className === 'impersonnation') ? (
        <>
          <div className="menu__main-item">
            <div className="menu__iconTitle">
              {iconName ? (
                <SvgIcon name={iconName} className="menu__icon" />
              ) : null}
              {title}
            </div>
            {getArrow(
              isImpersonnation,
              className === 'impersonnation',
              isOpen,
              isOpenInternal
            )}
          </div>

          {subItems && subItems.length > 0 && (
            <MenuSubItems
              subItems={subItems}
              isOpen={isOpen && isOpenInternal}
            />
          )}
        </>
      ) : null}

      {link && disabled ? (
        <div>
          {iconName ? <SvgIcon name={iconName} className="menu__icon" /> : null}
          {title}
        </div>
      ) : null}

      {link && !disabled ? (
        <Link to={link}>
          {iconName ? <SvgIcon name={iconName} className="menu__icon" /> : null}
          {title}
        </Link>
      ) : null}
    </div>
  )
}
MenuItem.defaultProps = {
  subItems: [],
  itemId: '',
  isOpen: false,
  link: undefined,
  className: '',
  iconName: undefined,
  onClick: () => console.debug('do nothing'),
  disabled: false,
}

interface MenuSubItemsProps {
  subItems: Array<MenuSubItem>
  isOpen?: boolean
}
const MenuSubItems: React.FC<MenuSubItemsProps> = ({
  subItems,
  isOpen,
}: MenuSubItemsProps) => {
  // STATE
  const { state, dispatch } = React.useContext(AppContext)
  // getting the instance
  const { instance } = useMsal()
  const history = useHistory()
  // HANDLES
  const handleLogout = () => {
    const channel = new BroadcastChannel('PCE-CHANNEL')
    channel.postMessage('DISCONNECT')

    // log out using azure sso
    if (state.user.isInternal) {
      instance.logout()
    }
    dispatchUserInformations(
      {
        username: '',
        userId: '',
        organizations: [],
        roleNames: [],
        firstname: '',
        backgroundColor: Color.DEFAULT,
      },
      dispatch,
      state
    )
    // reset all roles
    dispatch({
      type: ActionTypes.SET_ALL_ROLES,
      payload: {
        allRoles: [],
      },
    })
    // reset Organisations and Institutions
    dispatch({
      type: ActionTypes.SET_ORGANIZATION_LIST,
      payload: {
        organizationList: [],
      },
    })
    dispatch({
      type: ActionTypes.SET_INSTITUTION_LIST,
      payload: {
        institutionList: [],
      },
    })
    dispatch({
      type: ActionTypes.SET_INSTITUTION_PERIMETER,
      payload: {
        institutionPerimeter: [],
      },
    })
    dispatch({
      type: ActionTypes.SET_SERVICE_LIST,
      payload: { serviceList: [] },
    })
    // quitter l'impersonnation en cas de déconnexion
    dispatch({
      type: ActionTypes.SET_IS_IMPERSONNATION,
      payload: {
        isImpersonnation: false,
      },
    })
    dispatch({
      type: ActionTypes.SET_USERNAME_IMPERSONNATION,
      payload: {
        nameImpersonnated: '',
        accessDemands: false,
        emailImpersonated: '',
      },
    })

    history.push(ROUTES.CheckEmail)
    // clear the cookies and logout
    logout()
  }

  if (!subItems) {
    return null
  }

  const className = isOpen
    ? 'menu__sub-items menu__sub-items--opened'
    : 'menu__sub-items'
  return (
    <ul className={className}>
      {subItems.map(({ title, link }) => (
        <li key={title} className="menu__sub-item">
          <Link
            className="menu__sub-item-link"
            to={link}
            onClick={link === ROUTES.CheckEmail ? handleLogout : undefined}
          >
            {title}
          </Link>
        </li>
      ))}
    </ul>
  )
}
MenuSubItems.defaultProps = { isOpen: false }

export default MenuItem
