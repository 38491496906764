import {
  getRoleUrisFromRoleNames,
  getSupportNLabels,
} from '../services/roles-services'
import { UserEntity } from './UserEntity'
import { ADMIN_ROLES, ROLE_SYMBOLE, SUPPORT_ROLES } from './ROLES'
import AllRolesEntity from './AllRolesEntity'

export default function userCanBeActivated(user: UserEntity): boolean {
  let haveCompany = false
  let havePerimeter = false
  if (user.companyId) haveCompany = true
  if (user.organizations.length > 0) havePerimeter = true
  return haveCompany && havePerimeter
}

export const getStatusValue = (
  status: string,
  AvailableOptions: { value: string; label: string }[]
): string => {
  let statusLabel = ''
  AvailableOptions.forEach((option) => {
    if (option.value === status) statusLabel = option.label
  })
  return statusLabel
}

export const getAdminUserSymbol = (
  userRoles: string[] | undefined,
  allRoles: AllRolesEntity[],
  fullCharacters?: boolean
): string => {
  if (userRoles?.length === 1) {
    const roleName = allRoles.filter((role) => role.id === userRoles[0])[0].name
    if (SUPPORT_ROLES.includes(roleName)) {
      return getSupportNLabels(roleName, fullCharacters)
    }
  }

  const displayRoleAs = fullCharacters
    ? ROLE_SYMBOLE.fullCharacters
    : ROLE_SYMBOLE.abbreviation
  const adminRoleUris = getRoleUrisFromRoleNames(ADMIN_ROLES, allRoles)
  return userRoles?.some((role: string) => adminRoleUris.includes(role))
    ? displayRoleAs
    : ''
}

export function isSuezMail(email: string): boolean {
  return ['suez.com', 'suez.fr', 'suezenvironnement.com'].includes(
    email.toLowerCase().split('@')[1]
  )
}
