/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
/* eslint no-irregular-whitespace: ["error", { "skipStrings": true }] */
import axios, { CancelTokenSource } from 'axios'

import InstitutionNormalizer from '../normalizer/InstitutionNormalizer'
import { distinctArray } from '../utils/array-utils'
import regroupURL from '../utils/InstitutionUtils'
import InstitutionEntity from '../utils/InstitutionEntity'
import {
  getMemberData,
  getNextPage,
  getTotalItems,
  isLastPage,
} from './hydra-services'
import { addOrganizationsToUrl } from './organization-services'
import ROUTES from '../utils/ROUTES'
import { isCancel } from './billing-services'
import OrganizationEntity from '../utils/OrganizationEntity'

let cancelToken: CancelTokenSource

export interface SearchResult {
  entities: InstitutionEntity[]
  totalItems: number
}

async function searchInstitution(
  url: string,
  history: any,
  maxTotalLimit = -1,
  pagination = false,
  byPassCancel = true,
  params?: URLSearchParams
): Promise<SearchResult> {
  let currentUrl = url
  let result: InstitutionEntity[] = []
  let totalItems = 0
  let data

  if (!byPassCancel) {
    if (cancelToken !== undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = axios.CancelToken.source()
  }

  try {
    let hasMorePage = true
    while (hasMorePage) {
      const resp = byPassCancel
        ? await axios({
            method: 'GET',
            url: currentUrl,
            params,
          })
        : await axios({
            method: 'GET',
            url: currentUrl,
            params,
            cancelToken: cancelToken?.token,
          })
      data = getMemberData(resp.data)
      totalItems = getTotalItems(resp.data)
      const entities = InstitutionNormalizer.normalize(data)
      result = [...result, ...entities]

      if (
        data.length === 0 ||
        (maxTotalLimit > -1 && totalItems > maxTotalLimit)
      ) {
        hasMorePage = false
      } else if (data.length > 0) {
        hasMorePage = !isLastPage(resp.data) && !pagination
        currentUrl = `${process.env.REACT_APP_BASE_API_WDH_URL}${getNextPage(
          resp.data
        )}`
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve({ totalItems: 0, entities: err })
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(
      `Erreur lors de l'exécution de l'api searchInstitution avec l'url: ${url}`
    )
  }
  return {
    entities: result,
    totalItems,
  }
}

// TODO refactor common code

export async function searchInstitutionByTextAndOrganizationsIds(
  value: string,
  organizationIds: string[],
  history: any,
  maxTotalLimit = -1,
  disablePerimeter?: boolean,
  specificPerPage?: number,
  expandedMode = false
): Promise<SearchResult> {
  let url
  const params = new URLSearchParams({})
  if (value) {
    params.append('searchText', value)
  }
  if (specificPerPage) {
    url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises?perPage=${specificPerPage}`
  } else {
    url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  }
  if (expandedMode) url += '&expandedMode=true'
  if (!disablePerimeter) url = addOrganizationsToUrl(url, organizationIds)

  return searchInstitution(url, history, maxTotalLimit, false, true, params)
}

export function searchInstitutionByOrganizationIds(
  organizationIds: string[],
  history: any,
  expandedMode = false
): Promise<SearchResult> {
  if (!organizationIds) {
    Promise.resolve({
      entities: [],
      totalItems: 0,
    })
  }

  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}&sort[raisonSociale]=asc`
  if (expandedMode) url += '&expandedMode=true'

  url = addOrganizationsToUrl(url, organizationIds)

  return searchInstitution(url, history)
}

async function responsesAnalyser(responses: any[]): Promise<SearchResult> {
  let entity: InstitutionEntity[] = []
  const result = {
    entities: entity,
    totalItems: 0,
  }

  responses.forEach((resp: any) => {
    if (resp.data) {
      const data = getMemberData(resp.data)
      const totalItems = getTotalItems(resp.data)

      const entities = InstitutionNormalizer.normalize(data)

      entity = entity.concat(entities)
      result.totalItems += totalItems
    }
  })

  result.entities = entity
  return Promise.resolve(result)
}

export async function searchInstitutionByEntreprise(
  entreprisesIds: string[],
  history: any
): Promise<SearchResult> {
  if (!entreprisesIds) {
    return {
      entities: [],
      totalItems: 0,
    }
  }
  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  const entrepriseIds = distinctArray(entreprisesIds)
  const maxValue =
    parseInt(`${process.env.REACT_APP_MAX_COMPANY_ID_LENGTH}`, 10) || 200
  const entity: InstitutionEntity[] = []
  let result = {
    entities: entity,
    totalItems: 0,
  }

  if (entrepriseIds.length < maxValue) {
    entrepriseIds.forEach((id) => {
      url = url.concat(`&idEntreprise[]=${id}`)
    })
    // eslint-disable-next-line no-return-await
    return await searchInstitution(url, history)
  }

  const urlArray = regroupURL(entrepriseIds, url, maxValue)

  const requestArray: any[] = []
  urlArray.forEach((uri: string) => {
    requestArray.push(axios.get(uri))
  })

  const promisesResolved = requestArray.map((request) =>
    request.catch((err: any) => ({ err }))
  )

  await axios
    .all(promisesResolved)
    .then(
      axios.spread(async (...responses: any[]) => {
        result = await responsesAnalyser(responses)
      })
    )
    .catch((err: any) => {
      console.error(err)
      history.push(ROUTES.TechnicalError)
    })

  return result
}

export async function getEtablissementByCompanyid(
  entrepriseId: string,
  history: any
): Promise<InstitutionEntity> {
  let entity = {
    id: '',
    name: '',
    address: '',
    siret: '',
    villeCP: '',
    shortAdress: '',
    numDebiteur: '',
  }
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises/${entrepriseId}`

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken?.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      cancelToken: cancelToken?.token,
    })

    if (resp.data) {
      const countainer = []
      countainer.push(resp.data)
      const entities = InstitutionNormalizer.normalize(countainer)
      // added to pass eslint error :Use array destructuring
      const [result] = entities
      entity = result
    }
  } catch (e) {
    if (isCancel(e)) {
      return e
    }
    console.error(e)
    history.push(ROUTES.TechnicalError)
    return entity
  }
  return entity
}

export async function getEntreprisesByIds(
  entrepriseIds: string[],
  history: any,
  byPassCancel: boolean
): Promise<SearchResult> {
  if (!entrepriseIds || entrepriseIds.length === 0) {
    return {
      entities: [],
      totalItems: 0,
    }
  }

  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises?`

  entrepriseIds.forEach((id) => {
    url = url.concat(`&idEntreprise[]=${id}`)
  })
  // eslint-disable-next-line no-return-await
  return await searchInstitution(url, history, -1, false, byPassCancel)
}

export async function searchInstitutionByOrgaAndInstitution(
  entrepriseIds: string[],
  organisationId: string,
  history: any,
  page?: number,
  nbPage?: number,
  expandedMode = false
): Promise<SearchResult> {
  if (!entrepriseIds || !organisationId) {
    return {
      entities: [],
      totalItems: 0,
    }
  }

  const nb = nbPage
    ? `perPage=${nbPage}`
    : `perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  const p = page ? `&page=${page}` : ''

  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises?${nb}${p}&sort[raisonSociale]=asc`

  entrepriseIds.forEach((id) => {
    url = url.concat(`&idEntreprise[]=${id}`)
  })
  url = url.concat(`&idOrganisation[]=${organisationId}`)
  if (expandedMode) url = url.concat('&expandedMode=true')
  const pagination = !!nbPage
  // eslint-disable-next-line no-return-await
  return await searchInstitution(url, history, -1, pagination)
}
export async function getInstitutionByIdOrganization(
  idOrganization: string,
  history: any,
  page?: number,
  nbPage?: number,
  institution: InstitutionEntity[] = [],
  expandedMode = false
): Promise<SearchResult> {
  const params = new URLSearchParams()
  const nb = nbPage
    ? `perPage=${nbPage}`
    : `perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  const p = page ? `&page=${page}` : ''

  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${idOrganization}/etablissements?${nb}${p}&sort[raisonSociale]=asc`
  let result: InstitutionEntity[] = []
  let totalItems = 0
  let data
  institution.forEach((insti) => {
    params.append(`idEntreprise[]`, insti.id)
  })
  if (expandedMode) params.append(`expandedMode`, 'true')
  try {
    let hasMorePage = true
    while (hasMorePage) {
      const resp = await axios({
        method: 'GET',
        url,
        params,
      })
      data = getMemberData(resp.data)
      totalItems = getTotalItems(resp.data)

      if (data.length > 0) {
        const entities = InstitutionNormalizer.normalize(data)
        result = [...result, ...entities]

        hasMorePage = !isLastPage(resp.data) && !nbPage
        url = `${process.env.REACT_APP_BASE_API_WDH_URL}${getNextPage(
          resp.data
        )}`
      } else {
        hasMorePage = false
      }
    }
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(
      `Erruer lors de l'exécution de l'api getInstitutionByIdOrganization avec l'url: ${url}`
    )
  }
  return {
    entities: result,
    totalItems,
  }
}

export async function getInstitutionsForTable(
  perPage: number,
  page: number,
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  filters: { [key: string]: string[] },
  history: any
): Promise<{
  results: InstitutionEntity[]
  pageCount: number
  totalItems: number
}> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/etablissement_entreprises`
  let listOfinstitution: InstitutionEntity[] = []
  const params = new URLSearchParams()
  params.append(`page`, String(page))
  params.append(`perPage`, String(perPage))
  organisation.forEach((org) => {
    params.append(`idOrganisation[]`, org.id)
  })
  institution.forEach((insti) => {
    params.append(`idEntreprise[]`, insti.id)
  })
  params.append('sort[raisonSociale]', 'asc')
  Object.entries(filters).forEach(([key, values]) => {
    values.forEach((value: string) => params.append(`${key}[]`, value))
  })

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })
    if (resp?.data) {
      listOfinstitution = InstitutionNormalizer.normalize(
        getMemberData(resp.data)
      )
      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      return {
        results: [...listOfinstitution],
        pageCount: Math.ceil(totalItems / perPage),
        totalItems,
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return Promise.resolve({ pageCount: 0, results: [], totalItems: 0 })
}
