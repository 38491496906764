/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect } from 'react'

export const TAB_USER = 'users'
export const TAB_ORGANISATION = 'organisations'
export const TAB_MESSAGES = 'messages'
export const TAB_INSTITUTIONS = 'institutions'
export const TAB_DOCUMENTS = 'documents'
export const TAB_BILL = 'bill'
export const TAB_CREDIT = 'credit'
export const TAB_BRM = 'brm'
export const TAB_COMMUNICATIONS = 'communications'
export const TAB_NOTIFICATIONS = 'notifications'

export const generateColumns = (columnNames: string[]): any[] => {
  const result: any[] = []
  let i = 0
  columnNames.forEach((colName) => {
    result.push({
      Header: colName,
      accessor: `col${i}`,
    })
    i += 1
  })

  return result
}

export const generateData = (
  ids: string[],
  columnNames: string[],
  data: any[]
): any[] => {
  const result: any[] = []
  const totalCol = Object.keys(columnNames).length

  data.forEach((d, dataIndex) => {
    const cellData: any = { id: ids[dataIndex] }
    const dataKeys = Object.keys(d)

    for (let i = 0; i < totalCol; i += 1) {
      cellData[`col${i}`] = d[dataKeys[i]]
    }
    result.push(cellData)
  })
  return result.slice(0, ids.length)
}

// to know if is the first render
export function useFirstRender() {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

export function getNoDataMessage(type: string): any {
  let message = ''
  switch (type) {
    case TAB_USER:
      message = 'table.noDataAvailable.users'
      break
    case TAB_ORGANISATION:
      message = 'table.noDataAvailable.organizations'
      break
    case TAB_MESSAGES:
      message = 'table.noDataAvailable.infoBanner'
      break
    case TAB_BILL:
      message = 'table.noDataAvailable.billings'
      break
    case TAB_CREDIT:
      message = 'table.noDataAvailable.credit'
      break
    case TAB_BRM:
      message = 'table.noDataAvailable.brm'
      break
    case TAB_DOCUMENTS:
      message = 'table.noDataAvailable.documents'
      break
    case TAB_INSTITUTIONS:
      message = 'table.noDataAvailable.institutions'
      break
    case TAB_COMMUNICATIONS:
      message = 'table.noDataAvailable.communications'
      break
    case TAB_NOTIFICATIONS:
      message = 'table.noDataAvailable.notifications'
      break
    default:
      message = 'table.noDataAvailable.default'
  }

  return message
}
