/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useHistory, Route } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import ROLES from '../../utils/ROLES'
import ROUTES from '../../utils/ROUTES'
import MAINTENANCE_TYPE from '../../utils/maintenance-utils'
import { hasAccessTo, roleNamesToNumber } from '../../services/roles-services'
import { AppContext } from '../../store/context'
import { DecodedToken } from '../../services/user-services'
import handleNavigation from '../../utils/RedirectionUtils'

interface CustomRouteProps {
  children?: React.ReactNode
  path: string | string[]
  exact: boolean
  condition: ROLES[]
  personnalizedCondition?: boolean
  partialMaintenance?: PartialMaintenance
}

interface PartialMaintenance {
  type: MAINTENANCE_TYPE.ALL | MAINTENANCE_TYPE.DEMANDS
}

const CustomRoute = (props: CustomRouteProps) => {
  const history = useHistory()

  const {
    state: { user },
  } = React.useContext(AppContext)

  const [returnedRoute, setReturnedRoute] = useState(<Route {...props} />)

  const { condition, path, personnalizedCondition, partialMaintenance } = props

  useEffect(() => {
    const impersonateMode = sessionStorage.getItem('impersonate_mode')
    let accessToken = localStorage.getItem('access_token')

    if (impersonateMode) {
      // Cas de refresh page en mode impersonation : récuperation de roles de l'admin
      if (path !== ROUTES.UserImpersonation) {
        accessToken = sessionStorage.getItem('impersonation_token')
      }
      // Récupérer les rôles en mode impersonation depuis le token
      const decodedToken: DecodedToken = jwt_decode(accessToken || '')
      const impersonateUserRoles = decodedToken.roles.map((e) => e.name)
      // Vérifier l'accès et rediriger si nécessaire
      if (!hasAccessTo(condition, roleNamesToNumber(impersonateUserRoles))) {
        handleNavigation(impersonateUserRoles, history)
      }
    } else {
      // Vérifier l'accès et rediriger si nécessaire
      const userRoles = user.roleNames

      if (userRoles.length === 0) {
        history.push(ROUTES.CheckEmail)
      } else if (!hasAccessTo(condition, roleNamesToNumber(userRoles))) {
        history.push(ROUTES.DeniedAccess)
      }
    }

    if (personnalizedCondition) {
      history.push(ROUTES.DeniedAccess)
    }

    if (partialMaintenance?.type) {
      if (
        localStorage.getItem(`maintenanceMode${partialMaintenance?.type}`) ===
        'true'
      )
        history.push(ROUTES.MaintenanceError, {
          type: partialMaintenance.type,
        })
    }

    setReturnedRoute(<Route {...props} />)
  }, [path])

  return returnedRoute
}

export default CustomRoute
