import React from 'react'

interface Props {
  children: React.ReactNode
}
const AuthenticatedScreenContainer = ({ children }: Props) => {
  return (
    <div className="authenticated-screen-container">
      <div className="authenticated-screen-container__children">{children}</div>
    </div>
  )
}

export default AuthenticatedScreenContainer
