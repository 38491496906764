import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { hasAccessTo } from '../../services/roles-services'
import MenuItem from './MenuItem'
import MenuSubItem from '../../utils/MenuSubItem'
import ROLES_ACCESS from '../../utils/ROLES_ACCESS'
import { isSubMenu } from '../../services/menu-services'
import SubMenu from './SubMenu'
import ROUTES from '../../utils/ROUTES'
import { AppContext } from '../../store/context'

interface Props {
  currentRoles: number[]
  itemCollapsed: string
  onItemCollapsed: (id: string) => void
  activitiesMenuItems: MenuSubItem[]
  billingMenuItems: MenuSubItem[]
}

function MainMenu({
  currentRoles,
  itemCollapsed,
  onItemCollapsed,
  activitiesMenuItems,
  billingMenuItems,
}: Props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const {
    state: { impersonnation, user },
  } = useContext(AppContext)
  if (isSubMenu(pathname)) {
    return <SubMenu />
  }
  const ACCESS_DEMANDS = Boolean(
    parseInt(process?.env?.REACT_APP_ACCESS_DEMANDS || '1', 10)
  )

  const hasAccessToDemand = useMemo(() => {
    const access =
      ACCESS_DEMANDS &&
      (impersonnation.accessDemands || user.accessDemands) &&
      hasAccessTo(ROLES_ACCESS.myDemandsRoles, currentRoles)
    return access
  }, [user, impersonnation])

  return (
    <>
      {hasAccessTo(ROLES_ACCESS.mainMenuActivityRoles, currentRoles) && (
        <MenuItem
          title={t('menu.menu1.title')}
          subItems={activitiesMenuItems}
          itemId="activities"
          isOpen={itemCollapsed === 'activities'}
          iconName="assesstment"
          onClick={onItemCollapsed}
        />
      )}
      {hasAccessTo(ROLES_ACCESS.mainMenubillRoles, currentRoles) && (
        <MenuItem
          title={t('menu.menu2.title')}
          subItems={billingMenuItems}
          itemId="billing"
          isOpen={itemCollapsed === 'billing'}
          iconName="receipt"
          onClick={onItemCollapsed}
        />
      )}
      {hasAccessTo(ROLES_ACCESS.mesDocumentsRoles, currentRoles) && (
        <MenuItem
          title={t('menu.link1')}
          link={ROUTES.MyDocuments}
          iconName="folder"
        />
      )}
      {hasAccessToDemand && (
        <MenuItem
          title={t('menu.link3')}
          link={ROUTES.MyDemands}
          iconName="folder"
        />
      )}
    </>
  )
}

export default MainMenu
