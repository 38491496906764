/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Filter {
  id: string
  value: any
}

export default interface BillEntity {
  id: number
  number: string
  status: string
  price: number
  date: Date
  formattedDate: string
  formattedDateAsYearMonth?: string
  type: string
  linkDL: string
  name: string
  address: string
  interval: string
}

export interface FolderDto {
  id: string
  number: string
  institutionName: string
  description: string
  address: string
  annexes: AnnexDto[]
}

export interface contentForZip {
  docUrl: string
  docName: string
  dirName: string
  docType: string
}

export interface annexContentForZip extends contentForZip {
  id: string
}

export interface AnnexDto {
  id: string
  title: string
  date: string
  type: string
}

export interface FolderStateType {
  [key: string]: boolean
}

export function getFilterForId(filters: Filter[], id: string): any {
  const filter = filters.find((f) => f.id === id)
  return filter?.value
}

export interface StatusOption {
  value: string
  label: string
  apiValue?: string
}

// TODO translation
export const statusOptions: StatusOption[] = [
  {
    value: 'all',
    label: 'Statuts de paiement',
    apiValue: '',
  },
  {
    value: 'notPaid',
    label: 'En attente*',
    apiValue: 'En attente',
  },
  {
    value: 'closed',
    label: 'Partiel*',
    apiValue: 'Partiel',
  },
  {
    value: 'paid',
    label: 'Complet*',
    apiValue: 'Complet',
  },
]

export const getStatusLabel = (apiValue: string) => {
  const found = statusOptions.find(
    (option: StatusOption) => option.value === apiValue
  )
  return found ? found.label : statusOptions[0].label
}

export const downloadErrorType = ['unavailable', 'technical']
