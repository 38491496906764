/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { createContext, useReducer, Dispatch } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ApplicationInsights,
  DistributedTracingModes,
} from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

import { initialState, InitialStateType } from './state'
import { APP_ACTIONS } from './actions'
import {
  userReducer,
  serviceReducer,
  organizationReducer,
  institutionReducer,
  institutionPerimeterReducer,
  allRolesReducer,
  uiReducer,
  UserInCreationReducer,
  createModifyUserReducer,
  signUpUserReducer,
  institutionValidationReducer,
  organizationValidationReducer,
  impersonnationReducer,
  apiCallBlockReducer,
} from './reducers'

const AppContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<APP_ACTIONS>
  appInsights: any
  // roleNames is used as a context to speed up the retrieve of the value while checking for access to a <CustomRoute>.
  // The use of a custom context is better than the redux state which requires dispatching actions and filling a complex structure.
  // Also, since the logic of authorizing users to access pages will be based on context, it's relatively more secure than using cookies.
  roleNames: string[]
  updateRoleName: (roleNames: string[]) => void
}>({
  state: initialState,
  dispatch: () => null,
  appInsights: null,
  roleNames: [],
  updateRoleName: () => null,
})

const mainReducer = (
  {
    user,
    userInCreation,
    createModifyUser,
    signUpUser,
    service,
    organization,
    institution,
    institutionPerimeter,
    organizationValidation,
    institutionValidation,
    allRoles,
    ui,
    impersonnation,
    apiBlock,
  }: InitialStateType,
  action: APP_ACTIONS
) => ({
  user: userReducer(user, action),
  userInCreation: UserInCreationReducer(userInCreation, action),
  createModifyUser: createModifyUserReducer(createModifyUser, action),
  signUpUser: signUpUserReducer(signUpUser, action),
  service: serviceReducer(service, action),
  organization: organizationReducer(organization, action),

  institution: institutionReducer(institution, action),
  institutionPerimeter: institutionPerimeterReducer(
    institutionPerimeter,
    action
  ),
  organizationValidation: organizationValidationReducer(
    organizationValidation,
    action
  ),
  institutionValidation: institutionValidationReducer(
    institutionValidation,
    action
  ),
  allRoles: allRolesReducer(allRoles, action),
  ui: uiReducer(ui, action),
  impersonnation: impersonnationReducer(impersonnation, action),
  apiBlock: apiCallBlockReducer(apiBlock, action),
})

interface Props {
  children: React.ReactNode
}

const AppContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)
  const [roleNames, setRoleNames] = React.useState<string[]>([])
  const history = useHistory()
  const reactPlugin = new ReactPlugin()
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSIGHT_INSTRUMENTATION_KEY,
      distributedTracingMode: DistributedTracingModes.W3C,
      maxBatchInterval: 0,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      disableCorrelationHeaders: false,
      isStorageUseDisabled: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: {
          history,
        },
      },
    },
  })

  appInsights.loadAppInsights()
  appInsights.trackPageView()

  appInsights.addTelemetryInitializer((envelope) => {
    if (!envelope.tags) return

    envelope.tags['ai.cloud.role'] = 'Portail'
    envelope.tags['ai.cloud.roleInstance'] =
      process.env.REACT_APP_ENVIRONMENT || 'unkown'
    envelope.tags['ai.user.id'] = state.user.userId || ''
  })

  const trackDispatch = (value: APP_ACTIONS) => {
    appInsights.trackEvent({
      name: value.type,
      properties:
        'payload' in value
          ? ((value.payload as unknown) as { [key: string]: any })
          : undefined,
    })
    dispatch(value)
  }

  const updateRoleName = (roles: string[]) => {
    setRoleNames(roles)
  }

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch: trackDispatch,
        appInsights,
        roleNames,
        updateRoleName,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }
