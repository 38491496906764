/* eslint-disable class-methods-use-this */
import InstitutionEntity from '../utils/InstitutionEntity'
import InstitutionWDH from '../utils/InstitutionWDH'

class InstitutionNormalizer {
  normalize(institutionList: InstitutionWDH[]): InstitutionEntity[] {
    return institutionList.map(
      ({ idEntreprise, raisonSociale, codeSiret, adresses, numDebiteur }) => {
        const address = adresses?.[0]
        const villeCPFormated = `${
          address?.codePostalAdresse ? address?.codePostalAdresse : ''
        } - ${address?.villeAdresse ? address?.villeAdresse : ''}`
        const adressFormated = `${
          address?.rueAdresse ? address?.rueAdresse : ''
        } ${address?.codePostalAdresse ? address?.codePostalAdresse : ''} ${
          address?.villeAdresse ? address?.villeAdresse : ''
        }`
        return {
          id: String(idEntreprise),
          name: raisonSociale,
          siret: codeSiret,
          address: adressFormated,
          villeCP: villeCPFormated,
          shortAdress: `${address?.rueAdresse ? address?.rueAdresse : ''} `,
          numDebiteur,
        }
      }
    )
  }
}

export default new InstitutionNormalizer()
