/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Table from '../Tables/Table'
import { getWasteRegisters } from '../../services/WasteRegister-services'
import SvgIcon from '../Icons/SvgIcon'
import Alert from '../Alerts/Alert'
import DatePicker from '../DatePickers/DatePicker'
import Separator from '../Separators/Separator'
import SelectedContainer from '../SelectedContainer'
import Button from '../Buttons/Button'
import { generateColumns, generateData } from '../../utils/TableUtils'
import Loading from '../Loaders/Loading'
import InstitutionEntity from '../../utils/InstitutionEntity'
import ButtonSquareIcon from '../Buttons/ButtonSquareIcon'
import PopUpInfo from '../../layouts/PopUpInfo'
import { GenerateExcel } from '../../services/documents-services'
import { getMemberData } from '../../services/hydra-services'
import WasteRegisterNormalizer from '../../normalizer/WasteRegisterNormalizer'

interface Props {
  institutionsData: InstitutionEntity[]
  checkedList: string[]
  selectedCount: number
  handleRemoveitems: (checkedList: string[]) => void
}

function WasteRegisterGenerator({
  institutionsData,
  checkedList,
  selectedCount = 0,
  handleRemoveitems,
}: Props) {
  const { t } = useTranslation()
  const [nbPage, setNbPage] = useState(
    process.env.REACT_APP_TABLE_DEFAULT_RESULT_PER_PAGE
      ? parseInt(process.env.REACT_APP_TABLE_DEFAULT_RESULT_PER_PAGE, 10)
      : 10
  )
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [isErrorGenerated, setIsErrorGenerated] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), 0, 1)
  )
  const [endDate, setEndDate] = useState<Date>(new Date())
  // table data preparation
  const columnNames = ['name', 'address', 'siret', '']
  const columns = generateColumns(columnNames)
  const [data, setData] = useState<any[]>([])
  // popup states
  const [showModal, setShowModal] = useState(false)
  const [popupHeader, setPopupHeader] = useState('')
  const [popupBody, setPopupBody] = useState('')
  const LARGE_API_RESPONSE_THRESHOLD = 'LARGE_API_RESPONSE_THRESHOLD'

  const handleRemoveAll = () => {
    handleRemoveitems(checkedList)
  }

  const handleSingleRemove = (removeId: string) => {
    handleRemoveitems([removeId])
  }

  const itemsSelectedLabel = `${
    checkedList.length <= 1
      ? t('generic.added.singular')
      : t('generic.added.plural')
  }`
  const checkBoxLabel = `${selectedCount} ${itemsSelectedLabel}`
  const generateFile = async () => {
    // get data from api :
    if (startDate !== undefined && endDate !== undefined) {
      setIsDownloading(true)
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Registre_dechets_${day}.xlsx`
      let isSendedByEmail = false
      /** ********************* */
      // Data layers GTM
      // track document
      window.dataLayer.push({
        event: 'sz_conversion',
        sz_conversion_type: 'Générer un registre déchets',
        pageCategory: 'Mes activités',
        pageSubcategory: 'registre déchets',
        documentName: filename,
        documentType: 'Registre déchets',
        documentInteraction: 'Téléchargement',
      })
      /** ********************* */
      try {
        setIsErrorGenerated(false)
        const wasteData = await getWasteRegisters(
          startDate,
          endDate,
          checkedList
        )

        if (
          'message' in wasteData &&
          wasteData.message === LARGE_API_RESPONSE_THRESHOLD
        ) {
          isSendedByEmail = true
          setIsDownloading(false)
          showPopup()
          await getWasteRegisters(startDate, endDate, checkedList, true)
        } else {
          const data = getMemberData(wasteData)
          const result = WasteRegisterNormalizer.normalize(data)
          GenerateExcel(result, startDate, endDate)
          setIsDownloading(false)
        }
      } catch (err) {
        if (!isSendedByEmail) setIsErrorGenerated(true)
      }
    }
  }
  function showSelectionList() {
    if (institutionsData.length === 0) {
      return (
        <SelectedContainer>
          {t('wasteRegister.emptylistMessage')}
        </SelectedContainer>
      )
    }

    return (
      <div>
        <div className="wasteRegister_filterContainer">
          <div className="wasteRegister_datePickerContainer">
            <span className="searchBar_label">{checkBoxLabel}</span>
          </div>
          <Button className="button__secondary" onClick={handleRemoveAll}>
            <SvgIcon name="minus" className="wasteRegister_buttonicon" />
            {t('wasteRegister.removeRegister')}
          </Button>
        </div>
        <Table
          type="hidden"
          columns={columns}
          data={data}
          checkedList={checkedList}
          headerVisible={false}
          pageCount={Math.ceil(data.length / nbPage)}
          manualPagination={false}
          onNbPerPageChange={setNbPage}
          autoResetPage={false}
        />
      </div>
    )
  }

  useEffect(() => {
    setData(
      generateData(
        checkedList,
        columnNames,
        institutionsData.map(({ id, name, address, numDebiteur }) => ({
          data1: <span className="name">{name}</span>,
          data2: <span className="address">{address}</span>,
          data4: <span className="code">{numDebiteur}</span>,
          data5: (
            <div>
              <ButtonSquareIcon
                iconName="bin"
                onClick={(_) => {
                  handleSingleRemove(id)
                }}
              />
            </div>
          ),
        }))
      )
    )
  }, [checkedList])

  const showPopup = () => {
    setPopupHeader(t('wasteRegister.popup.title'))
    setPopupBody(t('wasteRegister.popup.body'))
    setShowModal(true)
  }

  return (
    <div className="wasteRegister__right">
      <div className="wasteRegister__header_selector">
        <SvgIcon name="assesstment" className="wasteRegister_buttonicon" />
        <div className="title">{t('wasteRegister.rightTitle')}</div>
      </div>
      <div className="wasteRegister_filterCalendar">
        <div className="wasteRegister_datePickerContainer">
          <div className="wasteRegister_datePickerlabel">
            <span className="searchBar_label">
              {t('wasteRegister.picker-label')}
            </span>
          </div>
          <div className="wasteRegister_datePicker">
            <DatePicker
              initialstartDate={startDate}
              initialendDate={endDate}
              handleStartDateChange={setStartDate}
              handleEndDateChange={setEndDate}
              isMonthly
            />
          </div>
        </div>
        <div>
          <div />

          <Button
            onClick={() => generateFile()}
            disabled={selectedCount === 0 || isDownloading}
            className="wasteRegister_exportBtn"
            id="wasteRegister_exportBtn"
          >
            <Loading isLoading={isDownloading} inButton>
              <SvgIcon name="uploadFile" className="wasteRegister_buttonicon" />
              {t('wasteRegister.generation_btn')}
            </Loading>
          </Button>
        </div>
      </div>

      <Separator />

      {/* show data if not empty */}
      {isErrorGenerated && (
        <Alert type="alert" alertMessaeg={t('wasteRegister.generated_error')} />
      )}
      {showSelectionList()}

      <PopUpInfo
        type="info"
        title={popupHeader}
        body={popupBody}
        show={showModal}
        onHideShow={() => {
          setShowModal(false)
        }}
        onHideShowClassName="button"
        btnText={t('wasteRegister.popup.btn')}
      />
    </div>
  )
}

export default WasteRegisterGenerator
