/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'
import CollapseTableNormalizer from '../normalizer/CollapseTableNormalizer'
import HierarchyNormalizer from '../normalizer/HierarchyNormalizer'
import OrganizationNormalizer from '../normalizer/OrganizationNormalizer'
import CollapseDto from '../utils/CollapseDto'
import HierarchyEntity from '../utils/HierarchyEntity'
import InstitutionEntity from '../utils/InstitutionEntity'
import ROUTES from '../utils/ROUTES'

import OrganizationEntity, {
  FormatedOrganization,
  CreatedOrganization,
  frontCreatedOrganization,
  OrganizationTableEntity,
  OrganizationDocumentEntity,
  OrganizationWDHFull,
} from '../utils/OrganizationEntity'
import {
  GROUPING_TYPE,
  GROUPING_FAMILY,
  WDH_GROUPING_TYPE,
  WHD_GROUPING_FAMILY,
  ORGANIZATION_STATUT,
} from '../utils/CreateOrganization-utils'
import OrganizationWDH from '../utils/OrganizationWDH'
import {
  getNextPage,
  isLastPage,
  getMemberData,
  getTotalItems,
} from './hydra-services'
import {
  getInstitutionByIdOrganization,
  searchInstitutionByOrgaAndInstitution,
  searchInstitutionByOrganizationIds,
  searchInstitutionByTextAndOrganizationsIds,
} from './institution-services'
import { getFilterForId } from '../utils/BillEntity'
import { isCancel } from './billing-services'
import { AgManagerialApiResults } from '../utils/interfaces/Organization/AgManagerialInterface'
import AgManagerialNormalizer from '../normalizer/AgManagerialNormalizer'

let cancelToken: CancelTokenSource

export interface SearchResult {
  entities: OrganizationEntity[]
  totalItems: number
}

interface SearchFullResult {
  organizations: OrganizationEntity[]
  linkedInstitutions: InstitutionEntity[]
  totalItems: number
}

const labelsToApiFields: { [key: string]: string } = {
  Nom: 'nomOrganisation',
  Regroupement: 'familleOrganisation',
  'Modifié le': 'dateModification',
  'Créé le': 'dateCreation',
  Statut: 'statut',
}

async function searchOrganization(
  url: string,
  maxTotalLimit = -1,
  params?: URLSearchParams
): Promise<SearchResult> {
  let currentUrl = url
  let result: OrganizationEntity[] = []
  let totalItems = 0
  let data

  try {
    let hasMorePage = true
    while (hasMorePage) {
      /** We use URLSearchParams instead of including the params in the url string to fix special characters encoding */
      const resp = await axios({
        method: 'GET',
        url: currentUrl,
        params,
      })
      data = getMemberData(resp.data)
      totalItems = getTotalItems(resp.data)
      if (data) {
        const entities = OrganizationNormalizer.normalize(data)
        result = [...result, ...entities]
      }
      if (maxTotalLimit > -1 && totalItems > maxTotalLimit) {
        hasMorePage = false
      }
      // fetch nextPage
      else {
        if (!resp.data['hydra:view']) break
        hasMorePage = !isLastPage(resp.data)
        currentUrl = `${process.env.REACT_APP_BASE_API_WDH_URL}${getNextPage(
          resp.data
        )}`
      }
    }
  } catch (err) {
    throw new Error(err)
  }
  return {
    entities: result,
    totalItems,
  }
}

export function addOrganizationsToUrl(url: string, organizationIds: string[]) {
  let urlResult = url

  organizationIds.forEach((id) => {
    urlResult = urlResult.concat(`&idOrganisation[]=${id}`)
  })

  return urlResult
}

export async function searchOrganizationByName(
  organizationName: string,
  organizationIds: string[],
  history: any,
  maxTotalLimit = -1,
  disablePerimeter?: boolean,
  onlyClientOrganization?: boolean,
  expandedMode = false
): Promise<SearchResult> {
  const params = new URLSearchParams({})
  if (organizationName) {
    params.append('nomOrganisation', organizationName)
  }
  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  url += `&statut[]=${ORGANIZATION_STATUT.STATUT_ACTIF}`
  if (!disablePerimeter) url = addOrganizationsToUrl(url, organizationIds)
  if (onlyClientOrganization)
    url += `&familleOrganisation[]=${WHD_GROUPING_FAMILY.WHD_CLIENT}`
  if (expandedMode) url += `&expandedMode=true`
  try {
    return searchOrganization(url, maxTotalLimit, params)
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(
      `Erreur lors de l'appel de l'api searchOrganizationByName avec l'url: ${url}`
    )
  }
}

export async function searchOrganizationByIds(
  organizationIds: string[],
  history: any,
  expandedMode = false
): Promise<SearchResult> {
  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  organizationIds.forEach((id) => {
    url = url.concat(`&idOrganisation[]=${id}`)
  })
  if (expandedMode) url = url.concat(`&expandedMode=true`)
  try {
    return searchOrganization(url)
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    return { entities: [], totalItems: 0 }
  }
}

export async function getCompanytByEtablissementid(
  entrepriseIds: string[],
  history: any,
  rawDataReturn: boolean,
  onlyClientOrganization?: boolean,
  expandedMode?: boolean
): Promise<SearchResult | any> {
  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  url += `&statut[]=${ORGANIZATION_STATUT.STATUT_ACTIF}`
  if (onlyClientOrganization) {
    url += `&familleOrganisation[]=${WHD_GROUPING_FAMILY.WHD_CLIENT}`
  }
  entrepriseIds.forEach((ets) => {
    url += `&idEntreprise[]=${ets}`
  })

  if (expandedMode) url += '&expandedMode=true'

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      cancelToken: cancelToken?.token,
    })

    if (resp.data && rawDataReturn) {
      return resp
    }

    const result = getMemberData(resp.data)
    const totalItems = getTotalItems(resp.data)
    if (result) {
      return {
        entities: OrganizationNormalizer.normalize(result),
        totalItems,
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return {
        entities: err,
        totalItems: 0,
      }
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(
      `Erreur lors de l'appel de l'api searchOrganization avec l'url: ${url}`
    )
  }

  return {
    entities: [],
    totalItems: 0,
  }
}

export async function getPerimeter(
  organizationIds: string[],
  autoSearchInstitution = true,
  history: any,
  institutionIds: string[] = [],
  statut: string[] | undefined = [],
  withAllEtablissementInOrganization = false,
  onlyClientOrganization?: boolean,
  perPage?: number,
  page?: number,
  expandedMode = false,
  institutionCountUnlimited = false
): Promise<CollapseDto[] | any> {
  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  let hierarchy: HierarchyEntity[] = []
  const hierarchyTotal: HierarchyEntity[] = []
  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  if (statut) {
    statut.forEach((status) => {
      url += `&statut[]=${status}`
    })
  }
  if (onlyClientOrganization)
    url += `&familleOrganisation[]=${WHD_GROUPING_FAMILY.WHD_CLIENT}`
  if (expandedMode) url += '&expandedMode=true'
  if (institutionIds.length > 0 && organizationIds.length === 0) {
    try {
      const res = await getCompanytByEtablissementid(
        institutionIds,
        history,
        true,
        onlyClientOrganization,
        expandedMode
      )
      if (isCancel(res.entities)) {
        return
      }
      const data: OrganizationWDH[] = getMemberData(res.data)
      hierarchy = HierarchyNormalizer.normalize(data)
      hierarchy.map((org) => {
        return hierarchyTotal.push(org)
      })
    } catch (err) {
      if (isCancel(err)) {
        return err
      }
      console.error(err)
      history.push(ROUTES.TechnicalError)
    }
  } else {
    try {
      let startIndex = 0
      const maxIdsOrganizations =
        parseInt(`${process.env.REACT_APP_MAX_COMPANY_ID_LENGTH}`, 10) || 200

      while (startIndex <= organizationIds.length) {
        let hasMorePage = true
        const params = new URLSearchParams({})
        organizationIds
          .slice(startIndex, startIndex + maxIdsOrganizations)
          .forEach((org) => {
            params.append('idOrganisation[]', org)
          })

        while (hasMorePage) {
          const resp = await axios({
            method: 'GET',
            url,
            params,
            cancelToken: cancelToken?.token,
          })
          const data: OrganizationWDH[] = getMemberData(resp.data)
          hierarchy = HierarchyNormalizer.normalize(data)
          hierarchyTotal.push(...hierarchy)

          hasMorePage = !isLastPage(resp.data)
          if (hasMorePage)
            // fetch nextPage
            url = `${process.env.REACT_APP_BASE_API_WDH_URL}${getNextPage(
              resp.data
            )}`
        }
        startIndex += maxIdsOrganizations + 1
      }
    } catch (err) {
      if (isCancel(err)) {
        return err
      }
      history.push(ROUTES.TechnicalError)
      throw new Error(`Erreur lors de l'obtention du périmètre`)
    }
  }

  const feedLevel = async (
    organizationId: string,
    level: number,
    parentId: string
  ): Promise<CollapseDto | any> => {
    const organization = hierarchyTotal.find((org) => org.id === organizationId)
    if (!organization) {
      return
    }
    const {
      id,
      name,
      childrenOrganization,
      nbEtablissementEnfant,
      typeOrganisation,
    } = organization

    // TODO translation + refactor
    const subtitleOrganization =
      childrenOrganization.length <= 1 ? 'organisation' : 'organisations'

    const getChildren = async () => {
      return Promise.all(
        childrenOrganization.map((org) => feedLevel(org, level + 1, id))
      )
    }

    let children = await getChildren()
    children = children.filter((child) => {
      return child !== undefined
    })
    const subtitle = `${childrenOrganization.length} ${subtitleOrganization}`
    const node: CollapseDto = {
      id,
      parentId,
      type: 'collapse',
      title: name,
      subtitle,
      level,
      typeOrganisation,
    }

    if (children?.length > 0) {
      node.children = children
    } else if (autoSearchInstitution) {
      if (nbEtablissementEnfant !== 0) {
        let entity = null
        if (statut.length > 0) {
          entity = await getInstitutionByIdOrganization(
            organizationId,
            history,
            page,
            perPage,
            [],
            expandedMode
          )
        } else {
          entity = await searchInstitutionByOrgaAndInstitution(
            institutionIds,
            organizationId,
            history,
            page,
            perPage,
            expandedMode
          )
          if (
            (institutionIds.length > 0 && entity.totalItems === 0) ||
            withAllEtablissementInOrganization
          ) {
            entity = await searchInstitutionByOrgaAndInstitution(
              [],
              organizationId,
              history,
              page,
              perPage,
              expandedMode
            )
          }
        }

        if (isCancel(entity?.entities)) {
          return entity.entities
        }

        const subtitleLibelle =
          entity.entities.length <= 1 ? 'établissement' : 'établissements'
        node.subtitle += ` / ${entity.totalItems} ${subtitleLibelle}`
        node.children = CollapseTableNormalizer.normalize(entity.entities, id)
        node.totalItem = entity.totalItems
      } else {
        node.children = CollapseTableNormalizer.normalize([], id)
      }
    } else {
      if (institutionIds.length > 0 && !institutionCountUnlimited) {
        node.totalItem = institutionIds.length
      } else {
        node.totalItem = nbEtablissementEnfant
      }
      const subtitleLibelle =
        nbEtablissementEnfant <= 1 ? 'établissement' : 'établissements'
      node.subtitle += ` / ${nbEtablissementEnfant} ${subtitleLibelle}`
      node.children = [
        {
          id: `table--${id}`,
          parentId: id,
          type: 'table',
          typeOrganisation: '',
          data: [],
          dataIds: [],
          columnNames: ['name', 'address', 'siret'],
        },
      ]
    }
    return node
  }

  // CONSTRUCT HIERARCHY
  const level = 1
  const resultTab: CollapseDto[] = []
  for (let index = 0; index < hierarchyTotal?.length; index += 1) {
    const tmp = await feedLevel(
      hierarchyTotal?.[index].id,
      level,
      hierarchyTotal?.[index].id
    )
    if (isCancel(tmp)) {
      return tmp
    }
    if (tmp) {
      resultTab.push(tmp)
    }
  }
  const childids: string[] = []
  if (resultTab !== undefined) {
    resultTab.forEach((parent: CollapseDto) => {
      if (parent.children !== undefined) {
        parent.children.forEach((child: CollapseDto) => {
          childids.push(child.id)
        })
      }
    })
  }
  if (resultTab !== undefined)
    return resultTab.filter(function (el: { id: string }) {
      return !childids.includes(el.id)
    })
  return resultTab
}

export async function getPerimeterWithoutInstitutions(
  organizationIds: string[],
  history: any
): Promise<CollapseDto[] | any> {
  return getPerimeter(organizationIds, false, history)
}

export async function organizationHandlerSearchValidated(
  selectedItemIds: string[],
  history: any,
  expandedMode = false
): Promise<SearchFullResult> {
  try {
    // auto find institution by organization
    const { entities } = await searchInstitutionByOrganizationIds(
      selectedItemIds,
      history,
      expandedMode
    )
    return {
      organizations: [],
      linkedInstitutions: entities,
      totalItems: entities.length,
    }
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
}

export async function searchOrganizationByNameAndIds(
  organizationName: string,
  organizationIds: string[],
  history: any,
  maxTotalLimit = -1,
  disablePerimeter?: boolean,
  onlyClientOrganization?: boolean,
  expandedMode = false
): Promise<SearchResult> {
  try {
    const result = await searchOrganizationByName(
      organizationName,
      organizationIds,
      history,
      maxTotalLimit,
      disablePerimeter,
      onlyClientOrganization,
      expandedMode
    )
    return result
  } catch (e) {
    console.error(e)
    history.push(ROUTES.TechnicalError)
  }

  return {
    entities: [],
    totalItems: 0,
  }
}

export async function searchOrgaByInstitutionName(
  value: string,
  organizationIds: string[],
  history: any
): Promise<SearchResult> {
  try {
    const institutions = await searchInstitutionByTextAndOrganizationsIds(
      value,
      organizationIds,
      history
    )

    if (institutions) {
      const institutionIds = institutions.entities.map(
        (item: InstitutionEntity) => item.id
      )
      return getCompanytByEtablissementid(institutionIds, history, false)
    }
  } catch (err) {
    console.error(err)
    history.push(ROUTES.TechnicalError)
  }

  return {
    entities: [],
    totalItems: 0,
  }
}

/**
 * Formate Organizations fo userPatch
 * @param organisations
 */
export function getFormatedOrganisations(
  organisations: OrganizationEntity[]
): FormatedOrganization[] {
  const result: FormatedOrganization[] = []
  organisations.forEach((organisation) => {
    const entitie: FormatedOrganization = {
      type: '',
      organisationId: '',
      application: '',
    }
    entitie.type = organisation.type
    entitie.organisationId = organisation.id
    entitie.application = organisation.application
    result.push(entitie)
  })
  return result
}
export function getFormatedOrganisationToCreate(
  organisation: frontCreatedOrganization,
  idParent = -1
): CreatedOrganization {
  const entitie: CreatedOrganization = {
    idFonctionnelOrganisation: '',
    familleOrganisation: '',
    typeOrganisation: '',
    nomOrganisation: '',
    statut: '',
  }
  entitie.idFonctionnelOrganisation = organisation.id
  entitie.typeOrganisation =
    organisation.typeOrganisation === GROUPING_TYPE.BY_ORGANIZATION
      ? WDH_GROUPING_TYPE.WHD_BY_ORGANIZATION
      : WDH_GROUPING_TYPE.WHD_BY_INSTITUTION
  entitie.familleOrganisation =
    organisation.familleOrganisation === GROUPING_FAMILY.CLIENT
      ? WHD_GROUPING_FAMILY.WHD_CLIENT
      : WHD_GROUPING_FAMILY.WHD_INTERNAL
  entitie.nomOrganisation = organisation.title
  entitie.statut = ORGANIZATION_STATUT.STATUT_ENCREATION
  if (idParent !== -1) entitie.parentIdOrganisation = idParent
  else if (
    organisation.parentId &&
    organisation.parentId !== '' &&
    idParent === -1
  )
    entitie.parentIdOrganisation = parseInt(organisation.parentId, 10)
  else if (
    organisation.parentId &&
    organisation.parentId !== '' &&
    idParent !== -1
  )
    entitie.parentIdOrganisation = idParent
  return entitie
}
/* eslint-disable no-param-reassign */
export async function createOrganization(
  oraganization: CreatedOrganization,
  history: any,
  token?: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations`
  let status = -1
  // pour ne pas envoyer l'idFonctionnelOrganisation
  oraganization.idFonctionnelOrganisation = ''
  try {
    let body: any = { method: 'POST', url, data: oraganization }
    if (token) {
      body = {
        headers: {
          Authorization: token,
        },
        method: 'POST',
        url,
        data: oraganization,
      }
    }
    const resp = await axios(body)

    if (resp?.data.idOrganisation !== undefined) {
      status = resp?.data.idOrganisation
    }
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function createRootOrganization(
  oraganization: CreatedOrganization,
  userId: string,
  organizations: string[],
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}/organization`
  let status = -1
  const newOrganizations: string[] = []
  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        FamilleOrganisation: oraganization.familleOrganisation,
        TypeOrganisation: oraganization.typeOrganisation,
        NomOrganisation: oraganization.nomOrganisation,
        Statut: oraganization.statut,
      },
    })
    if (resp?.data.organizations !== undefined) {
      resp.data.organizations.forEach((element: { organisationId: string }) => {
        newOrganizations.push(element.organisationId)
      })
      status = parseInt(
        newOrganizations.filter((idorga) => !organizations.includes(idorga))[0],
        10
      )
    }
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

/**
 * Formate Etablishments for PUT in Organizaions as children
 * @param childrens string[]
 */
export function getFormatedEtablissementsAsChildren(
  childrens: string[]
): any[] {
  const result: string[] = []
  childrens.forEach((child) => {
    const entitie: any = {
      fkEntreprise: 0,
    }
    entitie.fkEntreprise = parseFloat(child)
    result.push(entitie)
  })
  return result
}
/**
 * Formate Organization for PUT in Organizaions as children
 * @param EnfantOrganization string
 */
export function getFormatedOrganizationAsChildren(
  EnfantOrganization: string
): any[] {
  const result: string[] = []
  const entitie: any = {
    fkOrganisationEnfant: 0,
  }
  entitie.fkOrganisationEnfant = parseFloat(EnfantOrganization)
  result.push(entitie)

  return result
}
export async function putOrganisationsEnfant(
  organisationsEnfant: any[],
  organizationId: string,
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${organizationId}`
  let status = 400

  try {
    const resp = await axios({
      method: 'PUT',
      url,
      data: {
        organisationsEnfant,
      },
    })
    status = resp?.status
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function putEtablissementsEnfant(
  etablissementsEnfant: string[],
  organizationId: string,
  history: any,
  token?: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${organizationId}`
  let status = 400
  try {
    let body: any = {
      method: 'PUT',
      url,
      data: {
        etablissementsEnfant,
      },
    }
    if (token) {
      body = {
        method: 'PUT',
        url,
        headers: {
          Authorization: token,
        },
        data: {
          etablissementsEnfant,
        },
      }
    }
    const resp = await axios(body)
    status = resp?.status
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function putStatutOrganisation(
  statut: string,
  organizationId: string,
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${organizationId}`
  let status = 400

  try {
    const resp = await axios({
      method: 'PUT',
      url,
      data: {
        statut,
      },
    })
    status = resp?.status
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function getPerimeterWithInstitutions(
  organisation: OrganizationEntity[],
  institution: InstitutionEntity[],
  history: any,
  statut: string[] | undefined = [],
  perPage?: number,
  page?: number
): Promise<CollapseDto[] | any> {
  const organisationIds: string[] = []
  const institutionIds: string[] = []
  let result: CollapseDto[] = []

  organisation.map((orga) => organisationIds.push(orga.id))
  institution.map((insti) => institutionIds.push(insti.id))

  try {
    const res = await getPerimeter(
      organisationIds,
      false,
      history,
      institutionIds,
      statut,
      false,
      undefined,
      perPage,
      page
    )
    if (isCancel(res)) {
      return res
    }
    if (res != null) {
      result = res
    }
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return result
}
// section for organization modify
export async function getFamilleOrganisation(
  OrganizationId: string,
  history: any
): Promise<string> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${OrganizationId}`
  try {
    const resp = await axios({
      method: 'GET',
      url,
    })

    const result = resp.data
    if (result) {
      return result.familleOrganisation
    }
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return Promise.resolve('')
}
// function to remove link with a child organization
export async function removeOrganizationLinkChild(
  organizationId: string,
  childId: string,
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${organizationId}/organisationsEnfant/${childId}`
  let status = 400
  try {
    const resp = await axios({
      method: 'DELETE',
      url,
    })

    status = resp?.status
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}
// function to remove link with a child company
export async function removeInstitutionLinkChild(
  organizationId: string,
  institutionId: string,
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${organizationId}/etablissementsEnfant/${institutionId}`
  let status = 400
  try {
    const resp = await axios({
      method: 'DELETE',
      url,
    })

    status = resp?.status
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}
// function to update the name of an organization
export async function updateOrganizationName(
  organizationId: string,
  nomOrganisation: string,
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations/${organizationId}`
  let status = 400
  try {
    const resp = await axios({
      method: 'PUT',
      url,
      data: {
        nomOrganisation,
      },
    })
    status = resp?.status
  } catch (err) {
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function getAllOrganisations(
  perPage: number,
  page: number,
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  sortTable: any,
  filters: any[],
  history: any,
  familleOrganisation: string | undefined
): Promise<{ results: OrganizationTableEntity[]; pageCount: number }> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations`

  let listOfOrganization: OrganizationTableEntity[] = []
  const params = new URLSearchParams()
  params.append(`page`, String(page))
  params.append(`perPage`, String(perPage))
  organisation.forEach((org) => {
    params.append(`idOrganisation[]`, org.id)
  })
  institution.forEach((insti) => {
    params.append(`idEntreprise[]`, insti.id)
  })
  if (sortTable) {
    Object.keys(sortTable).forEach((key: string) => {
      params.append(`sort[${labelsToApiFields[key]}]`, sortTable[key])
    })
  }
  const statusFilter = getFilterForId(filters, 'statut')
  if (statusFilter && statusFilter.value !== 'all') {
    params.append('statut[]', statusFilter.value)
  }
  const familleOrganisationFilter = getFilterForId(
    filters,
    'familleOrganisation'
  )
  if (familleOrganisationFilter && familleOrganisationFilter.value !== 'all') {
    params.append('familleOrganisation[]', familleOrganisationFilter.value)
  }
  if (familleOrganisation) {
    params.append('familleOrganisation[]', familleOrganisation)
  }

  const nomOrganisationFilter = getFilterForId(filters, 'nomOrganisation')
  if (nomOrganisationFilter) {
    params.append('nomOrganisation', nomOrganisationFilter)
  }
  if (getFilterForId(filters, 'dateModification')) {
    const [startDate, endDate] = getFilterForId(filters, 'dateModification')
    params.append(
      'dateModification[after]',
      moment(startDate).format('YYYY-MM-DD')
    )
    params.append(
      'dateModification[before]',
      moment(endDate).format('YYYY-MM-DD')
    )
  }
  if (getFilterForId(filters, 'dateCreation')) {
    const [startDate, endDate] = getFilterForId(filters, 'dateCreation')
    params.append('dateCreation[after]', moment(startDate).format('YYYY-MM-DD'))
    params.append('dateCreation[before]', moment(endDate).format('YYYY-MM-DD'))
  }

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })
    if (resp?.data) {
      // Result Normalization
      listOfOrganization = OrganizationNormalizer.normalizeForTable(
        getMemberData(resp.data)
      )
      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      // pageCount = Math.floor(totalItems / perPage) + 1
      return {
        results: [...listOfOrganization],
        pageCount: Math.ceil(totalItems / perPage),
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve({ pageCount: 0, results: err })
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return Promise.resolve({ pageCount: 0, results: [] })
}

// functions used for export
export async function getMyPerimeterOrganistaions(): Promise<SearchResult> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations`
  try {
    return searchOrganization(url)
  } catch (err) {
    return { entities: [], totalItems: 0 }
  }
}
export async function getOrgasByIds(
  organizationIds: string[]
): Promise<SearchResult> {
  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`
  organizationIds.forEach((id) => {
    url = url.concat(`&idOrganisation[]=${id}`)
  })
  try {
    return searchOrganization(url)
  } catch (err) {
    return { entities: [], totalItems: 0 }
  }
}

export async function getDocumentOrganisations(
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  history: any
): Promise<OrganizationDocumentEntity[]> {
  let url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisations?perPage=${process.env.REACT_APP_BASE_API_WDH_MAX_RESULT}`

  let listOfOrganization: OrganizationDocumentEntity[] = []
  const OrganizationTotal: OrganizationDocumentEntity[] = []
  const params = new URLSearchParams()
  organisation.forEach((org) => {
    params.append(`idOrganisation[]`, org.id)
  })
  institution.forEach((insti) => {
    params.append(`idEntreprise[]`, insti.id)
  })

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()
  try {
    let hasMorePage = true
    while (hasMorePage) {
      const resp = await axios({
        method: 'GET',
        url,
        params,
        cancelToken: cancelToken.token,
      })
      const data: OrganizationWDHFull[] = getMemberData(resp.data)
      listOfOrganization = OrganizationNormalizer.normalizeForDocument(data)
      listOfOrganization.map((org) => {
        return OrganizationTotal.push(org)
      })
      // fetch nextPage
      hasMorePage = !isLastPage(resp.data)
      url = `${process.env.REACT_APP_BASE_API_WDH_URL}${getNextPage(resp.data)}`
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve([])
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return OrganizationTotal
}

// Get agence_manag collection
export async function getAgManagerial(
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  history: any
): Promise<AgManagerialApiResults> {
  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/organisation_managerials`
  const params = new URLSearchParams()

  organisation.forEach((org) => {
    params.append(`idOrganisation[]`, org.id)
  })
  institution.forEach((insti) => {
    params.append(`idEntreprise[]`, insti.id)
  })

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()
  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })

    return {
      count: getTotalItems(resp?.data),
      data: AgManagerialNormalizer.normalize(getMemberData(resp?.data)),
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve([])
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
}
