/* eslint-disable class-methods-use-this */
import ToastMessageEntity from '../utils/ToastMessageEntity'
import sideMessageAPI from '../utils/SideMessageRaw'

class ToastMessagesNormalizer {
  normalize(toastMessages: sideMessageAPI[]): ToastMessageEntity[] {
    return toastMessages.map((message) => {
      return {
        id: message.id,
        messagetext: message.messagetext,
      }
    })
  }
}

export default new ToastMessagesNormalizer()
