import React from 'react'
import styled from 'styled-components'

const StyledBandeauContent = styled.div<Props>`
  display: flex;
  align-items: flex-start;
  height: auto;
  width: 100%;
  max-width: 1440px;
  background-color: ${(props) =>
    props.isImpersonnation ? '#ffc229' : '#030f40'};
  color: ${(props) => (props.isImpersonnation ? '#030f40' : '#ffffff')};
  font-family: 'DINPro-bold';
  font-size: 1.4rem;
  line-height: 2.3rem;
  padding: 12px 36px;

  @media screen and (max-width: 996px) {
    flex-direction: column;
    //TODO: to replace after migrate to styled component
    #organisationId {
      order: 2;
    }

    #compagnyId {
      order: 3;
    }
  }
`
interface Props {
  children: React.ReactNode
  isImpersonnation: boolean
}

const BandeauContent = ({ children, isImpersonnation }: Props) => {
  return (
    <StyledBandeauContent isImpersonnation={isImpersonnation}>
      {children}
    </StyledBandeauContent>
  )
}
export default BandeauContent
