import React from 'react'

interface Props {
  children: React.ReactNode
  disabled: boolean
  className?: string
  onclick?: () => void
}

function FormSubmit({ children, disabled, className, onclick }: Props) {
  return (
    <button
      className={`generic-button ${className}`}
      type="submit"
      disabled={disabled}
      onClick={onclick}
    >
      {children}
    </button>
  )
}

FormSubmit.defaultProps = {
  className: 'form__submit',
  onclick: () => null,
}

export default FormSubmit
