import React from 'react'
import { Field } from 'formik'

import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg'

interface Props {
  name: string
  placeholder?: string
  onChange?: (value: string) => void
  allowPast?: boolean
  disabled?: boolean
}

const FormInputPassword: React.FC<Props> = ({
  name,
  placeholder,
  onChange,
  allowPast,
  disabled,
}: Props) => {
  const [type, setType] = React.useState('password')
  const [password, setPassword] = React.useState('')
  const switchDisplaying = () => {
    if (type === 'password') {
      setType('text')
    } else {
      setType('password')
    }
  }
  const handleAllowPast = (e: React.FormEvent<HTMLInputElement>) => {
    return allowPast ? true : e.preventDefault()
  }

  return (
    <div className="form-input-password">
      <Field name="name">
        {
          // we have to notify Formik that field change

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ form }: any) => (
            <input
              name={name}
              type={type}
              className="form_input custom-input"
              placeholder={placeholder}
              autoComplete="off"
              value={password}
              onChange={(e) => {
                const { value } = e.currentTarget
                setPassword(value)
                if (onChange) {
                  form.setFieldValue(name, value)
                  onChange(value)
                }
              }}
              onPaste={handleAllowPast}
              disabled={disabled}
            />
          )
        }
      </Field>
      <button type="button" className="eye" onClick={switchDisplaying}>
        {type === 'password' ? <EyeSlashIcon /> : <EyeIcon />}
      </button>
    </div>
  )
}

FormInputPassword.defaultProps = {
  placeholder: '',
  onChange: () => {
    // just for the compilation
  },
  allowPast: true,
  disabled: false,
}

export default FormInputPassword
