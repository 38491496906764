import axios from 'axios'

export default async function getCheckMaintenance() {
  let maintenance = []
  try {
    const url = `${process.env.REACT_APP_BASE_API_URL}/check_maintenance`
    const resp = await axios({
      method: 'GET',
      url,
    })
    maintenance = resp?.data
  } catch (e) {
    // I want application to not crush, but don't care about the message
  }
  return maintenance
}

export async function checkAccessHP() {
  const passwordHP = `${process.env.REACT_APP_PASSWORD_HP}`
  const isAuth =
    localStorage.getItem('basic_auth') &&
    localStorage.getItem('basic_auth') === btoa(passwordHP)
  if (!isAuth) {
    let password: string | null = ''
    let success = false
    do {
      password = prompt('Entrez votre mot de passe :')
      if (password && password === passwordHP) {
        localStorage.setItem('basic_auth', btoa(password))
        success = true
      }
    } while (password === null || password === '' || !success)
  }
}
