import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BandeauWrapper from '../../atom/BandeauPerimetre/BandeauWrapper'
import BandeauContent from '../../atom/BandeauPerimetre/BandeauContent'
import BandeauTitle from '../../atom/BandeauPerimetre/BandeauTitle'
import BandeauReset from '../../atom/BandeauPerimetre/BandeauReset'
import BandeauControle from '../../atom/BandeauPerimetre/BandeauControle'
import PERMITER_SEARCH_MODE from '../../../utils/PERMITER_SEARCH_MODE'
import { AppContext } from '../../../store/context'
import { ActionTypes } from '../../../store/actions'

interface Props {
  children: React.ReactNode
  resetFilter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  filterResetClass?: string
  classe?: string
}
const BandeauPerimetre = ({
  children,
  resetFilter,
  filterResetClass,
  classe,
}: Props) => {
  const { t } = useTranslation()

  const {
    state: {
      impersonnation: { isImpersonnation },
    },
    dispatch,
  } = useContext(AppContext)

  const [resetHovered, setResetHovered] = useState(false)

  const handleResetHover = () => {
    setResetHovered(true)
  }

  const handleResetLeave = () => {
    setResetHovered(false)
  }

  const [searchMode, setSearchMode] = useState(
    PERMITER_SEARCH_MODE.SEARCH_DISABLED
  )

  const switchMode = () => {
    dispatch({
      type: ActionTypes.SWITCH_PERIMETER_FILTER_MODE,
      payload: null,
    })
    return searchMode === PERMITER_SEARCH_MODE.SEARCH_ENABLED
      ? setSearchMode(PERMITER_SEARCH_MODE.SEARCH_DISABLED)
      : setSearchMode(PERMITER_SEARCH_MODE.SEARCH_ENABLED)
  }

  return (
    <BandeauWrapper
      onClick={switchMode}
      resetHovered={resetHovered}
      isImpersonnation={isImpersonnation}
      mode={
        searchMode === PERMITER_SEARCH_MODE.SEARCH_DISABLED
          ? 'toggledOff'
          : 'toggledOn'
      }
      className={`perimeter-filter ${
        searchMode === PERMITER_SEARCH_MODE.SEARCH_DISABLED
          ? 'decreased-mode'
          : 'increased-mode'
      } ${classe}`}
    >
      <BandeauContent isImpersonnation={isImpersonnation}>
        <BandeauTitle>{t('perimeterfilter.label.title')}</BandeauTitle>
        {children}
        <BandeauReset
          resetFilter={resetFilter}
          filterResetClass={filterResetClass}
          handleResetLeave={handleResetLeave}
          handleResetHover={handleResetHover}
        />
      </BandeauContent>
      <BandeauControle
        title={
          searchMode === PERMITER_SEARCH_MODE.SEARCH_DISABLED
            ? t('perimeterfilter.label.increase')
            : t('perimeterfilter.label.decrease')
        }
        icon={
          searchMode === PERMITER_SEARCH_MODE.SEARCH_DISABLED ? 'plus' : 'minus'
        }
        mode={
          searchMode === PERMITER_SEARCH_MODE.SEARCH_DISABLED
            ? 'toggledOff'
            : 'toggledOn'
        }
        resetHovered={resetHovered}
        isImpersonnation={isImpersonnation}
      />
    </BandeauWrapper>
  )
}

BandeauPerimetre.defaultProps = {
  filterResetClass: '',
  classe: '',
}
export default BandeauPerimetre
