/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Field } from 'formik'

interface Props {
  name: string
  type: string
  classname?: string
  placeholder?: string
  disabled?: boolean
  allowPast?: boolean
  onKeyUp?: (e: React.FormEvent<HTMLFormElement>) => void | undefined
}

const FormInput: React.FC<Props> = ({
  name,
  type,
  placeholder,
  classname,
  disabled,
  allowPast,
  onKeyUp,
}: Props) => {
  const [readonly, setReadonly] = useState(true)

  const handleAllowPast = (e: any) => {
    return allowPast ? true : e.preventDefault()
  }

  return (
    <Field
      name={name}
      type={type}
      placeholder={placeholder}
      className={`form_input ${classname} ${
        ['text', 'password', 'tel', 'email', 'number'].includes(type)
          ? 'custom-input'
          : null
      }`}
      autoComplete="off"
      disabled={disabled}
      readOnly={readonly}
      onFocus={() => setReadonly(false)}
      onPaste={handleAllowPast}
      onKeyUp={onKeyUp}
    />
  )
}

FormInput.defaultProps = {
  placeholder: '',
  classname: '',
  disabled: false,
  allowPast: true,
  onKeyUp: undefined,
}

export default FormInput
