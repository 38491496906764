import React from 'react'

import { AppContext } from '../store/context'

interface Props {
  children: React.ReactNode
}
const Screen = ({ children }: Props) => {
  const { state } = React.useContext(AppContext)
  const {
    ui: { backgroundColor },
  } = state

  return (
    <div className="screen" style={{ backgroundColor }}>
      {children}
    </div>
  )
}

export default Screen
