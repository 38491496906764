/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState, useEffect, FC } from 'react'

const beforeIcon = <i className="sz-icon-line search" />
const afterIcon = <i className="sz-icon-line close" />

const FOCUS_ON_CLASS = 'input__focus--on'
const FOCUS_OFF_CLASS = 'input__focus--off'

interface Props {
  placeholder: string
  classname?: string
  forceReset?: boolean
  onChange: (value: string) => void
  resetSearchValue?: boolean
  disabled?: boolean
  onClick?: () => void
  dataTest?: string
}

const Input: FC<Props> = ({
  placeholder,
  classname,
  forceReset,
  onChange,
  resetSearchValue,
  disabled,
  onClick,
  dataTest,
}: Props) => {
  const [focusClass, setFocusClass] = useState(FOCUS_ON_CLASS)
  const [value, setValue] = useState('')
  const inputElt = useRef<HTMLInputElement>(null)

  const changeValue = (newValue: string) => {
    setValue(newValue)
    onChange(newValue)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    changeValue(e.currentTarget.value)
  }

  const reset = () => {
    changeValue('')

    if (inputElt?.current) {
      inputElt.current.focus()
    }
  }

  useEffect(() => {
    if (resetSearchValue) {
      setValue('')
      onChange('')
    }
  }, [resetSearchValue])

  useEffect(() => {
    reset()
  }, [forceReset])

  return (
    <div className={`input input__focus ${focusClass} ${classname}`}>
      <div className="input__before">{beforeIcon}</div>
      <input
        className={`input__field ${disabled ? 'input__disabled' : ''}`}
        type="text"
        value={value}
        placeholder={placeholder}
        ref={inputElt}
        disabled={disabled}
        onChange={handleChange}
        onFocus={() => setFocusClass(FOCUS_ON_CLASS)}
        onBlur={() => setFocusClass(FOCUS_OFF_CLASS)}
        onClick={onClick}
        data-test={dataTest}
      />
      <div className="input__after" onClick={reset}>
        {afterIcon}
      </div>
    </div>
  )
}
Input.defaultProps = {
  classname: '',
  forceReset: false,
  resetSearchValue: false,
  disabled: true,
  onClick: undefined,
  dataTest: '',
}

export default Input
