/* eslint-disable class-methods-use-this */
import SideMessageRaw from '../utils/SideMessageRaw'
import SideMessageEntity from '../utils/SideMessageEntity'

class MessageTableNormalizer {
  normalize(tableMessages: SideMessageRaw[]): SideMessageEntity[] {
    return tableMessages.map((item) => {
      return this.normalizeSingle(item)
    })
  }

  normalizeSingle(message: SideMessageRaw): SideMessageEntity {
    return {
      id: message.id,
      type: message.type,
      statut: message.statut,
      messagetext: message.messagetext,
      source: message.source ? message.source : '',
      createdAt: message.createdAt ? new Date(message.createdAt) : undefined,
      expireAt: message.expireAt ? new Date(message.expireAt) : undefined,
      compagnies: message.companies,
      users: message.users.map((element) => {
        return element.user
      }),
    }
  }
}

export default new MessageTableNormalizer()
