import React, { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ROUTES from '../../../utils/ROUTES'

const Italique = styled.span`
  font-style: italic;
`

const Link = styled.a`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: #77b7fc;
  }
`

const Item = styled.li`
  text-align: justify;
  margin-bottom: 0.5rem;
`

const Instructions: FC = () => {
  const { t } = useTranslation()
  return (
    <div>
      <br />
      <p>{t('instructionsNoMail.label')}</p>
      <ul>
        <Item>{t('instructionsNoMail.instruction1')}</Item>
        <Item>
          {t('instructionsNoMail.instruction2.label')}
          <Italique>
            {t('instructionsNoMail.instruction2.noReplyEmail')}
          </Italique>
        </Item>
        <Item>{t('instructionsNoMail.instruction3')}</Item>
        <Item>
          {t('instructionsNoMail.instruction4.startLabel')}
          <Link href={ROUTES.ContactUs}>
            {t('instructionsNoMail.instruction4.link')}
          </Link>
          {t('instructionsNoMail.instruction4.endLabel')}
        </Item>
      </ul>
    </div>
  )
}
Instructions.defaultProps = {
  children: null,
}
export default Instructions
