import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../store/context'
import MenuItem from './MenuItem'
import MenuSubItem from '../../utils/MenuSubItem'
import ROUTES from '../../utils/ROUTES'

export enum SIGNUP_STEP {
  STEP1_IDENTIFIER = 'step1',
  STEP2_CUSTOMER = 'step2',
  STEP3_INFOS = 'step3',
}

const SignUpSubMenu = () => {
  const { t } = useTranslation()
  const [menuItems, setMenuItems] = React.useState<MenuSubItem[]>([])

  const {
    state: { signUpUser },
  } = React.useContext(AppContext)

  React.useEffect(() => {
    const newMenuItems: MenuSubItem[] = [
      {
        title: t('subMenu.userSignUp.identifier'),
        link: `${ROUTES.UserSignUp}`,
        selected: signUpUser.actualStep === SIGNUP_STEP.STEP1_IDENTIFIER,
        disabled: signUpUser.actualStep === SIGNUP_STEP.STEP1_IDENTIFIER,
      },
      {
        title: t('subMenu.userSignUp.customer'),
        link: `${ROUTES.UserSignUpClient}`,
        selected: signUpUser.actualStep === SIGNUP_STEP.STEP2_CUSTOMER,
        disabled: true,
      },
      {
        title: t('subMenu.userSignUp.coordinates'),
        link: `${ROUTES.UserPerimeter}`,
        selected: signUpUser.actualStep === SIGNUP_STEP.STEP3_INFOS,
        disabled: true,
      },
    ]
    setMenuItems(newMenuItems)
  }, [signUpUser])

  const total = menuItems.length

  return (
    <>
      {menuItems?.map(({ title, link, selected, disabled }, index) => (
        <MenuItem
          disabled={disabled}
          key={title}
          title={
            <div className={`sub-menu__label ${disabled ? 'disabled' : ''}`}>
              <div>{`${index + 1} / ${total}`}</div>
              <div>{title}</div>
            </div>
          }
          link={link}
          className={`sub-menu__item ${selected} ${disabled ? 'disabled' : ''}`}
        />
      ))}
    </>
  )
}

export default SignUpSubMenu
