/* eslint-disable class-methods-use-this */
import {
  GROUPING_TYPE,
  WDH_GROUPING_TYPE,
} from '../utils/CreateOrganization-utils'
import HierarchyEntity from '../utils/HierarchyEntity'
import OrganizationWDH from '../utils/OrganizationWDH'

class HierarchyNormalizer {
  normalize(organizationList: OrganizationWDH[]): HierarchyEntity[] {
    return organizationList.map(
      ({
        idOrganisation,
        nomOrganisation,
        organisationsEnfant,
        nbEtablissements,
        typeOrganisation,
      }) => {
        return {
          id: String(idOrganisation),
          name: nomOrganisation,
          childrenOrganization: organisationsEnfant.map(
            ({ fkorganisationenfant }) =>
              fkorganisationenfant.replace('/organisations/', '')
          ),
          nbEtablissementEnfant: nbEtablissements,
          typeOrganisation:
            typeOrganisation === WDH_GROUPING_TYPE.WHD_BY_ORGANIZATION
              ? GROUPING_TYPE.BY_ORGANIZATION
              : GROUPING_TYPE.BY_INSTITUTION,
        }
      }
    )
  }
}

export default new HierarchyNormalizer()
