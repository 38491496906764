import React from 'react'
import { ErrorMessage } from 'formik'

interface Props {
  name: string
}

const FormInputErrorMessage: React.FC<Props> = ({ name }: Props) => (
  <div className="form_input--error-msg">
    &nbsp;
    <ErrorMessage name={name} />
  </div>
)

export default FormInputErrorMessage
