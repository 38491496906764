/* eslint-disable array-callback-return */
import { ORGANIZATION_STATUT } from './CreateOrganization-utils'
import {
  ChildOrganisationWithState,
  OrganizationEnfant,
  OrganizationTableEntity,
} from './OrganizationEntity'

export function extractOrganizationEnfantId(
  listOrganizationEnfant: OrganizationEnfant[]
): string[] {
  const organizationEnfantIds: string[] = []
  listOrganizationEnfant.map((organizationEnfant) => {
    const result = organizationEnfant.fkorganisationenfant.match(
      /\/organisations\/(.*)/
    )
    if (result) organizationEnfantIds.push(result[1])
    return result
  })
  return organizationEnfantIds
}

export function extractOrganizationEnfantWithIdAndState(
  listOrganizationEnfant: OrganizationEnfant[]
): ChildOrganisationWithState[] {
  const organizationEnfants: ChildOrganisationWithState[] = []
  listOrganizationEnfant.map((organizationEnfant) => {
    const id = organizationEnfant.fkorganisationenfant.match(
      /\/organisations\/(.*)/
    )
    const { statut } = organizationEnfant
    if (id)
      organizationEnfants.push({
        id: id.toString(),
        state: statut,
      })
  })
  return organizationEnfants
}

/**
 * Checks the status of every entities and their children to determine whether this entity can be deactivated or not (if status is STATUT_INACTIF we can not deactivate the organization)
 */
export function canBeDeactivated(
  listOfOrganization: OrganizationTableEntity[],
  idOrganization: string
): boolean {
  let result = true
  const currentOrganizationChildrenIds = listOfOrganization
    .filter((Orga) => {
      return Orga.id === idOrganization
    })
    .map((item) => {
      return item.organisationsEnfant
    })[0]

  if (!currentOrganizationChildrenIds) {
    return result
  }

  currentOrganizationChildrenIds.forEach((organisation) => {
    result =
      organisation.state === ORGANIZATION_STATUT.STATUT_INACTIF
        ? result
        : result && false
  })
  currentOrganizationChildrenIds.forEach((orga) => {
    const recResult = canBeDeactivated(listOfOrganization, orga.id)
    result = result && recResult
  })
  return result
}
