/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useEffect,
  FC,
  ReactNode,
  useRef,
  useContext,
} from 'react'

import SearchWithList from './SearchWithList'
import SearchResultItem from '../../utils/SearchResultItem'
import SvgIcon from '../Icons/SvgIcon'
import PERMITER_SEARCH_MODE from '../../utils/PERMITER_SEARCH_MODE'
import { AppContext } from '../../store/context'

interface Props {
  placeholder: ReactNode
  selectedPlaceholderSingle: ReactNode
  selectedPlaceholderMulti: ReactNode
  parentWidth: number
  countLabel: string
  searchResults: SearchResultItem[] | undefined
  initialValues?: SearchResultItem[]
  count: number
  isLoading: boolean
  resetSearchValue?: boolean
  displaySelected?: boolean
  forceReset?: boolean
  forceUnfold?: boolean
  isOrga?: boolean
  onSearchChanged: (value: string) => void
  onSearchValidated: (selectedItemIds: string[]) => void
  isCollapsedSearch?: boolean
  onHandlerCollapsedSearch?: (SearchValue: string) => void
  onHandleUnSelectedItems?: (value: string) => void
  selectedItemsProp?: SearchResultItem[]
  disabledSearch?: boolean
  isBandeau?: boolean
  dataTest?: string
  handleItemsSelected?: (item: any) => void
  handleItemsUnselected?: (item: any) => void
  itemsSelected?: any
  organizationSetted?: boolean
}

const SmartSelect: FC<Props> = ({
  placeholder,
  selectedPlaceholderSingle,
  selectedPlaceholderMulti,
  parentWidth,
  countLabel,
  searchResults,
  initialValues,
  count,
  isLoading,
  resetSearchValue,
  displaySelected,
  forceReset,
  forceUnfold,
  isOrga,
  onSearchChanged,
  onSearchValidated,
  isCollapsedSearch,
  onHandlerCollapsedSearch,
  onHandleUnSelectedItems,
  selectedItemsProp,
  disabledSearch,
  isBandeau,
  dataTest,
  handleItemsSelected,
  handleItemsUnselected,
  itemsSelected,
  organizationSetted,
}: Props) => {
  // STATE
  const {
    state: { organization, institution },
  } = useContext(AppContext)
  const [searchMode, setSearchMode] = useState(
    PERMITER_SEARCH_MODE.SEARCH_DISABLED
  )
  const [selectedItems, setSelectedItems] = React.useState<SearchResultItem[]>(
    []
  )

  // COMPUTED
  const searchWidth =
    parentWidth && parentWidth !== 0 ? `${parentWidth}px` : '100%'
  const selectedPlaceholder =
    selectedItems.length > 1
      ? `${selectedItems.length} ${selectedPlaceholderMulti}`
      : `${selectedItems.length} ${selectedPlaceholderSingle}`

  // METHODS
  const switchMode = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e) e.stopPropagation() // To let PERIMETER FILTER (bandeau de périmètre) open
    if (searchMode === PERMITER_SEARCH_MODE.SEARCH_ENABLED) {
      setSearchMode(PERMITER_SEARCH_MODE.SEARCH_DISABLED)
    } else {
      setSearchMode(PERMITER_SEARCH_MODE.SEARCH_ENABLED)
    }
  }
  const handleSearchChanged = (value: string) => {
    onSearchChanged(value)
  }
  const HandlerCollapsedSearch = (value: string) => {
    if (onHandlerCollapsedSearch) onHandlerCollapsedSearch(value)
  }
  const handleSearchValidated = (selectedItemIds: string[]) => {
    onSearchValidated(selectedItemIds)
    switchMode()
  }
  const handleItemSelected = (toAddId: string) => {
    const toAddItem = searchResults?.find((item) => toAddId === item.id)
    if (toAddItem) {
      if (handleItemsSelected) {
        handleItemsSelected(toAddItem)
      }
    }
  }
  useEffect(() => {
    if (initialValues && !isOrga) {
      const listToRemove = selectedItems
        ?.flatMap((item) => item.id)
        .filter(
          (id: string) => !initialValues.flatMap((itm) => itm.id).includes(id)
        )
      setSelectedItems(
        selectedItems.filter((item) => !listToRemove.includes(item.id))
      )
    }
  }, [initialValues])

  useEffect(() => {
    if (selectedItemsProp && selectedItemsProp.length > 0) {
      setSelectedItems(selectedItemsProp)
    }
  }, [selectedItemsProp])

  const handleItemUnSelected = (toDeleteId: string) => {
    const newSelectedItems = selectedItems.filter(
      (item) => toDeleteId !== item.id
    )
    if (onHandleUnSelectedItems) onHandleUnSelectedItems(toDeleteId)
    setSelectedItems([...newSelectedItems])
    if (handleItemsUnselected) {
      handleItemsUnselected(toDeleteId)
    }
  }

  // EFFECTS
  useEffect(() => {
    setSearchMode(PERMITER_SEARCH_MODE.SEARCH_DISABLED)
    handleSearchChanged('')
    HandlerCollapsedSearch('')
  }, [forceReset])

  useEffect(() => {
    if (!isOrga) {
      const newSelectedItemIds: SearchResultItem[] = []
      const tmp = JSON.stringify(institution.items)
      selectedItems.map((sel: SearchResultItem) => {
        if (tmp.includes(sel.id)) {
          newSelectedItemIds.push(sel)
        }
      })

      setSelectedItems([...newSelectedItemIds])
    }
  }, [institution])

  useEffect(() => {
    if (forceUnfold && searchMode === PERMITER_SEARCH_MODE.SEARCH_DISABLED) {
      setSearchMode(PERMITER_SEARCH_MODE.SEARCH_ENABLED)
    }
  }, [forceUnfold])

  useEffect(() => {
    if (organization.items.length === 0) {
      setSelectedItems([])
    }
  }, [organization])

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current !== null) {
        if (!wrapperRef.current.contains(event.target as Node)) {
          setSearchMode(PERMITER_SEARCH_MODE.SEARCH_DISABLED)
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])
  useEffect(() => {
    if (itemsSelected) {
      setSelectedItems(itemsSelected)
    }
  }, [itemsSelected])

  return (
    <div className={`smart-select ${searchMode}`} ref={wrapperRef}>
      {isBandeau ? (
        <div style={{ width: searchWidth }} onClick={switchMode}>
          <SearchWithList
            isOrga={isOrga}
            isCollapsedSearch={isCollapsedSearch}
            onHandlerCollapsedSearch={HandlerCollapsedSearch}
            items={searchResults}
            selectedItems={selectedItems}
            count={count}
            countLabel={countLabel}
            selectedPlaceholder={selectedPlaceholder}
            isLoading={isLoading}
            displaySelected={!!displaySelected}
            forceReset={forceReset}
            resetSearchValue={resetSearchValue}
            onSearchChanged={handleSearchChanged}
            onSearchValidated={handleSearchValidated}
            onItemSelected={handleItemSelected}
            onItemUnSelected={handleItemUnSelected}
            disabledSearch={disabledSearch}
            isBandeau={isBandeau}
            dataTest={dataTest}
            organizationSetted={organizationSetted}
          />
        </div>
      ) : (
        <div className={`smart-select ${searchMode}`} ref={wrapperRef}>
          <div className="smart-select__select" onClick={switchMode}>
            <span className="smart-select__select-label">
              {selectedItems.length > 0 ? selectedPlaceholder : placeholder}
            </span>
            <span className="smart-select__select-arrow">
              <SvgIcon
                name="arrowDown"
                className="smart-select__select-arrow smart-select__select-arrow--down"
              />
              <SvgIcon
                name="arrowUp"
                className="smart-select__select-arrow smart-select__select-arrow--up"
              />
            </span>
          </div>
          {displaySelected ? (
            <div
              className="smart-select__search"
              style={{ width: searchWidth }}
            >
              <SearchWithList
                isOrga={isOrga}
                isCollapsedSearch={isCollapsedSearch}
                onHandlerCollapsedSearch={HandlerCollapsedSearch}
                items={searchResults}
                selectedItems={selectedItems}
                count={count}
                countLabel={countLabel}
                selectedPlaceholder={selectedPlaceholder}
                isLoading={isLoading}
                displaySelected={!!displaySelected}
                forceReset={forceReset}
                resetSearchValue={resetSearchValue}
                onSearchChanged={handleSearchChanged}
                onSearchValidated={handleSearchValidated}
                onItemSelected={handleItemSelected}
                onItemUnSelected={handleItemUnSelected}
                disabledSearch={disabledSearch}
                dataTest={dataTest}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  )
}

SmartSelect.defaultProps = {
  displaySelected: true,
  forceReset: false,
  forceUnfold: false,
  isCollapsedSearch: false,
  resetSearchValue: false,
  isOrga: false,
  onHandlerCollapsedSearch: undefined,
  onHandleUnSelectedItems: undefined,
  initialValues: undefined,
  selectedItemsProp: undefined,
  disabledSearch: false,
  isBandeau: false,
  dataTest: '',
  handleItemsSelected: undefined,
  handleItemsUnselected: undefined,
  itemsSelected: undefined,
  organizationSetted: false,
}

export default SmartSelect
