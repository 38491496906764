import React from 'react'

interface Props {
  children: React.ReactNode
  type?: string
}

export const LAYOUT_CENTER = 'CENTER'
export const LAYOUT_NO_CENTER = 'NO_CENTER'

const ScreenContainerWithBackground = ({ children, type }: Props) => {
  return (
    <div
      className={`${
        type === LAYOUT_CENTER
          ? 'screen-container__wrapper-with-cover-bg__center'
          : 'screen-container__wrapper-with-cover-bg__child'
      }`}
    >
      <div className="screen-container__children">{children}</div>
    </div>
  )
}

ScreenContainerWithBackground.defaultProps = {
  type: LAYOUT_NO_CENTER,
}

export default ScreenContainerWithBackground
