/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelTokenSource } from 'axios'
import BillingSynthesisNormalizer from '../normalizer/BillingSynthesisNormalizer'
import {
  BillsAndCreditsByServiceEntity,
  BillsAndCreditsEntity,
} from '../utils/BillSynthesisEntity'
import { getMemberData } from './hydra-services'
import { getFirstMonthAndYear } from '../utils/date-utils'
import { distinctArray } from '../utils/array-utils'
import { Dataset } from '../utils/Graphs-utils'
import { MATERIALS_SERVICES_COLORS } from '../utils/COLORS'
import InstitutionEntity from '../utils/InstitutionEntity'
import BillingSynthesisNormalizerByService from '../normalizer/BillingSynthesisNormalizerByService'
import OrganizationEntity from '../utils/OrganizationEntity'
import { isCancel } from './billing-services'
import { ActivitySynthesisEntity } from '../utils/ActivitySynthesisEntity'
import { SYN_ACTIVITY_TYPE } from './activity-synthesis-services'

export interface famillydata {
  type: string
  typeLabel: string
  yearData: number[]
}

export interface chartData {
  label: string
  backgroundColor: string
  data: number[]
}
export interface donugtData {
  label: string
  backgroundColor: string[]
  data: number[]
}

export interface fullDonutData {
  chart: donugtData
  labels: string[]
}

/**
 * Synthèse de Facturation
 */

export enum BILLING_SYNTHESIS {
  FAMILLE_DECHETS_FACTURES = 'FAMILLE_DECHETS_FACTURES',
  FAMILLE_DECHETS_AVOIRS = 'FAMILLE_DECHETS_AVOIRS',
  FAMILLE_DECHETS_RACHETES = 'FAMILLE_DECHETS_RACHETES',
  SERVICES_FACTURES = 'SERVICES_FACTURES',
  SERVICES_AVOIRS = 'SERVICES_AVOIRS',
}

interface BillingSynthesisTypesItem {
  name: BILLING_SYNTHESIS
  url: string
  cancelToken: CancelTokenSource
  Normalizer:
    | typeof BillingSynthesisNormalizer
    | typeof BillingSynthesisNormalizerByService
}

interface BillingSynthesisTypes {
  FAMILLE_DECHETS_FACTURES: BillingSynthesisTypesItem
  FAMILLE_DECHETS_AVOIRS: BillingSynthesisTypesItem
  FAMILLE_DECHETS_RACHETES: BillingSynthesisTypesItem
  SERVICES_FACTURES: BillingSynthesisTypesItem
  SERVICES_AVOIRS: BillingSynthesisTypesItem
}

const BillingSynthesisTypes: BillingSynthesisTypes = {
  FAMILLE_DECHETS_FACTURES: {
    name: BILLING_SYNTHESIS.FAMILLE_DECHETS_FACTURES,
    url: '/kpi-fac-synt-fac?regroupement[]=mois&regroupement[]=fkfamilledechet',
    cancelToken: axios.CancelToken.source(),
    Normalizer: BillingSynthesisNormalizer,
  },
  FAMILLE_DECHETS_AVOIRS: {
    name: BILLING_SYNTHESIS.FAMILLE_DECHETS_AVOIRS,
    url: '/kpi-fac-synt-avo?regroupement[]=mois&regroupement[]=fkfamilledechet',
    cancelToken: axios.CancelToken.source(),
    Normalizer: BillingSynthesisNormalizer,
  },
  FAMILLE_DECHETS_RACHETES: {
    name: BILLING_SYNTHESIS.FAMILLE_DECHETS_RACHETES,
    url: '/kpi-fac-synt-brm?regroupement[]=mois&regroupement[]=fkfamilledechet',
    cancelToken: axios.CancelToken.source(),
    Normalizer: BillingSynthesisNormalizer,
  },
  SERVICES_FACTURES: {
    name: BILLING_SYNTHESIS.SERVICES_FACTURES,
    url:
      '/kpi-fac-synt-fac?regroupement[]=mois&regroupement[]=fkfamilleservice',
    cancelToken: axios.CancelToken.source(),
    Normalizer: BillingSynthesisNormalizerByService,
  },
  SERVICES_AVOIRS: {
    name: BILLING_SYNTHESIS.SERVICES_AVOIRS,
    url:
      '/kpi-fac-synt-avo?regroupement[]=mois&regroupement[]=fkfamilleservice',
    cancelToken: axios.CancelToken.source(),
    Normalizer: BillingSynthesisNormalizerByService,
  },
}

interface BillingSynthesisResponse {
  data: BillsAndCreditsEntity[] | BillsAndCreditsByServiceEntity[]
  isCancled: boolean
}

export default async function getBillingSynthesisData(
  type: BILLING_SYNTHESIS,
  year: number,
  institution: InstitutionEntity[] = [],
  organization: OrganizationEntity[] = []
): Promise<BillingSynthesisResponse> {
  const response: BillingSynthesisResponse = {
    data: [],
    isCancled: false,
  }
  // preparing api call params
  const periodeDebut = getFirstMonthAndYear(year.toString())
  const periodeFin = `${year}-12`

  const url = `${process.env.REACT_APP_BASE_API_WDH_URL}/indicateurs${BillingSynthesisTypes[type].url}`

  const params = new URLSearchParams({
    periodeDebut,
    periodeFin,
  })
  if (organization.length) {
    organization.forEach((org: OrganizationEntity) => {
      params.append('idOrganisation[]', org.id)
    })
  }
  institution.forEach((insti) => {
    params.append(`fkentreprisefacturee[]`, insti.id)
  })

  if (BillingSynthesisTypes[type].cancelToken !== undefined) {
    BillingSynthesisTypes[type].cancelToken.cancel(
      'Operation canceled due to new request.'
    )
  }
  BillingSynthesisTypes[type].cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      url,
      params,
      cancelToken: BillingSynthesisTypes[type].cancelToken.token,
    })

    response.data = BillingSynthesisTypes[type].Normalizer.normalize(
      getMemberData(resp.data)
    )
    return Promise.resolve(response)
  } catch (err) {
    if (isCancel(err)) {
      response.isCancled = true
    }
    console.error(err)
  }
  return Promise.resolve(response)
}

export function getTotalAmount(array: any) {
  return array.reduce((a: number, b: any) => a + b.amount, 0)
}

export function getTotal(array: any) {
  return array.reduce((a: number, b: any) => a + b, 0)
}

export function extractFamillies(data: BillsAndCreditsEntity[]) {
  const wasteFamillies = data.map((item) => {
    return item.familleDechetCode
  })
  return distinctArray(wasteFamillies)
}

export function extractLibelleFamillies(data: BillsAndCreditsEntity[]) {
  const wasteFamillies = data.map((item) => {
    return item.familleDechetLibelle
  })
  return distinctArray(wasteFamillies)
}
export function extractServices(data: BillsAndCreditsByServiceEntity[]) {
  const wasteFamillies = data.map((item) => {
    return item.familleServiceCode
  })
  return distinctArray(wasteFamillies)
}
export function extractLibelleServices(data: BillsAndCreditsByServiceEntity[]) {
  const wasteFamillies = data.map((item) => {
    return item.familleServiceLibelle
  })
  return distinctArray(wasteFamillies)
}
export function extractTreated(data: ActivitySynthesisEntity[]) {
  const wasteFamillies = data.map((item) => {
    return item.familleTraitementCode
  })
  return distinctArray(wasteFamillies)
}
// will be used to get id from familleDechetCode when clicking in show détails
export function extractMapIdsCodeFamillies(data: BillsAndCreditsEntity[]) {
  const wasteFamillies = data.map((item) => {
    return { code: item.familleDechetCode, id: item.fkfamilledechet }
  })
  return wasteFamillies.filter(
    (v, i, a) => a.findIndex((t) => t.code === v.code) === i
  )
}

// will be used to fill the options
export function extractFamilliesForOptions(data: BillsAndCreditsEntity[]) {
  const wasteFamillies = data.map((item) => {
    return { value: item.familleDechetCode, label: item.familleDechetLibelle }
  })
  return wasteFamillies.filter(
    (v, i, a) => a.findIndex((t) => t.value === v.value) === i
  )
}

export function extractFamilliesCreditForOptions(
  data: BillsAndCreditsEntity[]
) {
  const wasteFamillies = data.map((item) => {
    return {
      value: item.familleDechetCode,
      label: `${item.familleDechetLibelle}`,
    }
  })
  return wasteFamillies.filter(
    (v, i, a) => a.findIndex((t) => t.value === v.value) === i
  )
}

export function extractFamilliesTreatedForOptions(
  data: ActivitySynthesisEntity[]
) {
  const wasteFamillies = data.map((item) => {
    return {
      value: item.familleTraitementCode,
      label: item.familleTraitementLibelle,
    }
  })
  return wasteFamillies.filter(
    (v, i, a) => a.findIndex((t) => t.value === v.value) === i
  )
}

export function extractServicesForOptions(
  data: BillsAndCreditsByServiceEntity[]
) {
  const wasteFamillies = data.map((item) => {
    return { value: item.familleServiceCode, label: item.familleServiceLibelle }
  })
  return wasteFamillies.filter(
    (v, i, a) => a.findIndex((t) => t.value === v.value) === i
  )
}

export function extractServicesCreditForOptions(
  data: BillsAndCreditsByServiceEntity[]
) {
  const wasteFamillies = data.map((item) => {
    return {
      value: item.familleServiceCode,
      label: `${item.familleServiceLibelle}`,
    }
  })
  return wasteFamillies.filter(
    (v, i, a) => a.findIndex((t) => t.value === v.value) === i
  )
}

export function formatDataByNoFamily(
  data: ActivitySynthesisEntity[],
  type: string
): famillydata[] {
  const result: famillydata[] = []
  const item: famillydata = {
    type: '',
    yearData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    typeLabel: '',
  }
  if (type === SYN_ACTIVITY_TYPE.PASSAGES) {
    data.forEach((value) => {
      item.yearData[value.mois - 1] = value.amount
    })
  } else if (type === SYN_ACTIVITY_TYPE.TRI) {
    data.forEach((value) => {
      if (value.denominateur) {
        item.yearData[value.mois - 1] =
          (value.amount / value.denominateur) * 100
      }
    })
  }
  result.push(item)
  return result
}

export function formatDataByFamillyTreated(
  data: ActivitySynthesisEntity[]
): famillydata[] {
  const famillies = extractTreated(data)
  const result: famillydata[] = []
  famillies.forEach((familly) => {
    const item: famillydata = {
      type: familly,
      yearData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      typeLabel: '',
    }
    data.forEach((value) => {
      if (value.familleTraitementCode === familly) {
        item.yearData[value.mois - 1] += value.amount
        item.typeLabel = value.familleTraitementLibelle
      }
    })
    result.push(item)
  })
  return result
}

export function formatDataByFamilly(
  data: BillsAndCreditsEntity[]
): famillydata[] {
  const famillies = extractFamillies(data)
  const result: famillydata[] = []
  famillies.forEach((familly) => {
    const item: famillydata = {
      type: familly,
      yearData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      typeLabel: '',
    }
    data.forEach((value) => {
      if (value.familleDechetCode === familly) {
        item.yearData[value.mois - 1] = value.amount
        item.typeLabel = value.familleDechetLibelle
      }
    })
    result.push(item)
  })
  return result
}

export function formatDataCreditByFamilly(
  data: BillsAndCreditsEntity[]
): famillydata[] {
  const famillies = extractFamillies(data)
  const result: famillydata[] = []
  famillies.forEach((familly) => {
    const item: famillydata = {
      type: familly,
      yearData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      typeLabel: '',
    }
    data.forEach((value) => {
      if (value.familleDechetCode === familly) {
        item.yearData[value.mois - 1] = value.amount
        item.typeLabel = `${value.familleDechetLibelle} (Avoirs)`
      }
    })
    result.push(item)
  })
  return result
}

export function formatDataForLineChart(
  data: BillsAndCreditsEntity[],
  year: number,
  backgroundColor: string,
  borderColor: string
): Dataset {
  const item: Dataset = {
    label: String(year),
    backgroundColor,
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    borderColor,
  }
  data.forEach((value) => {
    if (value.annee === year) {
      item.data[value.mois - 1] = value.amount
    }
  })
  return item
}
export function formatCreditDataForLineChart(
  data: BillsAndCreditsEntity[],
  year: number
): number[] {
  const item = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  data.forEach((value) => {
    if (value.annee === year) {
      item[value.mois - 1] = value.amount
    }
  })
  return item
}

export function formatDataByService(
  data: BillsAndCreditsByServiceEntity[]
): famillydata[] {
  const famillies = extractServices(data)
  const result: famillydata[] = []
  famillies.forEach((familly) => {
    const item: famillydata = {
      type: familly,
      yearData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      typeLabel: '',
    }
    data.forEach((value) => {
      if (value.familleServiceCode === familly) {
        item.yearData[value.mois - 1] = value.amount
        item.typeLabel = value.familleServiceLibelle
      }
    })
    result.push(item)
  })
  return result
}

export function formatDataCreditByService(
  data: BillsAndCreditsByServiceEntity[]
): famillydata[] {
  const famillies = extractServices(data)
  const result: famillydata[] = []
  famillies.forEach((familly) => {
    const item: famillydata = {
      type: familly,
      yearData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      typeLabel: '',
    }
    data.forEach((value) => {
      if (value.familleServiceCode === familly) {
        item.yearData[value.mois - 1] = value.amount
        item.typeLabel = `${value.familleServiceLibelle} (Avoirs)`
      }
    })
    result.push(item)
  })
  return result
}

export function addCreditDataToLineChart(
  dataFact: number[],
  dataCredit: number[]
): number[] {
  let item = []
  item = dataFact.map((value, i) => value + dataCredit[i])
  return item
}

export function prepareDataforchart(
  data: famillydata[],
  famillies: string[]
): chartData[] {
  return data
    .filter((value) => {
      return famillies.includes(value.type)
    })
    .map((item) => {
      return {
        label: item.typeLabel,
        backgroundColor: MATERIALS_SERVICES_COLORS[item.type],
        data: item.yearData,
      }
    })
}

export function prepareDataforchartWithNoFamily(
  data: famillydata[]
): chartData[] {
  return data.map((item) => {
    return {
      label: item.typeLabel,
      backgroundColor: MATERIALS_SERVICES_COLORS[item.type],
      data: item.yearData,
    }
  })
}
