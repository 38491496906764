/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'
import COLORS from '../utils/COLORS'
import { Dataset } from '../utils/Graphs-utils'
import KPIConnexionRaw, { KPIConnexion } from '../utils/KPIConnexionRaw'
import KPIDownloadDocAPI, {
  KPIDownloadDocTableEntity,
} from '../utils/KPIDownloadDocEntity'
import formatKPIDemandesForExport from '../utils/KPIDemands'
import { KPIDemandsAPI } from '../utils/interfaces/SuperAdministration/KPI'
import { getLastXMonths } from '../utils/date-utils'
import sendFileToClient from '../utils/file-utils'
import { isCancel } from './billing-services'
import { getMemberData } from './hydra-services'

let cancelToken: CancelTokenSource
const XlsxTemplate = require('xlsx-template')

const allDocTypes = [
  'FACTURE',
  'AVOIR',
  'BRM',
  'BI',
  'BP',
  'ATTESTATION_VALO',
  'AUTRES',
]

export async function getKpiUserConnexion(
  startDate: string,
  endDate: string
): Promise<KPIConnexionRaw[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/kpi_users_connexions?connexionAt[after]=${startDate}&connexionAt[before]=${endDate}&perPage=1000`

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }
  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      url,
    })
    const data = getMemberData(resp.data)
    return Promise.resolve(data)
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }
    console.error(err)
  }
  return Promise.resolve([])
}

export function formatDataForExport(data: KPIConnexionRaw[]): KPIConnexion[] {
  const startDate = moment().subtract(12, 'M')
  const endDate = moment()
  const exportedData: KPIConnexion[] = []

  while (startDate <= endDate) {
    const searchRow = data.find(
      (e) => e.connexionAt === endDate.format('YYYY-MM-DD')
    )
    exportedData.push({
      id: endDate.format('YYYY-MM-DD'),
      connexionAt: endDate.toDate(),
      numberConnexion: searchRow !== undefined ? searchRow.numberConnexion : 0,
      dayOfWeek: endDate.locale('fr').format('dddd'),
    })

    endDate.add(-1, 'd')
  }

  return exportedData
}

export function generateNbrConnexionExcel(data: KPIConnexion[]) {
  fetch('/resources/KpiNombreConnexions.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)
      const values = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        exportedConnexion: data,
      }
      template.substitute(1, values)
      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `kpi_nombre_connexions_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}

export async function getDemandsExport(): Promise<KPIDemandsAPI[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/v_kpi_demands_motif`

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }
  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    return Promise.resolve(resp.data)
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }
  }
  return Promise.resolve([])
}

export const generateKpiDemandsExcel = (data: KPIDemandsAPI[]) => {
  const monthExportLabels: string[] = getLastXMonths(14, 'MMMM-YYYY')
  const formatedData = formatKPIDemandesForExport(monthExportLabels, data)

  fetch('/resources/KpiDemandes.xlsx').then((resp) => {
    resp.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)

      const exportValues = {
        dateColumns: monthExportLabels,
        kpiData: formatedData,
      }

      template.substitute(1, exportValues)
      const fileData = template.generate({ type: 'arraybuffer' })
      const fileName = 'kpi_demandes'
      const fileDate = moment(new Date()).format('YYYY_MM_DD')
      sendFileToClient(fileData, fileName, fileDate)
    })
  })
}

export function formatDataForLineChart(
  data: KPIConnexionRaw[]
): { labels: string[]; datasets: Dataset[] } {
  let index = 27
  const currentLabels: string[] = []
  const previewLabels: string[] = []
  const labels: string[] = []

  while (index > -1) {
    const currentDay = moment().subtract(index, 'd')
    const previewDay = moment().subtract(index + 28, 'd')
    currentLabels.push(currentDay.format('YYYY-MM-DD'))
    previewLabels.push(previewDay.format('YYYY-MM-DD'))
    labels.push(
      `${previewDay.format('DD-MM-YY')} ${currentDay.format('DD-MM-YY')}`
    )
    index--
  }

  const currentLegend = `du ${moment()
    .subtract(27, 'd')
    .format('DD-MM-YYYY')} au ${moment().format('DD-MM-YYYY')}`
  const previewLegend = `du ${moment()
    .subtract(55, 'd')
    .format('DD-MM-YYYY')} au ${moment()
    .subtract(28, 'd')
    .format('DD-MM-YYYY')}`

  const currentMonth: Dataset = {
    label: currentLegend,
    backgroundColor: COLORS.BLUE,
    borderColor: COLORS.DARKBLUE,
    data: new Array(28).fill(0),
  }
  const previewMonth: Dataset = {
    label: previewLegend,
    backgroundColor: COLORS.GREENCLEAR,
    borderColor: COLORS.DARKGREEN,
    data: new Array(28).fill(0),
  }

  data.forEach((value) => {
    if (moment(value.connexionAt) >= moment().subtract(28, 'd')) {
      currentMonth.data[currentLabels.indexOf(value.connexionAt)] =
        value.numberConnexion
    }
    if (moment(value.connexionAt) < moment().subtract(28, 'd')) {
      previewMonth.data[previewLabels.indexOf(value.connexionAt)] =
        value.numberConnexion
    }
  })

  return { labels, datasets: [currentMonth, previewMonth] }
}

export async function incrementKpiDownloadDoc(
  docType: string,
  errorType: string
) {
  const url = `${process.env.REACT_APP_BASE_API_URL}/kpi_download_documents`
  let status = 400

  try {
    const resp = await axios({
      method: 'POST',
      url,
      headers: {
        'content-type': 'application/json',
      },
      data: {
        docType,
        errorType,
      },
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}

export async function getKpiDownloadDoc(): Promise<KPIDownloadDocAPI[]> {
  const previousMonth: string = moment().subtract(1, 'M').format('YYYY-MM-01')
  const currentMonth: string = moment().format('YYYY-MM-DD')
  const url = `${process.env.REACT_APP_BASE_API_URL}/kpi_download_documents?monthYear[after]=${previousMonth}&monthYear[before]=${currentMonth}&perPage=100`

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }
  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      url,
    })
    const data = getMemberData(resp.data)
    return Promise.resolve(data)
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }
    console.error(err)
  }
  return Promise.resolve([])
}

function getKpiLabel(code: string): string {
  switch (code) {
    case 'FACTURE':
      return 'Facture'
    case 'AVOIR':
      return 'Avoir'
    case 'BRM':
      return 'BRM'
    case 'BI':
      return 'BI'
    case 'BP':
      return 'BP'
    case 'ATTESTATION_VALO':
      return 'Attestation de valorisation'
    default:
      return 'Autres'
  }
}

export function formatDataForTable(
  data: KPIDownloadDocAPI[]
): KPIDownloadDocTableEntity[] {
  const previousMonth: string = moment().subtract(1, 'M').format('MM-YYYY')
  const currentMonth: string = moment().format('MM-YYYY')

  // Group all months by docTypes
  const groupedData = data.reduce((r, a: KPIDownloadDocAPI) => {
    const initializeRow = {
      id: a.docType.code,
      docType: getKpiLabel(a.docType.code),
      nbSuccessDownloadM: 0,
      nbSuccessDownloadPreviousM: 0,
      nbFailedDownloadM: 0,
      nbFailedDownloadPreviousM: 0,
    }

    r[a.docType.code] = r[a.docType.code] || initializeRow
    if (a.monthYear === previousMonth) {
      r[a.docType.code].nbSuccessDownloadPreviousM = a.totalSuccess
      r[a.docType.code].nbFailedDownloadPreviousM =
        a.totalTechnicalError + a.totalUnfoundDoc
    } else if (a.monthYear === currentMonth) {
      r[a.docType.code].nbSuccessDownloadM = a.totalSuccess
      r[a.docType.code].nbFailedDownloadM =
        a.totalTechnicalError + a.totalUnfoundDoc
    }

    return r
  }, Object.create(null))

  // Convert Object to Array for table
  const formatedData: KPIDownloadDocTableEntity[] = Object.keys(
    groupedData
  ).map((key) => {
    return { ...groupedData[key] }
  })

  // For display: Fill non retrieved types with 0
  allDocTypes.map((item: string) => {
    if (!Object.keys(groupedData).includes(item)) {
      const initializeRow = {
        id: item,
        docType: getKpiLabel(item),
        nbSuccessDownloadM: 0,
        nbSuccessDownloadPreviousM: 0,
        nbFailedDownloadM: 0,
        nbFailedDownloadPreviousM: 0,
      }
      formatedData.push(initializeRow)
    }
  })

  // Order array as the same order as allDocTypes
  const orderedFormatedData: KPIDownloadDocTableEntity[] = []
  formatedData.forEach((item) => {
    orderedFormatedData[allDocTypes.indexOf(item.id)] = item
  })

  return orderedFormatedData
}

function generateKpiDownloadDocExcel(data: KPIDownloadDocAPI[]) {
  // Array with months-year => ex: [12-2022, 11-2022...]
  let month = 0
  let dateColumns: string[] = []
  while (month < 15) {
    const column = moment().subtract(month, 'M').format('MM-YYYY')
    dateColumns.push(column)
    month++
  }

  // Group by docType > monthYear
  const formatedData = data.reduce((acc, current) => {
    const initializeRow = {
      totalSuccess: 0,
      totalFailed: 0,
      totalTechnicalError: 0,
      totalUnfoundDoc: 0,
    }
    acc[current.docType.code] =
      acc[current.docType.code] ??
      new Array(dateColumns.length).fill(initializeRow)
    acc[current.docType.code][dateColumns.indexOf(current.monthYear)] = {
      totalSuccess: current.totalSuccess,
      totalFailed: current.totalTechnicalError + current.totalUnfoundDoc,
      totalTechnicalError: current.totalTechnicalError,
      totalUnfoundDoc: current.totalUnfoundDoc,
    }
    return acc
  }, Object.create(null))

  // For display: Fill non retrieved types with 0
  allDocTypes.map((item: string) => {
    if (!Object.keys(formatedData).includes(item)) {
      const initializeRow = {
        totalSuccess: 0,
        totalFailed: 0,
        totalTechnicalError: 0,
        totalUnfoundDoc: 0,
      }
      formatedData[item] = new Array(dateColumns.length).fill(initializeRow)
    }
  })

  fetch('/resources/KpiNombreTelechargementDocuments.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)

      // Prepare months header
      month = 0
      dateColumns = []
      while (month < 15) {
        const column = moment().subtract(month, 'M').format('MMMM YYYY')
        dateColumns.push(column.charAt(0).toUpperCase() + column.slice(1))
        month++
      }

      const introductionValues = {
        exportDate: moment().format('DD/MM/YYYY'),
        dateColumns,
        data: formatedData,
      }

      template.substitute(1, introductionValues)

      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const filename = `kpi_nombre_telechargement_documents.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}

export async function getKpiDownloadDocExport(): Promise<KPIDownloadDocAPI[]> {
  const fromDate: string = moment().subtract(14, 'M').format('YYYY-MM-01')
  const currentMonth: string = moment().format('YYYY-MM-DD')
  const url = `${process.env.REACT_APP_BASE_API_URL}/kpi_download_documents?monthYear[after]=${fromDate}&monthYear[before]=${currentMonth}`

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }
  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      url,
    })
    const data = getMemberData(resp.data)
    generateKpiDownloadDocExcel(data)
    return Promise.resolve(data)
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }
    console.error(err)
  }
  return Promise.resolve([])
}

export async function getDemandsKPI(
  demandLabels: string[]
): Promise<Dataset[]> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/v_kpi_demands`

  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    const { data } = resp

    const succesResults = [
      {
        label: 'Succès 1ère création',
        data: Array(6).fill(0),
        backgroundColor: '#AADC14',
        stack: 'success',
      },
      {
        label: 'Succès 1er rejeu',
        data: Array(6).fill(0),
        backgroundColor: '#C4E769',
        stack: 'success',
      },
      {
        label: 'Succès 2e rejeu',
        data: Array(6).fill(0),
        backgroundColor: '#D4ED94',
        stack: 'success',
      },
      {
        label: 'Succès 3e rejeu',
        data: Array(6).fill(0),
        backgroundColor: '#E6F5BF',
        stack: 'success',
      },
    ]

    const echecResults = [
      {
        label: 'Echec 1ère création',
        data: Array(6).fill(0),
        backgroundColor: '#030F40',
        stack: 'echec',
      },
      {
        label: 'Echec 1er rejeu',
        data: Array(6).fill(0),
        backgroundColor: '#4F5779',
        stack: 'echec',
      },
      {
        label: 'Echec 2e rejeu',
        data: Array(6).fill(0),
        backgroundColor: '#8187A0',
        stack: 'echec',
      },
      {
        label: 'Echec 3e rejeu',
        data: Array(6).fill(0),
        backgroundColor: '#B4B7C6',
        stack: 'echec',
      },
    ]

    data.forEach((item: any) => {
      const index = demandLabels.indexOf(
        moment(item.anneeMois, 'YYYY_MM').format('MMM YYYY')
      )
      if (index !== -1) {
        echecResults[item.typeRejeu].data[index] = item.echec
        succesResults[item.typeRejeu].data[index] = item.succes
      }
    })

    const result = [...succesResults, ...echecResults]

    return Promise.resolve(result)
  } catch (err) {
    throw new Error(err)
  }
}
