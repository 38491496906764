/* eslint-disable @typescript-eslint/no-explicit-any */

import InstitutionEntity from '../utils/InstitutionEntity'
import OrganizationEntity from '../utils/OrganizationEntity'
import AllRolesEntity from '../utils/AllRolesEntity'
import ViewMode from '../utils/VIEW_MODE'
import { UserEntity } from '../utils/UserEntity'
import ServiceEntity from '../utils/ServiceEntity'

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum ActionTypes {
  SET_USER_TYPE = 'SET_USER_TYPE',
  SET_TOKEN_INFO = 'SET_TOKEN_INFO',
  SET_FIRSTNAME = 'SET_FIRSTNAME',
  SET_SERVICE_LIST = 'SET_SERVICE_LIST',
  SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST',
  SET_ORGANIZATION_VALIDATED = 'SET_ORGANIZATION_VALIDATED',
  SET_INSTITUTION_LIST = 'SET_INSTITUTION_LIST',
  SET_INSTITUTION_PERIMETER = 'SET_INSTITUTION_PERIMETER',
  SET_INSTITUTION_VALIDATED = 'SET_INSTITUTION_VALIDATED',
  SET_ALL_ROLES = 'SET_ALL_ROLES',
  SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR',
  SET_CREATE_USERID = 'SET_CREATE_USERID',
  RESET_PERIMETER_FILTER = 'RESET_PERIMETER_FILTER',
  SWITCH_PERIMETER_FILTER_MODE = 'SWITCH_PERIMETER_FILTER_MODE',
  SWITCH_VIEW_MODE = 'SWITCH_VIEW_MODE',
  SET_STEP2_STATE = 'SET_STEP2_STATE',
  SET_STEP3_STATE = 'SET_STEP3_STATE',
  SET_IS_CREATION = 'SET_IS_CREATION',
  SET_USERID_CREATEMODIFY = 'SET_USERID_CREATEMODIFY',
  SET_ACTUAL_STEP = 'SET_ACTUAL_STEP',
  SET_USER_ENTITY = 'SET_USER_ENTITY',
  SET_IS_IMPERSONNATION = 'SET_IS_IMPERSONNATION',
  SET_USERNAME_IMPERSONNATION = 'SET_USERNAME_IMPERSONNATION',
  SET_API_CALL_BLOCK = 'SET_API_CALL_BLOCK',
  SET_INSC_ACTUAL_STEP = 'SET_INSC_ACTUAL_STEP',
  SET_INSC_STEP2_STATE = 'SET_INSC_STEP2_STATE',
  SET_INSC_STEP3_STATE = 'SET_INSC_STEP3_STATE',
  SET_INSC_USER_ENTITY = 'SET_INSC_USER_ENTITY',
}

type UserPayload = {
  [ActionTypes.SET_USER_TYPE]: {
    email: string
    isInternal: boolean
    byPassRecaptch: boolean
  }
  [ActionTypes.SET_TOKEN_INFO]: {
    username: string
    userId: string
    isInternal: boolean
    organizations: string[]
    roleNames: string[]
    firstname: string
    accessDemands: boolean
    accessDocs: boolean
    idContactWdh: string
    isSuperAdminTech: boolean
  }
  [ActionTypes.SET_FIRSTNAME]: {
    firstname: string
  }
}

type UserInCreationPayload = {
  [ActionTypes.SET_CREATE_USERID]: {
    userId: string
  }
}

type CreateModifyUserPayload = {
  [ActionTypes.SET_USERID_CREATEMODIFY]: {
    userId: string
    isCreation?: boolean
  }
  [ActionTypes.SET_IS_CREATION]: {
    isCreation: boolean
  }
  [ActionTypes.SET_STEP2_STATE]: {
    step2Disabled: boolean
  }
  [ActionTypes.SET_STEP3_STATE]: {
    step3Disabled: boolean
  }
  [ActionTypes.SET_ACTUAL_STEP]: {
    actualStep: string
  }
  [ActionTypes.SET_USER_ENTITY]: {
    userEntity: UserEntity
  }
  [ActionTypes.SET_INSC_STEP2_STATE]: {
    step2Disabled: boolean
  }
  [ActionTypes.SET_INSC_STEP3_STATE]: {
    step3Disabled: boolean
  }
  [ActionTypes.SET_INSC_ACTUAL_STEP]: {
    actualStep: string
  }
}
type ImpersonnationPayload = {
  [ActionTypes.SET_IS_IMPERSONNATION]: {
    isImpersonnation: boolean
  }
  [ActionTypes.SET_USERNAME_IMPERSONNATION]: {
    nameImpersonnated: string
    accessDemands: boolean
    emailImpersonated: string
  }
}

type ApiCallBlockPayload = {
  [ActionTypes.SET_API_CALL_BLOCK]: {
    APICallBlocked: boolean
  }
}

type ServicePayload = {
  [ActionTypes.SET_SERVICE_LIST]: {
    serviceList: ServiceEntity[]
  }
}
type OrganizationPayload = {
  [ActionTypes.SET_ORGANIZATION_LIST]: {
    organizationList: OrganizationEntity[]
  }
}

type OrganizationValidationPayload = {
  [ActionTypes.SET_ORGANIZATION_VALIDATED]: {
    organizationValidated: boolean
  }
}

type InstitutionPayload = {
  [ActionTypes.SET_INSTITUTION_LIST]: {
    institutionList: InstitutionEntity[]
  }
}
type InstitutionPerimeterPayload = {
  [ActionTypes.SET_INSTITUTION_PERIMETER]: {
    institutionPerimeter: InstitutionEntity[]
  }
}

type InstitutionValidationPayload = {
  [ActionTypes.SET_INSTITUTION_VALIDATED]: {
    institutionValidated: boolean
  }
}

type AllRolesPayload = {
  [ActionTypes.SET_ALL_ROLES]: {
    allRoles: AllRolesEntity[]
  }
}

type UiPayload = {
  [ActionTypes.SET_BACKGROUND_COLOR]: {
    backgroundColor: string
  }
  [ActionTypes.SWITCH_PERIMETER_FILTER_MODE]: null
  [ActionTypes.SWITCH_VIEW_MODE]: {
    viewMode: ViewMode
  }
}

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>]

export type UserInCreationActions = ActionMap<
  UserInCreationPayload
>[keyof ActionMap<UserInCreationPayload>]

export type CreateModifyUserActions = ActionMap<
  CreateModifyUserPayload
>[keyof ActionMap<CreateModifyUserPayload>]

export type ImpersonnationActions = ActionMap<
  ImpersonnationPayload
>[keyof ActionMap<ImpersonnationPayload>]

export type ApiCallBlockActions = ActionMap<
  ApiCallBlockPayload
>[keyof ActionMap<ApiCallBlockPayload>]

export type AllRolesActions = ActionMap<AllRolesPayload>[keyof ActionMap<
  AllRolesPayload
>]

export type ServiceActions = ActionMap<ServicePayload>[keyof ActionMap<
  ServicePayload
>]

export type OraganizationActions = ActionMap<
  OrganizationPayload
>[keyof ActionMap<OrganizationPayload>]

export type InstitutionActions = ActionMap<InstitutionPayload>[keyof ActionMap<
  InstitutionPayload
>]
export type InstitutionPerimeterActions = ActionMap<
  InstitutionPerimeterPayload
>[keyof ActionMap<InstitutionPerimeterPayload>]

export type OraganizationValidationActions = ActionMap<
  OrganizationValidationPayload
>[keyof ActionMap<OrganizationValidationPayload>]

export type InstitutionValidationActions = ActionMap<
  InstitutionValidationPayload
>[keyof ActionMap<InstitutionValidationPayload>]

export type UiActions = ActionMap<UiPayload>[keyof ActionMap<UiPayload>]

export type PerimeterFilterActions = {
  type: ActionTypes.RESET_PERIMETER_FILTER
}

export type APP_ACTIONS =
  | UserActions
  | UserInCreationActions
  | CreateModifyUserActions
  | OraganizationActions
  | ServiceActions
  | InstitutionActions
  | InstitutionPerimeterActions
  | OraganizationValidationActions
  | InstitutionValidationActions
  | AllRolesActions
  | UiActions
  | PerimeterFilterActions
  | ImpersonnationActions
  | ApiCallBlockActions
