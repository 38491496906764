import React from 'react'
import styled from 'styled-components'
import SvgIcon from '../../Icons/SvgIcon'

interface ControleProps {
  mode: string
  resetHovered: boolean
  isImpersonnation: boolean
}

export const StyledBandeauControl = styled.div<ControleProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
  padding: 0.3rem 0.5rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  ${(props) =>
    props.isImpersonnation
      ? {
          'background-color': '#ffc229',
          color: '#030f40',
        }
      : {
          'background-color': '#030f40',
          color: '#ffffff',
        }}
  ${(props) =>
    props.mode === 'toggledOff'
      ? {
          bottom: '0rem',
        }
      : {
          bottom: '-2.5rem',
        }}
  position: relative;
  cursor: pointer;
  align-self: flex-end;

  .icon {
    margin: 0 0.4rem;
    height: 1.8rem;

    & path {
      ${(props) =>
        props.isImpersonnation
          ? {
              'background-color': '#030f40',
              fill: '#030f40',
            }
          : {
              'background-color': '#ffffff',
              fill: '#ffffff',
            }}
    }
  }
`
interface Props {
  title: string
  icon: string
  mode: string
  resetHovered: boolean
  isImpersonnation: boolean
}
const BandeauControle = ({
  title,
  icon,
  mode,
  resetHovered,
  isImpersonnation,
}: Props) => {
  return (
    <StyledBandeauControl
      mode={mode}
      resetHovered={resetHovered}
      isImpersonnation={isImpersonnation}
    >
      {title}
      <SvgIcon name={icon} className="icon" />
    </StyledBandeauControl>
  )
}
export default BandeauControle
