/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'

/**
 * Formate Message Users
 * @param messageText string
 * @param expireAt Date
 * @param usersId string[]
 */
export default function getFormatedMessageUsers(
  messageText: string,
  expireAt: Date,
  usersId: string[]
): any {
  const usersList: string[] = []
  usersId.forEach((idUser) => {
    const entitie: any = {
      user: `${'/users/'}${idUser}`,
    }
    usersList.push(entitie)
  })
  const result = {
    messagetext: messageText,
    expireAt: moment(expireAt).format('YYYY-MM-DD'),
    users: usersList,
  }
  return result
}
