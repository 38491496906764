import React from 'react'

import ScreenContainerSimpleLayoutConnexion from '../ScreenContainerSimpleLayoutConnexion'

interface Props {
  heading: string
  topAdvice?: string
  supplementaryAdvice?: string
  info?: string
  children: React.ReactNode
  connected?: boolean
}

const ConnectionLayout: React.FC<Props> = ({
  heading,
  topAdvice,
  supplementaryAdvice,
  info,
  children,
  connected,
}: Props) => {
  return (
    <>
      <ScreenContainerSimpleLayoutConnexion isLogged={connected}>
        <div className="layout-connection">
          <div className="top-advice">
            {topAdvice}
            {supplementaryAdvice && <div>{supplementaryAdvice}</div>}
          </div>
          <div className="dialog-box">
            <div className="dialog-box__heading">{heading}</div>
            <p className="dialog-box__info">{info}</p>
            {children}
          </div>
        </div>
      </ScreenContainerSimpleLayoutConnexion>
    </>
  )
}

ConnectionLayout.defaultProps = {
  topAdvice: '',
  info: '',
  supplementaryAdvice: '',
  connected: false,
}

export default ConnectionLayout
