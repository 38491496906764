/* eslint-disable class-methods-use-this */
import NotificationNH from '../../utils/interfaces/Notifications/NotificationNH'
import NotificationECE from '../../utils/interfaces/Notifications/NotificationECE'
import { parseDate } from '../../utils/date-utils'

class NotificationNormalizer {
  normalize(notificationList: NotificationNH[]): NotificationECE[] {
    return (
      notificationList
        // Les notifications ayant une date d'envoi nulle ne doivent pas être affichées dans l'ECE (MVP)
        .filter((notification) => notification.notificationDateEnvoi !== null)
        .map((notification) => {
          const typeNotification =
            notification.typeNotification === 'GDD' ? 'Demande' : 'Autre'

          return {
            id: notification.id,
            messageObjet: notification.messageObjet,
            messageCorps: notification.messageCorps,
            seeApplication: notification.seeApplication,
            typeNotification,
            dateEnvoi: parseDate(notification.notificationDateEnvoi ?? ''), // Pour compiler
          }
        })
    )
  }
}

export default new NotificationNormalizer()
