/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode } from 'react'

import SearchResultItem from '../../utils/SearchResultItem'
import SvgIcon from '../Icons/SvgIcon'

interface Props {
  placeholder?: ReactNode
  items: SearchResultItem[] | undefined
  entityAbbreviation: string
  onUnselect: (id: string) => void
}
const SearchSelected: React.FC<Props> = ({
  placeholder,
  items,
  entityAbbreviation,
  onUnselect,
}: Props) => {
  if (!items || items.length === 0) {
    return <></>
  }

  return (
    <div className="search-selected">
      <div className="search-selected__placeholder">{placeholder}</div>
      <div>
        {items?.map(({ id, nbSubEntity, line1 }) => (
          <div key={id} className="search-selected__item">
            <div className="search-selected__item-name">{line1}</div>
            <div className="search-selected__sub-entity">
              <div>
                {nbSubEntity} {entityAbbreviation}
              </div>
              <div
                className="search-selected__close"
                onClick={() => onUnselect(id)}
              >
                <SvgIcon name="close" className="search-selected__close-icon" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
SearchSelected.defaultProps = { placeholder: '' }

export default SearchSelected
