import React from 'react'

import Button from './Button'
import SvgIcon from '../Icons/SvgIcon'
import Loading from '../Loaders/Loading'

interface Props {
  iconName: string
  onClick: (e: React.MouseEvent) => void
  disabled?: boolean
  isCompleting?: boolean
}
function ButtonSquareIcon({
  iconName,
  onClick,
  disabled,
  isCompleting,
}: Props) {
  return (
    <Button
      disabled={disabled}
      className="buttonSquareIcon button__secondary"
      onClick={onClick}
    >
      <Loading isLoading={isCompleting} inButton isCommon>
        <SvgIcon name={iconName} className="" />
      </Loading>
    </Button>
  )
}

ButtonSquareIcon.defaultProps = {
  disabled: false,
  isCompleting: false,
}

export default ButtonSquareIcon
