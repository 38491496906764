import React from 'react'

import InstitutionEntity from '../utils/InstitutionEntity'
import SearchResultItem from '../utils/SearchResultItem'

function SearchInstitutionAdapter(
  searchResultItems: InstitutionEntity[]
): SearchResultItem[] {
  if (
    searchResultItems?.length === undefined ||
    searchResultItems?.length < 1
  ) {
    return []
  }

  return searchResultItems.map(({ id, name, address }) => ({
    id,
    line1: <>{name}</>,
    line2: <>{address}</>,
  }))
}

export default SearchInstitutionAdapter
