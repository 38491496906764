import React from 'react'
import styled from 'styled-components'

const StyledBandeauTitle = styled.span`
  align-self: flex-start;
  display: flex;
  margin-right: 4%;
  font-size: 1.6rem;
  @media screen and (max-width: 996px) {
    order: 0;
  }
`
interface Props {
  children: React.ReactNode
}
const BandeauTitle = ({ children }: Props) => {
  return <StyledBandeauTitle>{children}</StyledBandeauTitle>
}
export default BandeauTitle
