import ROLES from './ROLES'

const ROLES_ACCESS = {
  mainMenuActivityRoles: [ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_INT_ACTIVITIES],
  mainMenubillRoles: [ROLES.ROLE_CLI_BILLING, ROLES.ROLE_INT_BILLING],
  activityRoles: [
    ROLES.ROLE_INT_ACTIVITIES,
    ROLES.ROLE_INT_SUPER_ADMIN,
    ROLES.ROLE_CLI_ACTIVITIES,
  ],

  adminActivityRoles: [ROLES.ROLE_CLI_ACTIVITIES, ROLES.ROLE_CLI_ADMIN],

  billRoles: [
    ROLES.ROLE_INT_BILLING,
    ROLES.ROLE_INT_SUPER_ADMIN,
    ROLES.ROLE_CLI_BILLING,
  ],
  documentRoles: [
    ROLES.ROLE_CLI_ACTIVITIES,
    ROLES.ROLE_CLI_BILLING,
    ROLES.ROLE_INT_SUPER_ADMIN,
  ],
  mesDocumentsRoles: [
    ROLES.ROLE_CLI_ACTIVITIES,
    ROLES.ROLE_CLI_BILLING,
    ROLES.ROLE_INT_ACTIVITIES,
    ROLES.ROLE_INT_BILLING,
  ],
  contactRoles: [
    ROLES.ROLE_CLI_ACTIVITIES,
    ROLES.ROLE_CLI_BILLING,
    ROLES.ROLE_CLI_ADMIN,
  ],
  adminUsersRoles: [
    ROLES.ROLE_INT_ACTIVITIES,
    ROLES.ROLE_INT_BILLING,
    ROLES.ROLE_INT_ADMIN_CLIENT,
    ROLES.ROLE_INT_ADMIN_INTERNAL,
    ROLES.ROLE_INT_SUPER_ADMIN,
    ROLES.ROLE_CLI_ADMIN,
    ROLES.ROLE_INT_SUPPORT_N1,
  ],
  adminOrganisationsRoles: [
    ROLES.ROLE_INT_ADMIN_CLIENT,
    ROLES.ROLE_INT_ADMIN_INTERNAL,
    ROLES.ROLE_INT_SUPER_ADMIN,
    ROLES.ROLE_CLI_ADMIN,
    ROLES.ROLE_INT_SUPPORT_N1,
  ],
  adminDocumentsRoles: [
    ROLES.ROLE_INT_ADMIN_CLIENT,
    ROLES.ROLE_INT_ADMIN_INTERNAL,
    ROLES.ROLE_INT_SUPER_ADMIN,
  ],
  firstPageAdminUsersRoles: [
    ROLES.ROLE_INT_ACTIVITIES,
    ROLES.ROLE_INT_BILLING,
    ROLES.ROLE_INT_ADMIN_CLIENT,
    ROLES.ROLE_INT_ADMIN_INTERNAL,
    ROLES.ROLE_INT_SUPPORT_N1,
  ],
  myDemandsRoles: [
    ROLES.ROLE_CLI_ADMIN,
    ROLES.ROLE_CLI_BILLING,
    ROLES.ROLE_CLI_ACTIVITIES,
  ],
  superAdminPageRole: [ROLES.ROLE_INT_SUPER_ADMIN],
}

export default ROLES_ACCESS
