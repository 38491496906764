/* eslint-disable no-param-reassign */
import CollapseDto from './CollapseDto'
/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface NodeAndChildren {
  collapse: string[]
  table: any
}
export interface CollapseDtoIdLevel {
  id: string
  level: number | undefined
  parent: string | undefined
}

function hasInstitutionCorrespondingToSearch(
  node: CollapseDto,
  searchValue: string
): boolean {
  if (
    !node?.children?.[0]?.type ||
    node.children[0].type !== 'table' ||
    !node.children[0]?.data ||
    node.children[0]?.data.length === 0
  ) {
    return false
  }

  return node.children[0].data.some((row) => {
    const keys = Object.keys(row)
    return keys.some((k) =>
      row[k].toLowerCase().includes(searchValue.toLowerCase())
    )
  })
}

/**
 * Recursive function that iterate over `children` and feed `outputResult` and `outputFoundChildren` by reference (it's easier like that because of the recursive callings)
 * @param children the object iterated recursively
 * @param nodeId the node ID to find
 * @param outputResult object modified by reference
 * @param outputFoundChildren object modified by reference
 */
export function filterCollapseById(
  children: CollapseDto[] | undefined,
  nodeId: string,
  outputResult: NodeAndChildren,
  outputFoundChildren: { ref: CollapseDto[] | undefined }
): void {
  if (children) {
    children.some((currentNode) => {
      if (currentNode.id === nodeId) {
        outputResult.collapse.push(currentNode.id)
        outputFoundChildren.ref = currentNode.children
        return true
      }

      filterCollapseById(
        currentNode.children,
        nodeId,
        outputResult,
        outputFoundChildren
      )
      return false
    })
  }
}

/**
 * Recursive function that use outputFoundNode, in argument, to set the result (it's easier like that because of the recursive callings))
 * @param collapse
 * @param nodeId
 * @param outputFoundNode the object that contain the result (it has to be initialized by the caller)
 */
export function findNode(
  collapse: CollapseDto | undefined,
  nodeId: string,
  outputFoundNode: { ref: CollapseDto | undefined }
): void {
  if (collapse?.id === nodeId) {
    outputFoundNode.ref = collapse
    return
  }

  if (collapse?.children) {
    collapse.children.some((currentNode) => {
      if (currentNode.id === nodeId) {
        outputFoundNode.ref = { ...currentNode }
        return true
      }
      findNode(currentNode, nodeId, outputFoundNode)
      return false
    })
  }
}

/**
 * Recursive function that iterate over `children` and feed `outputResult` and `outputFoundChildren` by reference (it's easier like that because of the recursive callings)
 * @param children the object iterated recursively
 * @param searchValue
 * @param outputResult object modified by reference
 * @param outputFoundChildren object modified by reference
 */
export function filterCollapseByInstitution(
  children: CollapseDto[] | undefined,
  searchValue: string,
  outputResult: { ref: string[] },
  outputFoundChildren: { ref: CollapseDto[] | undefined }
): void {
  if (children) {
    children.some((currentNode) => {
      if (hasInstitutionCorrespondingToSearch(currentNode, searchValue)) {
        outputResult.ref.push(currentNode.id)
        outputFoundChildren.ref = currentNode.children
        return true
      }

      filterCollapseByInstitution(
        currentNode.children,
        searchValue,
        outputResult,
        outputFoundChildren
      )
      return false
    })
  }
}

export function findNodeTreeById(
  collapse: CollapseDto,
  nodeId: string
): NodeAndChildren {
  const result: NodeAndChildren = { collapse: [], table: [] }

  const { id, children } = collapse
  const foundChildren: { ref: CollapseDto[] | undefined } = { ref: undefined }

  // recursive function
  const feedResult = (currentChildren: CollapseDto[] | undefined) => {
    if (currentChildren) {
      currentChildren.forEach((currentNode) => {
        if (currentNode.type === 'collapse') {
          result.collapse.push(currentNode.id)
          feedResult(currentNode.children)
        } else if (currentNode.dataIds && currentNode.dataIds.length > 0) {
          // table type
          result.table = [...result.table, ...currentNode.dataIds]
        }
      })
    }
  }

  if (id === nodeId) {
    result.collapse.push(id)
    foundChildren.ref = children
  } else {
    filterCollapseById(children, nodeId, result, foundChildren)
  }

  feedResult(foundChildren.ref)

  return result
}

export function findNodeTreeByIdList(
  collapse: CollapseDto,
  nodeIds: string[]
): NodeAndChildren {
  const result: NodeAndChildren = { collapse: [], table: [] }

  nodeIds.forEach((nodeId) => {
    const { collapse: currentCollapse, table } = findNodeTreeById(
      collapse,
      nodeId
    )
    result.collapse = [...result.collapse, ...currentCollapse]
    result.table = [...result.table, ...table]
  })

  return result
}

export function findNodeIdsByInstitution(
  collapse: CollapseDto,
  searchValue: string
): string[] {
  const resultRef: { ref: string[] } = { ref: [] }
  const foundChildren: { ref: CollapseDto[] | undefined } = { ref: undefined }

  const { id, children } = collapse

  if (hasInstitutionCorrespondingToSearch(collapse, searchValue)) {
    resultRef.ref.push(id)
  } else {
    filterCollapseByInstitution(children, searchValue, resultRef, foundChildren)
  }

  return resultRef.ref
}

export function findNodeIdsByInstitutionList(
  collapse: CollapseDto,
  searchValueList: string[]
): string[] {
  let result: string[] = []

  searchValueList.forEach((searchValue) => {
    result = [...result, ...findNodeIdsByInstitution(collapse, searchValue)]
  })

  return result
}

export function findBranch(
  collapse: CollapseDto,
  currentNodeId: string
): string[] {
  let result: string[] = []
  const node: { ref: CollapseDto | undefined } = { ref: undefined }

  findNode(collapse, currentNodeId, node)

  if (node?.ref?.parentId) {
    result.push(node.ref.id)
    while (node.ref.level !== 1) {
      findNode(collapse, node.ref.parentId, node)
      result = [node.ref.id, ...result]
    }
  }

  return result
}

export function extractCollapseDtoIdLevel(
  organisationCollapseDto: CollapseDto | undefined
): CollapseDtoIdLevel[] {
  const table: CollapseDtoIdLevel[] = []
  if (organisationCollapseDto) {
    const { id } = organisationCollapseDto
    const { level } = organisationCollapseDto
    const { parentId } = organisationCollapseDto
    if (organisationCollapseDto.children) {
      organisationCollapseDto.children.forEach(async (element) => {
        table.push({ id, level, parent: parentId })
        if (element.type === 'collapse') {
          const nodeSecond: { ref: CollapseDto | undefined } = {
            ref: undefined,
          }
          findNode(organisationCollapseDto, element.id, nodeSecond)
          const recResult = extractCollapseDtoIdLevel(nodeSecond.ref)
          if (recResult) {
            recResult.forEach((elem) => {
              table.push({
                id: elem.id,
                level: elem.level,
                parent: elem.parent,
              })
            })
          }
        }
      })
    }
  }
  // fonction au moment du return pour enlever les doublons
  return table.filter((v, i, a) => a.findIndex((xt) => xt.id === v.id) === i)
}

export function extractCollapseDtoIds(
  organisationCollapseDto: CollapseDto | undefined
): string[] {
  const table: string[] = []
  if (organisationCollapseDto) {
    const { id } = organisationCollapseDto
    if (organisationCollapseDto.children) {
      organisationCollapseDto.children.forEach(async (element) => {
        table.push(id)
        if (element.type === 'collapse') {
          const nodeSecond: { ref: CollapseDto | undefined } = {
            ref: undefined,
          }
          findNode(organisationCollapseDto, element.id, nodeSecond)
          const recResult = extractCollapseDtoIds(nodeSecond.ref)
          if (recResult) {
            recResult.forEach((elem) => {
              table.push(elem)
            })
          }
        }
      })
    }
  }
  // fonction au moment du return pour enlever les doublons
  return table.filter((v, i, a) => a.findIndex((xt) => xt === v) === i)
}

export function getCollapseChildIds(
  allDto: CollapseDto[],
  selectedElems: string
): CollapseDtoIdLevel[] {
  const result: CollapseDtoIdLevel[] = []
  if (allDto)
    allDto.forEach((dto) => {
      const nodeSecond: { ref: CollapseDto | undefined } = {
        ref: undefined,
      }
      if (selectedElems) {
        findNode(dto, selectedElems, nodeSecond)
        if (nodeSecond.ref) {
          const elemResult = extractCollapseDtoIdLevel(nodeSecond.ref)
          if (elemResult) {
            elemResult.forEach((elem) => {
              result.push(elem)
            })
          }
        }
      }
    })

  return result
}

export interface collapseTree {
  id: string
  collapseElem: CollapseDtoIdLevel[]
}
export function getAllcollapseTree(dtoList: CollapseDto[]): collapseTree[] {
  const listTree: collapseTree[] = []
  dtoList.forEach((element: CollapseDto | undefined) => {
    const res = extractCollapseDtoIdLevel(element)
    if (element?.id) listTree.push({ id: element.id, collapseElem: res })
  })
  return listTree
}

export function getCurrentCollapseElem(
  collapseList: collapseTree[],
  id: string
): CollapseDtoIdLevel[] {
  let result: CollapseDtoIdLevel[] = []
  collapseList.forEach((collapseel) => {
    if (
      collapseel.collapseElem.filter((item) => {
        return item.id === id
      }).length > 0
    ) {
      result = collapseel.collapseElem
    }
  })
  return result
}

export function extractParent(
  collapseItem: CollapseDtoIdLevel[],
  id: string,
  currentLevel: number | undefined
): string[] {
  if (
    collapseItem.filter((item) => {
      return item.id === id
    }).length > 0
  ) {
    return collapseItem
      .filter((items) => {
        if (items.level && currentLevel) {
          return items.level < currentLevel
        }
        return []
      })
      .map((el) => {
        return el.id
      })
  }
  return []
}
export enum TRANSLATE {
  ORGANISATION_SINGULAR = 'organisation sélectionnée',
  ORGANISATION_PLURAL = 'organisations sélectionnées',
  SOUS_ORGANISATION_SINGULAR = '(avec tous ses sous-niveaux)',
  SOUS_ORGANISATION_PLURAL = '(avec tous leurs sous-niveaux)',
  SELECT_SINGULAR = 'élément sélectionné',
  SELECT_PLURAL = 'éléments sélectionnés',
}

export function getLabelForSelected(nbSelected: number): string {
  if (nbSelected === 0) {
    return `${nbSelected} ${TRANSLATE.ORGANISATION_SINGULAR}`
  }
  if (nbSelected > 1) {
    return `${nbSelected} ${TRANSLATE.ORGANISATION_PLURAL}`
  }
  return `${nbSelected} ${TRANSLATE.ORGANISATION_SINGULAR}`
}
export function getLabelForIncluded(nbrSelected: number): string {
  if (nbrSelected === 0) {
    return `${' '}`
  }
  if (nbrSelected > 1) {
    return `${TRANSLATE.SOUS_ORGANISATION_PLURAL}`
  }
  return `${TRANSLATE.SOUS_ORGANISATION_SINGULAR}`
}
export function getLabelForRemove(nbrSelected: number): string {
  if (nbrSelected === 0) {
    return `${nbrSelected} ${TRANSLATE.SELECT_SINGULAR}`
  }
  if (nbrSelected > 1) {
    return `${nbrSelected} ${TRANSLATE.SELECT_PLURAL}`
  }
  return `${nbrSelected} ${TRANSLATE.SELECT_SINGULAR}`
}
