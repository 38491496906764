/* eslint-disable class-methods-use-this */
import { wasteRegisterAPIM } from '../utils/wasteRegisterAPIM'
import WasteRegisterEntity from '../utils/WasteRegisterEntity'
import { parseDate } from '../utils/date-utils'
import { normalizeString } from '../utils/ts-utils'

class WasteRegisterNormalizer {
  normalize(organizationList: wasteRegisterAPIM[]): WasteRegisterEntity[] {
    return organizationList.map(
      ({
        idRegistreDechet,
        codeDechetNED,
        numDocumentAnnexeVII,
        nomTransporteur,
        numBSD,
        numRecipisseTransporteur,
        rueAdresseTransporteur,
        qteContaminant,
        libelleDechetNEDContaminant,
        qtePesee,
        dateExpedition,
        nomExutoire,
        rueAdresseExutoire,
        cpAdresseExutoire,
        villeAdresseExutoire,
        libelleTraitementExutoire,
        fkadresse,
        fkMatiere,
        codeTraitementExutoire,
        numBonIntervention,
        volumeMateriel,
        uniteVolumeMateriel,
        cpAdresseTransporteur,
        villeAdresseTransporteur,
        numDossier,
        libelleDechetNED,
        codeDechetNEDContaminant,
        qteCollectee,
        descriptionDossier,
        unite,
        qualificationTraitementFinal,
        dechetPop,
        nomCourtier,
        numDebiteur,
        numNotification,
        numRecipisseCourtier,
        numSaisie,
        paysAdresseProducteur,
        paysAdresseTransporteur,
        siretCourtier,
        siretExutoire,
        siretProducteur,
        siretTransporteur,
        typeCourtier,
        typeExutoire,
        typeProducteur,
        typeTransporteur,
        numBonPesee,
      }) => ({
        idRegister: idRegistreDechet,
        nomSiteExpediteur: nomExutoire,
        nRueSiteExpediteur: rueAdresseExutoire,
        cpSiteExpediteur: cpAdresseExutoire,
        villeExpediteur: villeAdresseExutoire,
        numDossier,
        descriptionDossier:
          descriptionDossier != null ? descriptionDossier : '',
        numBonIntervention:
          numBonIntervention != null ? numBonIntervention : '',
        dateExpedition: parseDate(dateExpedition),
        codeDechetNED,
        nbInstitutions: 11,
        natureDechet: libelleDechetNED != null ? libelleDechetNED : '',
        quantitePesee: qtePesee != null ? qtePesee : 0,
        unite,
        quantiteCollectee: qteCollectee != null ? qteCollectee : 0,
        uniteVolume: '',
        nomInstallation: fkadresse !== null ? fkadresse.nomAdresse : '',
        nRueInstallation:
          fkadresse?.rueAdresse != null ? fkadresse.rueAdresse : '',

        villeInstallation: fkadresse !== null ? fkadresse.villeAdresse : '',
        codePostalAdresseInstallation:
          fkadresse !== null ? fkadresse.codePostalAdresse : '',
        nomTransporteur,
        nRueTransporteur: rueAdresseTransporteur,
        cpAdresseTransporteur,
        villeAdresseTransporteur,
        numeroRecepisseTransporteur: numRecipisseTransporteur,
        nBSD: numBSD != null ? numBSD : '',
        nDocAnnexe: numDocumentAnnexeVII != null ? numDocumentAnnexeVII : '',
        codeTraitement: codeTraitementExutoire,
        libelleTraitement: libelleTraitementExutoire,
        qualificationTraitement: qualificationTraitementFinal || '',
        volumeMateriel: volumeMateriel !== null ? Number(volumeMateriel) : 0,
        uniteVolumeMateriel: uniteVolumeMateriel || '',
        codeDechetNEDContaminant:
          codeDechetNEDContaminant !== null ? codeDechetNEDContaminant : '',
        libelleDechetNEDContaminant:
          libelleDechetNEDContaminant !== null
            ? libelleDechetNEDContaminant
            : '',
        qteContaminant: qteContaminant !== null ? qteContaminant : 0,
        dechetPop: normalizeString(dechetPop),
        nomCourtier: normalizeString(nomCourtier),
        numDebiteur: normalizeString(numDebiteur),
        numNotification: normalizeString(numNotification),
        numRecipisseCourtier: normalizeString(numRecipisseCourtier),
        numSaisie: normalizeString(numSaisie),
        paysAdresseProducteur: normalizeString(paysAdresseProducteur),
        paysAdresseTransporteur: normalizeString(paysAdresseTransporteur),
        siretCourtier: normalizeString(siretCourtier),
        siretExutoire: normalizeString(siretExutoire),
        siretProducteur: normalizeString(siretProducteur),
        siretTransporteur: normalizeString(siretTransporteur),
        typeCourtier: normalizeString(typeCourtier),
        typeExutoire: normalizeString(typeExutoire),
        typeProducteur: normalizeString(typeProducteur),
        typeTransporteur: normalizeString(typeTransporteur),
        numBonPesee: normalizeString(numBonPesee),
        matiere: fkMatiere !== null ? fkMatiere.libelleDechetInterne : '',
      })
    )
  }
}

export default new WasteRegisterNormalizer()
