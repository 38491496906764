/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { isMobile } from '../../utils/screenUtils'

interface Props {
  children: React.ReactNode
  backgroundUrl: any
}

const ScreenContainerWithBackgroundWrapper = ({
  children,
  backgroundUrl,
}: Props) => {
  return (
    <div
      className="screen-container__wrapper-with-cover-bg"
      style={{
        backgroundColor: '#f4f6fb',
        backgroundImage: isMobile(window.innerWidth)
          ? 'none'
          : `url(${backgroundUrl})`,
        backgroundPosition: 'center 8rem',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '123%',
        minHeight: '100vh',
      }}
    >
      {children}
    </div>
  )
}

export default ScreenContainerWithBackgroundWrapper
