/* eslint-disable class-methods-use-this */
import { BillingSynthesisWDHByServices } from '../utils/BillingSynthesisWDHByServices'
import { BillsAndCreditsByServiceEntity } from '../utils/BillSynthesisEntity'

class BillingSynthesisNormalizerByService {
  normalize(
    billAndCreditList: BillingSynthesisWDHByServices[]
  ): BillsAndCreditsByServiceEntity[] {
    return billAndCreditList.map((item) => {
      return {
        indicateurCode: item.indicateurCode,
        indicateurLibelle: item.indicateurLibelle,
        annee: item.annee,
        mois: item.mois,
        unite: item.unite,
        amount: Number(item.numerateur),
        denominateur: Number(item.denominateur),
        fkfamilleservice: item.fkfamilleservice,
        familleServiceCode: item.familleServiceCode,
        familleServiceLibelle: item.familleServiceLibelle,
      }
    })
  }
}

export default new BillingSynthesisNormalizerByService()
