import React from 'react'
import { Form as FormikForm } from 'formik'

interface Props {
  children: React.ReactNode
  className?: string
}

const Form: React.FC<Props> = ({ children, className }: Props) => (
  <FormikForm>
    <div className={className}>{children}</div>
  </FormikForm>
)

Form.defaultProps = {
  className: 'form',
}

export default Form
