/* eslint-disable class-methods-use-this */
import InstitutionEntity from '../utils/InstitutionEntity'
import CollapseDto from '../utils/CollapseDto'

class CollapseTableNormalizer {
  normalize(
    institutionList: InstitutionEntity[],
    organizationId: string
  ): CollapseDto[] {
    const result: CollapseDto = {
      id: `table--${organizationId}`,
      parentId: organizationId,
      type: 'table',
      typeOrganisation: '',
    }

    if (institutionList.length === 0) {
      // FIXME organization that has no sub-organization, or sub-insitutions... = what to do with that???
      return [
        {
          ...result,
          data: [
            {
              data1: `organisation "vide", ne contenant ni organisation enfants, ni établissements enfants...`,
            },
          ],
          dataIds: ['-1'],
          columnNames: ['singleColumn'],
        },
      ]
    }

    return [
      {
        ...result,
        data: institutionList.map(
          ({ name: dataName, address, numDebiteur }) => ({
            data1: dataName,
            data2: address,
            data3: numDebiteur,
          })
        ),
        dataIds: institutionList.map(({ id: dataId }) => dataId),
        columnNames: ['name', 'address', 'numClient'],
      },
    ]
  }
}

export default new CollapseTableNormalizer()
