/* eslint-disable class-methods-use-this */
import {
  ContactPreference,
  DEFAULT_USERINFOS,
  UserEntity,
} from '../utils/UserEntity'
import { SingleUserWDH, UserWDH } from '../utils/UserWDH'

class UsersNormalizer {
  normalize(usersList: UserWDH[]): UserEntity[] {
    return usersList.map((item) => {
      let pref: ContactPreference = DEFAULT_USERINFOS.preferences

      if (item?.preferences) {
        pref = JSON.parse(item?.preferences)
      }

      return {
        id: item['@id'].replace('/users/', ''),
        type: item.type,
        firstname: item.firstname,
        lastname: item.lastname,
        username: item.username,
        companyId: item.companyId,
        createdAt: item.createdAt ? new Date(item.createdAt) : undefined,
        state: item.state,
        lastConnexionDate: item.lastConnexionDate
          ? new Date(item.lastConnexionDate)
          : undefined,
        // for user details:
        email: item.email,
        roles: item.roles,
        fonction: item.fonction,
        UPN: item.UPN,
        passwordExpirationDate: item.passwordExpirationDate
          ? new Date(item.passwordExpirationDate)
          : undefined,
        officePhone: item.officePhone,
        mobilePhone: item.mobilePhone,
        preferences: pref,
        organizations: item.organizations,
        couldBeImpersonated: item.couldBeImpersonated,
        accessDocs: Boolean(item.accessDocs),
        accessDemands: Boolean(item.accessDemands),
        isSuperAdminTech: Boolean(item.isSuperAdminTech),
      }
    })
  }

  normalizeSingle(usersList: SingleUserWDH[]): UserEntity[] {
    return usersList.map((item) => {
      let pref: ContactPreference = DEFAULT_USERINFOS.preferences

      if (item?.preferences) {
        pref = JSON.parse(item?.preferences)
      }
      const roles = item.roles?.map((role) => {
        return role.name
      })
      return {
        id: item['@id'].replace('/users/', ''),
        type: item.type,
        firstname: item.firstname,
        lastname: item.lastname,
        username: item.username,
        companyId: item.companyId,
        createdAt: item.createdAt ? new Date(item.createdAt) : undefined,
        state: item.state,
        lastConnexionDate: item.lastConnexionDate
          ? new Date(item.lastConnexionDate)
          : undefined,
        // for user details:
        email: item.email,
        roles,
        fonction: item.fonction,
        UPN: item.UPN,
        passwordExpirationDate: item.passwordExpirationDate
          ? new Date(item.passwordExpirationDate)
          : undefined,
        officePhone: item.officePhone,
        mobilePhone: item.mobilePhone,
        preferences: pref,
        organizations: item.organizations,
        couldBeImpersonated: item.couldBeImpersonated,
        updatedBy: item.updatedBy
          ? {
              firstname: item.updatedBy.firstname,
              lastname: item.updatedBy.lastname,
            }
          : undefined,
        accessDemands: Boolean(item.accessDemands),
        accessDocs: Boolean(item.accessDocs),
        isSuperAdminTech: Boolean(item.isSuperAdminTech),
      }
    })
  }
}

export default new UsersNormalizer()
