/* eslint-disable class-methods-use-this */

import {
  AgManagerial,
  AgManagerialEntity,
} from '../utils/interfaces/Organization/AgManagerialInterface'

class AgManagerialNormalizer {
  normalize(AdvancedReport: AgManagerial[]): AgManagerialEntity[] {
    return AdvancedReport.map((item: AgManagerial) => {
      return {
        id: item.idAgence,
        label: item.nomAgence,
      }
    })
  }
}

export default new AgManagerialNormalizer()
