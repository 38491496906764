export default function regroupURL(
  arrayOfParameter: string[],
  baseUrl: string,
  maxParameterUrl: number
): string[] {
  const urlArray: string[] = []
  let index = 0
  let urlTmp = baseUrl
  let maxParameterValue = maxParameterUrl
  while (index < arrayOfParameter.length) {
    urlTmp = urlTmp.concat(`&idEntreprise[]=${arrayOfParameter[index]}`)
    if (index === maxParameterValue || index === arrayOfParameter.length - 1) {
      urlArray.push(urlTmp)
      urlTmp = baseUrl
      maxParameterValue += maxParameterUrl
    }
    index += 1
  }
  return urlArray
}
