export default function sendFileToClient(
  fileData: string,
  fileName: string,
  fileDate: string
) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([fileData]))
  const fileClientName = `${fileName}_${fileDate}.xlsx`
  link.setAttribute('download', fileClientName)
  document.body.appendChild(link)
  link.click()
}
