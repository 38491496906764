enum SCREEN_TYPES {
  WINDOW = 1320,
  TABLETTE = 913,
  MOBILE = 343,
}

// check if it's Desktop mode
export default function isDesktop(width: number): boolean {
  if (width < SCREEN_TYPES.WINDOW) return false
  return true
}

// check if it's Mobile mode
export function isMobile(width: number): boolean {
  if (width < SCREEN_TYPES.TABLETTE) return true
  return false
}

export function getTooltipDirection(
  tooltipElement: HTMLDivElement | null,
  iconElement: HTMLDivElement | null
) {
  const directions = ['top', 'bottom', 'left', 'right']

  // calc width and height tooltip
  const tooltipWidth = tooltipElement ? tooltipElement.offsetWidth + 10 : 0
  const tooltipHeight = tooltipElement ? tooltipElement.offsetHeight + 10 : 0

  const left = iconElement ? iconElement.offsetLeft - tooltipWidth : 0
  const top = iconElement ? iconElement.offsetTop - tooltipHeight : 0

  const bottom = window.innerHeight - top - tooltipHeight
  const right = window.innerWidth - left - 2 * tooltipWidth

  const measures = [top, bottom, left, right]
  const maxIndex = measures.indexOf(Math.max(...measures))
  const direction = directions[maxIndex]

  if (top > 0 && left > 0 && right > 0) {
    return 'top'
  }

  if (
    (direction === 'left' || direction === 'right') &&
    (top <= 0 || bottom <= 0)
  ) {
    return top > bottom ? 'top' : 'bottom'
  }

  if (
    (direction === 'top' || direction === 'bottom') &&
    (left <= 0 || right <= 0)
  ) {
    return right > left ? 'right' : 'left'
  }

  return direction
}
