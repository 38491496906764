/* eslint-disable prefer-destructuring */
import {
  chartData,
  extractFamillies,
  formatDataByFamilly,
  prepareDataforchart,
  extractServices,
  formatDataByService,
  extractTreated,
  formatDataByFamillyTreated,
} from '../services/billing-synthesis-services'
import { ActivitySynthesisEntity } from './ActivitySynthesisEntity'
import {
  BillsAndCreditsEntity,
  BillsAndCreditsByServiceEntity,
} from './BillSynthesisEntity'

interface TableInput {
  family: string
  jan: number
  feb: number
  mars: number
  april: number
  may: number
  june: number
  july: number
  august: number
  sep: number
  oct: number
  nov: number
  dec: number
}

interface DoubleTableInput {
  family: string
  janBill: number
  janCredits: number
  febBill: number
  febCredits: number
  marsBill: number
  marsCredits: number
  aprilBill: number
  aprilCredits: number
  mayBill: number
  mayCredits: number
  juneBill: number
  juneCredits: number
  julyBill: number
  julyCredits: number
  augustBill: number
  augustCredits: number
  sepBill: number
  sepCredits: number
  octBill: number
  octCredits: number
  novBill: number
  novCredits: number
  decBill: number
  decCredits: number
}

export function extractMaterialCleanDataFromRaw(
  BillsData: BillsAndCreditsEntity[]
): chartData[] {
  let cleanData: chartData[] = []
  const Extratedfamillies = extractFamillies(BillsData)
  const FormatedData = formatDataByFamilly(BillsData)
  cleanData = prepareDataforchart(FormatedData, Extratedfamillies)
  return cleanData
}
export function extractMaterialTreatedCleanDataFromRaw(
  BillsData: ActivitySynthesisEntity[]
): chartData[] {
  let cleanData: chartData[] = []
  const Extratedfamillies = extractTreated(BillsData)
  const FormatedData = formatDataByFamillyTreated(BillsData)
  cleanData = prepareDataforchart(FormatedData, Extratedfamillies)
  return cleanData
}

export function extractServicelCleanDataFromRaw(
  ServiceData: BillsAndCreditsByServiceEntity[]
): chartData[] {
  let cleanData: chartData[] = []
  const Extratedfamillies = extractServices(ServiceData)
  const FormatedData = formatDataByService(ServiceData)
  cleanData = prepareDataforchart(FormatedData, Extratedfamillies)
  return cleanData
}

export function prepareTableSimpleData(Data: chartData[]): TableInput[] {
  const result: TableInput[] = []
  Data.forEach((familly) => {
    const line: TableInput = {
      family: '',
      jan: 0,
      feb: 0,
      mars: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
    }
    line.family = familly.label
    // add bills data
    line.jan = familly.data[0]
    line.feb = familly.data[1]
    line.mars = familly.data[2]
    line.april = familly.data[3]
    line.may = familly.data[4]
    line.june = familly.data[5]
    line.july = familly.data[6]
    line.august = familly.data[7]
    line.sep = familly.data[8]
    line.oct = familly.data[9]
    line.nov = familly.data[10]
    line.dec = familly.data[11]
    result.push(line)
  })
  return result
}

export function prepareTableBillsCredits(
  Data: chartData[],
  SecondData: chartData[],
  onlyCredits: string[]
): DoubleTableInput[] {
  const result: DoubleTableInput[] = []
  // TODO
  Data.forEach((familly) => {
    const line: DoubleTableInput = {
      family: '',
      janBill: 0,
      janCredits: 0,
      febBill: 0,
      febCredits: 0,
      marsBill: 0,
      marsCredits: 0,
      aprilBill: 0,
      aprilCredits: 0,
      mayBill: 0,
      mayCredits: 0,
      juneBill: 0,
      juneCredits: 0,
      julyBill: 0,
      julyCredits: 0,
      augustBill: 0,
      augustCredits: 0,
      sepBill: 0,
      sepCredits: 0,
      octBill: 0,
      octCredits: 0,
      novBill: 0,
      novCredits: 0,
      decBill: 0,
      decCredits: 0,
    }
    line.family = familly.label
    // add bills data
    line.janBill = familly.data[0]
    line.febBill = familly.data[1]
    line.marsBill = familly.data[2]
    line.aprilBill = familly.data[3]
    line.mayBill = familly.data[4]
    line.juneBill = familly.data[5]
    line.julyBill = familly.data[6]
    line.augustBill = familly.data[7]
    line.sepBill = familly.data[8]
    line.octBill = familly.data[9]
    line.novBill = familly.data[10]
    line.decBill = familly.data[11]
    if (SecondData)
      SecondData.forEach((credit) => {
        if (credit.label === line.family) {
          line.janCredits = credit.data[0]
          line.febCredits = credit.data[1]
          line.marsCredits = credit.data[2]
          line.aprilCredits = credit.data[3]
          line.mayCredits = credit.data[4]
          line.juneCredits = credit.data[5]
          line.julyCredits = credit.data[6]
          line.augustCredits = credit.data[7]
          line.sepCredits = credit.data[8]
          line.octCredits = credit.data[9]
          line.novCredits = credit.data[10]
          line.decCredits = credit.data[11]
        }
      })
    result.push(line)
  })
  if (onlyCredits.length !== 0) {
    if (SecondData) {
      SecondData.forEach((credit) => {
        const line2: DoubleTableInput = {
          family: '',
          janBill: 0,
          janCredits: 0,
          febBill: 0,
          febCredits: 0,
          marsBill: 0,
          marsCredits: 0,
          aprilBill: 0,
          aprilCredits: 0,
          mayBill: 0,
          mayCredits: 0,
          juneBill: 0,
          juneCredits: 0,
          julyBill: 0,
          julyCredits: 0,
          augustBill: 0,
          augustCredits: 0,
          sepBill: 0,
          sepCredits: 0,
          octBill: 0,
          octCredits: 0,
          novBill: 0,
          novCredits: 0,
          decBill: 0,
          decCredits: 0,
        }
        if (onlyCredits.includes(credit.label)) {
          line2.family = credit.label
          line2.janCredits = credit.data[0]
          line2.febCredits = credit.data[1]
          line2.marsCredits = credit.data[2]
          line2.aprilCredits = credit.data[3]
          line2.mayCredits = credit.data[4]
          line2.juneCredits = credit.data[5]
          line2.julyCredits = credit.data[6]
          line2.augustCredits = credit.data[7]
          line2.sepCredits = credit.data[8]
          line2.octCredits = credit.data[9]
          line2.novCredits = credit.data[10]
          line2.decCredits = credit.data[11]
        }
        result.push(line2)
      })
    }
  }
  return result
}

export function prepareTableWithArray(Data: chartData[]): TableInput {
  const line: TableInput = {
    family: '',
    jan: 0,
    feb: 0,
    mars: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
  }
  if (Data[0]) {
    line.jan = Data[0].data[0]
    line.feb = Data[0].data[1]
    line.mars = Data[0].data[2]
    line.april = Data[0].data[3]
    line.may = Data[0].data[4]
    line.june = Data[0].data[5]
    line.july = Data[0].data[6]
    line.august = Data[0].data[7]
    line.sep = Data[0].data[8]
    line.oct = Data[0].data[9]
    line.nov = Data[0].data[10]
    line.dec = Data[0].data[11]
  }
  return line
}
