/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["refreshPage"] }] */

import React from 'react'
import PropTypes from 'prop-types'
import technicalError from '../assets/technicalError.png'
import Button from '../components/Buttons/Button'

// inline style
const errorbody = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#030f40',
}

const errorBodyImage = {
  width: '25rem',
  marginTop: '5rem',
}

const ErrorBodyTextCountainer = {
  height: '39.6rem',
  margin: '2rem 0',
  backgroundColor: '#ffffff',
}
const ErrorBodyTextContent = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
  paddingLeft: '6.2rem',
  paddingRight: '6.2rem',
  textAlign: 'center',
  fontSize: '1.6rem',
  letterSpacing: '0',
  lineHeight: '1.8rem',
}
const ErrorBodyTextTitle = {
  fontSize: '3.2rem',
  marginBottom: '3rem',
}

const ErrorBodyTextBody = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

// calling New Relic Logs Api
async function sendLogs(error, errorInfo, userIp) {
  const url =
    'https://log-api.eu.newrelic.com/log/v1?Api-Key=' +
    `${process.env.REACT_APP_NEWRELIC_LOG_KEY}`
  const data = {
    error,
    errorInfo,
    userIp,
  }
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

async function getUserIp() {
  // Initialising client ip address
  let userIP = ''
  const url = `${process.env.REACT_APP_BASE_API_URL}/get-user-ip`
  // get the user ip adress using geolocation-db
  try {
    await fetch(url, {
      method: 'GET',
    }).then((res) => {
      userIP = res.json()
    })
  } catch (e) {
    console.log('Something went wrong :', e)
  }
  // return the user ip adress

  return userIP
}

// page DOM
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    })
    async function checkUserIp() {
      let userIpv4 = ''
      userIpv4 = await getUserIp()
      // You can also log error messages to an error reporting service here
      console.log('🚧 Technical infos | Error : ', error)
      console.log('🚧 Technical infos | Error Infos : ', errorInfo)
      sendLogs(error, errorInfo, userIpv4.IP)
    }
    checkUserIp()
  }

  refreshPage() {
    window.location.reload(false)
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="errorBoundery_background">
          <div style={errorbody}>
            <img
              src={technicalError}
              alt="man holding empty box"
              style={errorBodyImage}
            />
            <div style={ErrorBodyTextCountainer}>
              <div style={ErrorBodyTextContent}>
                <h2 style={ErrorBodyTextTitle}>Oups !</h2>
                <div style={ErrorBodyTextBody}>
                  <p>
                    Un probl&egrave;me de chargement du site sur votre
                    navigateur a provoqu&eacute; une erreur technique.
                  </p>
                  <br />
                  <p>
                    Voici quelques manipulations &agrave; r&eacute;aliser pour
                    corriger le probl&egrave;me :
                  </p>
                  <br />
                  <p>1. Rafraichissez la page.</p>
                  <p>
                    2. Essayez de vous connecter &agrave; partir d&rsquo;un
                    autre navigateur (Chrome / Edge / &#8230;).
                  </p>

                  <p>
                    3. Essayez de vous connecter &agrave; partir d&rsquo;un
                    autre poste.
                  </p>
                </div>
                <Button
                  onClick={() => {
                    this.refreshPage()
                  }}
                >
                  Rafraichir la page
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}
