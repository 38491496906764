/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect } from 'react'

import SvgIcon from '../Icons/SvgIcon'

interface Props {
  id?: string
  classname?: string
  name?: string
  label?: string
  checked?: boolean
  disabled?: boolean
  type?: string
  indeterminate?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  initAction?: () => void
}

const Checkbox: React.FC<Props> = ({
  id,
  classname,
  name,
  label,
  checked,
  disabled,
  type,
  onChange,
  indeterminate,
  initAction,
}: Props) => {
  const checkboxInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    initAction?.()
  }, [])

  useEffect(() => {
    const checkbox = checkboxInput.current
    if (!checkbox) return
    if (indeterminate === undefined) {
      checkbox.indeterminate = false
    }

    if (indeterminate === true) {
      checkbox.indeterminate = true
    }
  }, [indeterminate])

  return (
    <div
      className={`pretty ${
        type === 'checkbox' ? 'p-svg' : 'p-round p-default'
      }`}
    >
      <input
        id={id}
        ref={checkboxInput}
        name={name}
        type={type}
        className={`checkbox ${classname}`}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      {type === 'checkbox' && (
        <div className="state">
          {label ? (
            <label>
              <SvgIcon name="check" className="svg svg-icon" />
              {label}
            </label>
          ) : (
            <label>
              <SvgIcon name="check" className="svg svg-icon" />
              &#8203;
            </label>
          )}
        </div>
      )}
      {type === 'radio' && (
        <div className="state">{label ? <label>{label}</label> : null}</div>
      )}
    </div>
  )
}
Checkbox.defaultProps = {
  id: '',
  classname: '',
  name: '',
  label: undefined,
  checked: false,
  type: 'checkbox',
  disabled: false,
  indeterminate: undefined,
  onChange: () => {
    // just for compilation
  },
  initAction: () => {
    // action when Checkbox first mount
  },
}

export default Checkbox
