import { BillDownloadStatus } from '../services/billing-services'
import BillingDownloadErrorNormalizer from './BillingDownloadErrorNormalizer'

export interface DossierAnnexe {
  [key: string]: string[]
}

export interface DossierError {
  [key: string]: BillDownloadStatus[]
}

export interface ErrorsByCategory {
  [key: string]: DossierError
}

export default class BillingAnnexeDownloadErrorNormalizer {
  static normalize(
    fileErrorList: BillDownloadStatus[],
    dossiers: DossierAnnexe
  ): ErrorsByCategory {
    const downloadKOCategorizedErrors = BillingDownloadErrorNormalizer.normalize(
      fileErrorList
    )

    const allErrorsCategorized = Object.keys(
      downloadKOCategorizedErrors
    ).reduce<ErrorsByCategory>((categorized, setName) => {
      // setName are error types names such as 'unavailable', 'technical'
      const sortedErrors = downloadKOCategorizedErrors[setName].reduce<
        DossierError
      >((setErrors, setErrorItem) => {
        // keys of dossiers are dossier number
        // values for each keys (i.e. 'dossiers[item]') are file technical id
        let nextErrorSet = { ...setErrors }
        Object.keys(dossiers).forEach((item) => {
          nextErrorSet = dossiers[item].includes(setErrorItem.id)
            ? {
                ...nextErrorSet,
                [item]: nextErrorSet[item]
                  ? nextErrorSet[item].concat(setErrorItem)
                  : (nextErrorSet[item] = [setErrorItem]),
              }
            : { ...nextErrorSet }
        })
        return nextErrorSet
      }, {})
      return { ...categorized, [setName]: sortedErrors }
    }, {})

    const allErrorsCategorizedSortedById: ErrorsByCategory = {}
    Object.entries(allErrorsCategorized).forEach(([i, values]) => {
      const sortedValues = Object.keys(values)
        .sort()
        .reduce((sorted, key) => {
          return { ...sorted, [key]: values[key] }
        }, {})
      allErrorsCategorizedSortedById[i] = sortedValues
    })

    return allErrorsCategorizedSortedById
  }
}
