/* eslint-disable class-methods-use-this */
import moment from 'moment'
import { BillDetailWDH, BillDetailEntity } from '../utils/BillDetailWDH'

import formatAdresse from '../utils/AddressUtils'

import 'moment/locale/fr'
import {
  formatDate,
  to1stDayOfMonth,
  toLastDayOfMonth,
  getTrim,
} from '../utils/date-utils'
import BillingAnnexeDetailNormalizer from './BillingAnnexeDetailNormalizer'

moment.locale('fr')

class BillingDetailNormalizer {
  normalize(billDetailraw: BillDetailWDH): BillDetailEntity {
    // intilizing adresses
    let addressClient = ''
    let addressAgence = ''

    let billStartDate = ''
    let billEndDate = ''

    let raisonSocialClient = ''
    let raisonSocialSuez = ''
    let numDebiteur = ''
    let statut = ''

    if (billDetailraw.fkstatutfacture?.statut === 'Complet') {
      statut = 'Complet*'
    } else if (billDetailraw.fkstatutfacture?.statut === 'Partiel') {
      statut = 'Partiel*'
    } else {
      statut = 'En attente*'
    }
    if (billDetailraw.fketablissemententrepriseclient !== undefined) {
      // Managing Adresses
      if (
        billDetailraw.fketablissemententrepriseclient.adresses !== undefined
      ) {
        const clientPrincipalAddress = billDetailraw.fketablissemententrepriseclient.adresses.find(
          (address) => address.typeAdresse === 'Principale'
        )
        addressClient = formatAdresse(clientPrincipalAddress)
      }
      // managing raison social :
      raisonSocialClient = billDetailraw.fketablissemententrepriseclient
        .raisonSociale
        ? billDetailraw.fketablissemententrepriseclient.raisonSociale
        : ''
      numDebiteur = billDetailraw.fketablissemententrepriseclient.numDebiteur
        ? billDetailraw.fketablissemententrepriseclient.numDebiteur
        : ''
    }

    if (billDetailraw.fketablissemententrepriseclient !== undefined) {
      // managing cycleFacturation
      switch (billDetailraw.fketablissemententrepriseclient.cycleFacturation) {
        case 'TRIM': {
          const interval = getTrim(billDetailraw.dateFacturation)
          billStartDate = formatDate(interval[0])
          billEndDate = formatDate(interval[1])
          break
        }
        default: {
          billStartDate = formatDate(
            to1stDayOfMonth(billDetailraw.dateFacturation)
          )
          billEndDate = formatDate(
            toLastDayOfMonth(billDetailraw.dateFacturation)
          )
        }
      }
      if (
        billDetailraw.fketablissemententrepriseagence.adresses !== undefined
      ) {
        const agencePrincipalAddress = billDetailraw.fketablissemententrepriseagence.adresses.find(
          (address) => address.typeAdresse === 'Principale'
        )
        addressAgence = formatAdresse(agencePrincipalAddress)
      }

      raisonSocialSuez = billDetailraw.fketablissemententrepriseagence
        .raisonSociale
        ? billDetailraw.fketablissemententrepriseagence.raisonSociale
        : ''
    }

    // normalizing the annexes
    const annexes = BillingAnnexeDetailNormalizer.normalize(
      billDetailraw.interventions
    )

    // creation du reason social :

    return {
      id: billDetailraw.numFacture,
      date: moment(billDetailraw.dateFacturation).format('MMMM YYYY'), // date de facturation
      period: {
        begin: billStartDate,
        end: billEndDate,
      },
      amount: billDetailraw.montantTotalHT,
      institution: {
        name: raisonSocialClient,
        address: addressClient,
      },
      emissionDate: moment(billDetailraw.dateFacturation).format('DD/MM/YYYY'),
      debtorNb: numDebiteur,
      billingSuezEntity: {
        name: raisonSocialSuez,
        address: addressAgence,
      },
      annexes,
      statut,
    }
  }
}

export default new BillingDetailNormalizer()
