import React from 'react'

import OrganizationEntity from '../utils/OrganizationEntity'
import SearchResultItem from '../utils/SearchResultItem'

function SearchOrganizationAdapter(
  searchResultItems: OrganizationEntity[],
  institutionLabel: string,
  organizationLabel: string
): SearchResultItem[] {
  if (searchResultItems?.length === 0) {
    return []
  }

  return searchResultItems.map(
    ({ id, name, nbInstitutions, nbOrganizationEnfant }) => ({
      id,
      nbSubEntity: nbInstitutions,
      line1: <>{name}</>,
      line2:
        nbOrganizationEnfant === 0 ? (
          <>
            {nbInstitutions} {institutionLabel}
          </>
        ) : (
          <>
            {nbOrganizationEnfant} {organizationLabel}
          </>
        ),
    })
  )
}

export default SearchOrganizationAdapter
