import React from 'react'
import { useTranslation } from 'react-i18next'

import logo from '../../assets/logo_footer.png'

const Footer: React.FC = () => {
  const { t } = useTranslation()

  const url = `${process.env.REACT_APP_FOOTER_URL_PDF}resources/`

  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__links row">
          <div className="col-sm-6">
            <div className="my-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${url}ECE-CGU-SUEZ_RV.pdf`}
              >
                {t('footer.links.cgu')}
              </a>
            </div>
            <div className="my-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${url}terms-of-sales_FR.pdf`}
              >
                {t('footer.links.cgv')}
              </a>
            </div>
            <div className="my-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${url}ECE-Charte_de_gestion_des_cookies-SUEZ_RV.pdf`}
              >
                {t('footer.links.cookies')}
              </a>
            </div>
            <div className="my-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${url}ECE-Mentions_légales-SUEZ_RV.pdf`}
              >
                {t('footer.links.legal')}
              </a>
            </div>
            <div className="my-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${url}ECE-Politique_de_confidentialité-SUEZ_RV.pdf`}
              >
                {t('footer.links.confidentiality')}
              </a>
            </div>
          </div>
          <div className="col-sm-6 d-flex justify-content-center align-items-center">
            <div className="footer__left mt-4">
              <img src={logo} alt="suez logo" className="logo" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
