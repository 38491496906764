import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
import ROUTES from '../../utils/ROUTES'

const ConnectionLayoutWithImageHeader: React.FC = () => {
  return (
    <>
      <div className="header-containter-with-bg">
        <div className="header-containter-with-bg__children">
          <Link to={ROUTES.Home}>
            <img src={logo} alt="logo Suez" />
          </Link>
        </div>
      </div>
    </>
  )
}

export default ConnectionLayoutWithImageHeader
