import { BillDownloadStatus } from '../services/billing-services'

const categories = {
  unavailable: [204, 404],
}

export interface CategorizedBillingError {
  [key: string]: BillDownloadStatus[]
}

export default class BillingDownloadErrorNormalizer {
  static normalize(
    fileErrorList: BillDownloadStatus[]
  ): CategorizedBillingError {
    const billingDownloadErrors = {
      unavailable: [],
      technical: [],
    } as CategorizedBillingError

    fileErrorList.forEach((fileError: BillDownloadStatus) => {
      if (categories.unavailable.includes(fileError.status)) {
        billingDownloadErrors.unavailable.push(fileError)
      } else {
        billingDownloadErrors.technical.push(fileError)
      }
    })

    return billingDownloadErrors
  }
}
