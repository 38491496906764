/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'
import { getMemberData } from './hydra-services'
import { isCancel } from './billing-services'
import ToastMessagesNormalizer from '../normalizer/ToastMessagesNormalizer'
import MessagesTableNormalizer from '../normalizer/MessagesTableNormalizer'

import ROUTES from '../utils/ROUTES'
import { getFilterForId } from '../utils/BillEntity'
import OrganizationEntity from '../utils/OrganizationEntity'
import InstitutionEntity from '../utils/InstitutionEntity'
import SideMessageEntity from '../utils/SideMessageEntity'
import getFormatedMessageUsers from '../utils/message-utils'
import ToastMessagesEntity from '../utils/ToastMessageEntity'

let cancelToken: CancelTokenSource
const labelsToApiFields: { [key: string]: string } = {
  'Date de publication': 'createdAt',
  'Date d’expiration': 'expireAt',
  Status: 'statut',
  Message: 'messagetext',
  Utilisateurs: 'users.user.username',
  Etablissements: 'company',
  Source: 'source',
}

export async function getMessageTable(
  perPage: number,
  page: number,
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  sortTable: any,
  filters: any[],
  history: any
): Promise<{
  results: SideMessageEntity[]
  pageCount: number
}> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages`
  let listOfMessage: SideMessageEntity[] = []
  const params = new URLSearchParams()
  params.append(`page`, page.toString())
  params.append(`perPage`, perPage.toString())

  organisation.forEach((org) => {
    params.append(`idOrganisation[]`, org.id)
  })
  institution.forEach((insti) => {
    params.append(`companyId[]`, insti.id)
  })
  if (sortTable) {
    Object.keys(sortTable).forEach((key: string) => {
      params.append(`order[${labelsToApiFields[key]}]`, sortTable[key])
    })
  }
  if (getFilterForId(filters, 'createdAt')) {
    const [startDate, endDate] = getFilterForId(filters, 'createdAt')
    params.append('createdAt[after]', moment(startDate).format('YYYY-MM-DD'))
    params.append('createdAt[before]', moment(endDate).format('YYYY-MM-DD'))
  }
  if (getFilterForId(filters, 'expireAt')) {
    const [startDate, endDate] = getFilterForId(filters, 'expireAt')
    params.append('expireAt[after]', moment(startDate).format('YYYY-MM-DD'))
    params.append('expireAt[before]', moment(endDate).format('YYYY-MM-DD'))
  }
  const statusFilter = getFilterForId(filters, 'statut')
  if (statusFilter && statusFilter.value !== 'all') {
    params.append('statut[]', statusFilter.value)
  }
  const messageFilter = getFilterForId(filters, 'messagetext')
  if (messageFilter) {
    params.append('messagetext', messageFilter)
  }
  const usernameFilter = getFilterForId(filters, 'username')
  if (usernameFilter) {
    params.append('users.user.username', usernameFilter)
  }
  const companyFilter = getFilterForId(filters, 'company')
  if (companyFilter) {
    params.append('searchCompany', companyFilter)
  }
  const sourceFilter = getFilterForId(filters, 'source')
  if (sourceFilter && sourceFilter.value !== 'all') {
    params.append('source', sourceFilter.value)
  }
  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })
    if (resp?.data) {
      // Result Normalization
      listOfMessage = MessagesTableNormalizer.normalize(
        getMemberData(resp.data)
      )

      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      return {
        results: [...listOfMessage],
        pageCount: Math.ceil(totalItems / perPage),
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve({ pageCount: 0, results: err })
    }
    history.push(ROUTES.TechnicalError)
    throw new Error(`Erreur : ${err}`)
  }
  return Promise.resolve({ pageCount: 0, results: [] })
}

export async function patchBannerMessage(
  messageId: string,
  state: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages/${messageId}`

  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        statut: state,
      },
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}

export async function postMessageCompagnies(
  messagetext: string,
  expireAt: Date,
  companies: InstitutionEntity[]
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages`
  let status = 400
  const companiesIds = companies?.map((company) => {
    return { companyId: company.id }
  })
  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        messagetext,
        expireAt,
        companies: companiesIds,
      },
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}

export async function postMessageUsers(
  messageText: string,
  expireAt: Date,
  usersId: string[]
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages`

  let status = 400

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: getFormatedMessageUsers(messageText, expireAt, usersId),
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}

export async function postMessageSource(
  messagetext: string,
  expireAt: Date,
  source: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages`

  let status = 400

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        messagetext,
        expireAt,
        source,
      },
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}

// return the list of messages to show to the connected user
export async function getToastMessages() {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages`
  const params = new URLSearchParams()
  let result: ToastMessagesEntity[] = []
  params.append(`page`, '1')
  params.append(`perPage`, '3')
  params.append(`order[createdAt]`, 'DESC')
  params.append(`statut`, 'activated')
  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
    })
    const res = getMemberData(resp?.data)
    if (res.length)
      result = ToastMessagesNormalizer.normalize(getMemberData(resp.data))
  } catch (err) {
    throw new Error(err)
  }
  return result
}

export async function getMessage(
  idMessage: string
): Promise<SideMessageEntity> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages/${idMessage}`
  const res = {
    id: '',
    messagetext: '',
    statut: '',
    type: '',
    source: '',
    compagnies: [],
    users: [],
  }
  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    if (resp?.data) {
      return MessagesTableNormalizer.normalizeSingle(resp.data)
    }
  } catch (err) {
    throw new Error(err)
  }
  return res
}

export async function patchMessageCompagnies(
  messageId: string,
  messagetext: string,
  expireAt: Date,
  companies: InstitutionEntity[]
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages/${messageId}`
  let data: any
  let status = 400
  const companiesIds = companies?.map((company) => {
    return { companyId: company.id }
  })
  data = {
    messagetext,
    expireAt,
  }
  if (companiesIds.length > 0) {
    data = {
      messagetext,
      expireAt,
      companies: companiesIds,
    }
  }
  if (moment(expireAt).isAfter(moment())) {
    data = {
      messagetext,
      expireAt,
      companies: companiesIds,
      statut: 'activated',
    }
  }
  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data,
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}

export async function removeMessageCompanyLink(
  companyId: string,
  messageId: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/asso_messages_companies/companyId=${companyId};message=${messageId}`
  let status = 400
  try {
    const resp = await axios({
      method: 'DELETE',
      url,
    })

    status = resp?.status
  } catch (err) {
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function callAPIRemoveMessageCompany(
  companiesId: string[],
  messageId: string
) {
  for (const item of companiesId) {
    await removeMessageCompanyLink(item, messageId)
  }
}

export async function patchMessageUsers(
  messageId: string,
  messageText: string,
  expireAt: Date,
  usersId: string[]
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages/${messageId}`
  let data: any
  let status = 400
  data = {
    messagetext: messageText,
    expireAt: moment(expireAt).format('YYYY-MM-DD'),
  }
  if (moment(expireAt).isAfter(moment())) {
    data = {
      messagetext: messageText,
      expireAt: moment(expireAt).format('YYYY-MM-DD'),
      statut: 'activated',
    }
  }
  if (usersId.length > 0) {
    data = getFormatedMessageUsers(messageText, expireAt, usersId)
  }
  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data,
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}
export async function removeMessageUserLink(
  userId: string,
  messageId: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/asso_messages_users/user=${userId};message=${messageId}`
  let status = 400
  try {
    const resp = await axios({
      method: 'DELETE',
      url,
    })

    status = resp?.status
  } catch (err) {
    throw new Error(`Erreur : ${err}`)
  }
  return status
}

export async function callAPIRemoveMessageUser(
  usersId: string[],
  messageId: string
) {
  for (const item of usersId) {
    await removeMessageUserLink(item, messageId)
  }
}

export async function patchMessageSource(
  messageId: string,
  messagetext: string,
  expireAt: Date,
  source: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/messages/${messageId}`
  let data: any
  let status = 400
  data = {
    messagetext,
    expireAt,
    source,
  }
  if (moment(expireAt).isAfter(moment())) {
    data = {
      messagetext,
      expireAt,
      source,
      statut: 'activated',
    }
  }
  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data,
    })
    status = resp?.status
  } catch (err) {
    throw new Error(err)
  }
  return status
}
